

.main_wrap_onboth{
  padding-top: 5px;
  margin-top: 2%;
  height: 72%;
  /* overflow: auto; */

}




.text_cap.dropdown_wrapper.ant-input{
  text-transform: capitalize !important;
  
}



.evaluation_background_gradient_wrapper {
  height: calc(100vh / 1.7);
  width: 100%;
}
.evaluation_background_gradient {
  /* background-image: linear-gradient(
    to bottom right,
    var(--primaryDark),
    var(--primary),
    var(--infprimaryLighto)
    ); */
  height: calc(100vh / 1.7);
  width: 100%;
  background-color: var(--primary);
  /* background-image: linear-gradient(
    to left top,
    #c33b2a,
    #b83525,
    #ae3021,
    #a32a1c,
    #992518
  ); */
  /* background-image: linear-gradient(to bottom right, #88271d, #c33b2a, #88271d); */
  position: absolute;
  top: 0;
}
.numberIcon {
  background: var(--infoPlus);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary);
  font-weight: bold;
  margin: 0 0.2rem 0 0;
}
.evaluation_section_1 {
  height: calc(100vh / 1.7);
  width: 100%;
}
.elevation_s1_lc {
  width: 93%;
}
.elevation_s1_lc_cont_2_r {
  display: none;
}
.breadcrumb_icon {
  width: 20px;
  height: 20px;
  font-size: 15px;
}
.elevation_s1_lc_cont_1_price {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  font-weight: 800;
  color: var(--secondary);
  line-height: 2.1rem;
  justify-content: center;
}
.elevation_s1_lc_cont_1_price > span:nth-child(1) {
  font-size: 2.3rem;
  display: block;
}
.elevation_s1_lc_cont_1_price > span:nth-child(2) {
  font-size: 2.6rem;
  display: block;
}
.elevation_s1_lc_cont_1_t_1 {
  font-size: 1.3rem;
}
.elevation_s1_lc_cont_1_t_2 {
  font-size: 1.4rem;
  font-weight: 500;
}
.evaluation_s2_lc_heading {
  width: 85%;
  padding-top: 2rem;
  margin-top: 2rem;
}
.evaluation_s2_heading {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
/* order list */
.evaluation_s2_orderlist {
  list-style: none;
  counter-reset: list-count;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.evaluation_s2_orderlist > li {
  counter-increment: list-count;
  font-size: 1.4rem;
  margin-top: 0.8rem;
}
.evaluation_s2_orderlist > li::before {
  content: counter(list-count) ".";
  font-weight: bold;
  font-size: 1.4rem;
  margin-right: 1rem;
  color: var(--primary);
}
.evaluation_footer {
  border-top: 1px solid var(--secondary);
  margin-top: 2rem;
}
/* ================================= */
@media only screen and (max-width: 610px) {
  .elevation_s1_lc_cont_1_price > span:nth-child(1) {
    font-size: 1rem;
    display: block;
  }
  .elevation_s1_lc_cont_1_price > span:nth-child(2) {
    font-size: 2.3rem;
    display: block;
  }
  /*  */
  .evaluation_s2_heading {
    font-size: 1.5rem;
    font-weight: 700;
  }
  .evaluation_s2_orderlist > li {
    font-size: 1.35rem;
    margin-top: 0.75rem;
  }
  .evaluation_s2_orderlist > li::before {
    font-size: 1.35rem;
    margin-right: 0.9rem;
  }
}
@media only screen and (max-width: 540px) {
 
 .step_num{
  font-size: .7rem !important;
 }
 
  .elevation_s1_lc_cont_1_price > span:nth-child(1) {
    font-size: 1rem;
    display: block;
  }
  .elevation_s1_lc_cont_1_price > span:nth-child(2) {
    font-size: 2.1rem;
    display: block;
  }
  .breadcrumb_icon {
    width: 15px;
    height: 15px;
    font-size: 10px;
  }

  .elevation_s1_lc_cont_1_price{
    line-height: normal !important;
  }
  /*  */
  .evaluation_s2_heading {
    font-size: 1.4rem;
    font-weight: 600;
  }
  .evaluation_s2_orderlist > li {
    font-size: 1.2rem;
    margin-top: 0.6rem;
  }
  .evaluation_s2_orderlist > li::before {
    font-size: 1.2rem;
    margin-right: 0.8rem;
  }
}
@media only screen and (max-width: 991px) {
  .evaluation_section_1 {
    height: auto;
  }
  .evaluation_background_gradient_wrapper {
    height: calc(100vh / 2.7);
  }
  .evaluation_background_gradient {
    height: calc(100vh / 2.7);
  }
  /* .elevation_s1_lc_cont_2 {
    display: none;
  } */
  .elevation_s1_lc_cont_2_r {
    display: inline-block;
  }
  .evaluation_s2_heading {
    margin-bottom: 1rem;
  }
  .evaluation_s2_lc_heading {
    width: 95%;
    padding-top: 1rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}

.img1 {
  width: 7rem;
  height: 7rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
}

.picdiv {
  display: flex;
  flex-wrap: wrap;

  /* border: 1px solid black; */
  width: 100%;
}

.imgpiker {
  position: relative;
  /* border: 1px solid blue; */
  height: 7rem;
  width: 7rem;
  /* padding: 0.8rem; */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  margin: 0.5rem;
  overflow: hidden;
  box-shadow: -2px 2px 6px -1px rgba(69, 68, 68, 0.75);
  -webkit-box-shadow: -2px 2px 6px -1px rgba(69, 68, 68, 0.75);
  -moz-box-shadow: -2px 2px 6px -1px rgba(69, 68, 68, 0.75);
}
