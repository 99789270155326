.showInterest_headingCont {
    color: var(--white);
    background-color: #48BA7E;
    padding: 5rem;
    /* padding-left: 12rem; */
  }
  .showInterest_heading {
    font-size: 2.5rem;
    line-height: 2.8rem;
    font-weight: bold;
    text-transform: uppercase;
 
    
  }
  .showInterest_description {
    font-size: 1.4rem;
    line-height: 1.8rem;
    padding: 1rem;
  }


  .card_wrap_contactus{
      /* height: 30vh; */
      background-color: #F2F4F5;

  }

  .card_contact{
      background-color: white;
  }

  .mobile_svg{
      height: 100%;
      width: 100%;
  }

  .sell_car{
      color: #48BA7E;
  }

  .btn_contact{
      /* background-color: #FFD700; */
      width: 100%;
      height: 3rem;
      font-weight: bold;
    
  }
  .btn_contact:hover{
      /* border: 4px solid #FFD700; */
      /* background-color: white; */
      width: 100%;
      height: 3rem;
      font-weight: bold;
  }

  .card_row{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      width: 80%;
      margin: 0 auto;
  }

  .text_tow{
      font-size: 1rem;
      color: black;
      font-family: Arial, Helvetica, sans-serif;
  }

  /* @media screen and (max-width: 767px) {
    .mobile-space {
        margin-top:60px;
        background-color: red;
    }
    } */



    .contact_wrap{
        background-color: #F2F4F5;
        /* height: 70vh; */
    }

    .inp_wrap{
        border: 2px solid #096C59;
        height: 3.7rem;
        background-color: white;
        padding: 0.7rem;
    }

.inp_wrap_coment{

    border: 2px solid #096C59;
    height: 5.7rem;
    background-color: white;
    padding: 0.7rem;

}



    .inp_contact{
        width: 100%;
        padding: 5px;
        color: black;
        /* border: 1px solid red; */
    }


.btn_row{
    display: flex;
    justify-content: center;
}

.shiping_book{
    box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.3);
    /* height: 30vh; */
    width: 60%;
    margin: 0 auto;
}

.ship_wrap{
    width: 80%;
    border: 1px solid red;
    margin: 0 auto;
}
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600&display=swap");
*, *::after, *::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}


.shoping_h{
  margin: 0;
  padding: 0;
  /* display: inline-block; */
}

.root {
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.3);
}

figure {
  display: flex;
}
figure img {
  width: 8rem;
  height: 8rem;
  border-radius: 15%;
  border: 1.5px solid #f05a00;
  margin-right: 1.5rem;
  padding:1rem;
}
figure figcaption {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
figure figcaption h4 {
  font-size: 1.4rem;
  font-weight: 500;
}
figure figcaption h6 {
  font-size: 1rem;
  font-weight: 300;
}
figure figcaption h2 {
  font-size: 1.6rem;
  font-weight: 500;
}

.order-track {
  margin-top: 2rem;
  padding: 0 1rem;
  border-top: 1px dashed #2c3e50;
  padding-top: 2.5rem;
  display: flex;
  flex-direction: column;
}
.order-track-step {
  display: flex;
  height: 7rem;
}
.order-track-step:last-child {
  overflow: hidden;
  height: 4rem;
}
.order-track-step:last-child .order-track-status span:last-of-type {
  display: none;
}
.order-track-status {
  margin-right: 1.5rem;
  position: relative;
}
.order-track-status-dot {
  display: block;
  width: 2.2rem;
  height: 2.2rem;
  border: 2px solid #f05a00;
  border-radius: 50%;
  /* background-color: white; */
  background: #f05a00;
}
.clr{
  background-color: white;
}




.order-track-status-line {
  display: block;
  margin: 0 auto;
  width: 2px;
  height: 7rem;
  background: #f05a00;
}
.order-track-text-stat {
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 3px;
}
.order-track-text-sub {
  font-size: 1rem;
  font-weight: 300;
}

.order-track {
  transition: all .3s height 0.3s;
  transform-origin: top center;
}





@media screen and (max-width: 500px) {
  
  .smim__{
    width: 100% !important;
  }
   
   } 

