.left_wrap{
    width: 50rem;
    height: 50vh;
    position: relative;
}

/* .car_imgimg{
    width:100%;
    height:40%
} */
.car_up_text{
    position: absolute;
    color: gray;
    font-weight: bold;
    /* top: 70px; */
    /* left: 150px; */
    z-index: 1000px;
   
}
.car_up_text2{
    position: absolute;
    color: gray;
    font-weight: bold;
    /* top: 170px; */
    /* left: 460px; */
    z-index: 1000px;
}
.car_up_text3{
    position: absolute;
    color: gray;
    font-weight: bold;
    top: 290px;
    left: 150px;
    z-index: 1000px;
}






.car_damage{
    padding: 5px;
    border-radius: 10px;
    position: absolute;
    top: 20px;
    right: 20px;
    /* width: 13rem; */
    /* height: 17rem; */
    display: flex;
    justify-content: center;
    border: 2px solid gray;
}
.main_wrap_car_damge{
    position: relative;
    /* border: 1px solid red; */
    display: flex;
    /* flex-direction: row; */
    flex-wrap: nowrap;

}

.car_iii{
  

    width: 10rem;
    height: 10rem;
    /* border: 1px solid red; */
    margin: 0 auto;

}
.main_line{
    /* float: right; */
    border: 1px solid grey;
    position: absolute;
    width: 150px;
    top: 80px;
    right:18%;
    /* left: 170px; */

    z-index: 10000;
}

.right_wrap{
    /* position: absolute; */
    /* border: 1px solid red; */
    display: flex;
    flex-wrap: nowrap;
}
.main_section{
    padding:0px 4px 30px;
}
#image_map_print{
    display: none;
}
.maphilighted {
    background: url(https://wsa4.pakwheels.com/assets/car_certification_v2-e9ae77b….jpg) no-repeat center center !important;
    display: block;
    position: relative;
    padding: 0px;
    width: 553px;
    height: 406px;
}
.taggd-wrapper {
    overflow: hidden;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
}
.carsure-structure-outer .carsure-structure-info {
    position: absolute;
    /* top: 0px; */
    right: 75px;
    width: 180px;
    padding: 20px;
    border: 2px solid #7b8397;
    border-radius: 2px;
    min-height: 100px;
    text-align: center;
    background-color: #FFF;
}
.carsure-structure-outer .carsure-structure-info .pointer {
    background: #7b8397;
    height: 2px;
    margin-top: -2px;
    position: absolute;
    right: 100%;
    top: 60px;
}
.carsure-structure-outer .carsure-structure-infob  {
    background: #7b8397;
width: 80%;
    margin-top: -2px;
    position: absolute;
    right: 100%;
    top: 60px;
    border: 1px solid red;
}

.pointerb{

}



.line_botm{
    border: 1px solid #7b8397 ;

    width: 0px;
    position: absolute;
    z-index: 1000px;
    bottom: 59px;
  
 
}

.damage_show{
    position: relative;
    width: 250px;
    border: 1px solid #7b8397;
    /* width: 70%; */
    /* margin: 0 auto; */
    /* padding: 5px; */
}

.pic_show{
    width: 80%;
    margin: 0 auto;
}








@media only screen and (max-width: 900px) and (min-width: 0px) {
   
    #car_div{
        display: none;
    }
    .pointer{
        display: none;
    }
    
    
  }
@media only screen and (max-width: 1800px) and (min-width: 901px) {
    .damage_show {
      display: none;
    }
    .line_botm {
      display: none;
    }
   
    
  }

@media only screen and (max-width: 768px) and (min-width: 0px) {
   
  .car_damage{
      width: 91%;
  }
    
    
  }
@media only screen and (max-width: 991px) and (min-width: 768px) {
 
    .car_damage{
        width: 96%;
    }
     
    
  }
@media only screen and (max-width: 1440px) and (min-width: 991px) {
 
    .car_damage{
        width: 100%;
    }
     
    
  }




.pint_name{
    font-weight: bold;
    font-size: larger;
    color: black;
}

.p_clor{
    color: #545454;
    width: 50%;
    padding: 0 10px 0 26px;
}


.title_list{
    display: flex;
    flex-wrap: wrap;
}
















  .main_wrap_fist{
      width: 100%;
      margin: 0 auto;
    background-color: #6a6a6a !important;
    color: #fff;
    padding: 6px 0px 6px 5px;
    font-size: 16px;
    text-decoration: none;
    text-transform: capitalize;
    display: flex;
    min-height: 50px;
  }

  .imgicon{
    width: 56px;
    text-align: center;
  }

  .icon-heading{
      display: flex;
      align-items: center;
      
      
  }
  .heading{
    text-align: center;
 
  }

  .ppp{
      display: flex;
      justify-content: end;
      align-items: center;
      color: white;
  }

  .textp{
      font-weight: bold;
  }

  .damge_data{
      /* width: 96%; */
      /* margin: 0 auto; */
      /* border: 1px solid red; */
  }

  .non_{
      background-color: #edfdf1;
      border-bottom: 1px solid #EAEDEF;

   
  }
  .hedd{
    border-bottom: 1px solid #EAEDEF;  
    border-left: 1px solid #EAEDEF;  
    border-right: 1px dashed #c7c1bb;
    
  }

  .report-issue-point {
    border: 1px solid #EAEDEF;
    color: #518ecb;
    /* margin: 5px 0 0; */
    margin: 0 auto;
    font-size: 13px;
    font-weight: 400;
    width: 50%;
    /* padding: 6px */

    border-radius: 5px;
    box-shadow: inset 0 0 2px #ddd;
    display: inline-block;
    cursor: pointer;
}

.crosbtnqq{
position: absolute;
z-index: 1000;
left: 20px;
top: 50px;
font-weight: bold;
color: white;
}

.icon_n{
    color: white;
}

.sub_heading{
    border-top: 1px solid #dfe1e5;
    border-bottom: 1px solid #dfe1e5;
    background-color: #F0F0F0 !important;
    color: #2E2E2E;
    font-weight: 700;
   
    font-size: 16px;
    padding: 13px 10px 13px 20px;
}

.multiple{
    display: flex;
    justify-content: space-between;
}

.row_f{
    /* border: 1px solid rgb(88, 40, 201); */
    margin-right: 0px !important;
    margin-left: 0px !important;
}