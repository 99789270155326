.carDetailBannerCont {
  width: 100%;
  background: var(--primary);
  padding: 1rem 0;
  
}

.cardma {
  width: 300px;
  box-shadow: 0px 0px 14px -5px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 14px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 14px -5px rgba(0, 0, 0, 0.75);
}

.mdiv1 {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  border-radius: 0.8rem;

  /* flex-wrap: nowrap; */
  /* background-color: red; */
  /* display: flex; */
  /* justify-content: center; */
  width: 80%;
}
.mdiv1shimer {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  border-radius: 0.8rem;

  /* flex-wrap: nowrap; */
  /* background-color: red; */
  /* display: flex; */
  /* justify-content: center; */
  width: 80%;
  /* margin: 0 auto; */
  /* margin-left: 50px; */
}


.shimerpic{
  width: 100%;
  height: 100%;
}
/* @media ( max-width: 800px) {
 .mdiv1{
  width: 90%;
  background-color: orange;
 }
} */

.sliderDiv {
  width: 100%;
  /* border: 1px solid blue; */
  /* background-color: var(--primary); */
  height: 300px;
}

.sliderDiv2 {
  width: 80%;
  margin: 0 auto;
  /* border: 1px solid green; */
}
/* .slick-arrow {
  background-color: black;
  color: black;
} */

.head {
  font-weight: bold;
  font-size: 1.5rem;
}
.head2 {
  font-size: 1.2rem;
}
/* 
.ttt {
  background-color: rgb(218, 210, 210);
} */
.notfoundpic {
  width: 30rem;
  height: 30rem;
  margin: 0 auto;
}

.maindivcar {
  display: flex;
  /* justify-content: center; */
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;

  /* border: 1px solid blue; */

  /* flex-direction: column; */
  /* background-color: red; */
}

.cola {
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
}

/* .colb {
  border: 1px solid blue;
} */

.carflxa {
  flex: none;
}
/* .picdiv {
} */
.img1 {
  height: 20rem;
  width: 17rem;
  /* border: 1px solid gold; */
}

/* .cardtil {
} */

.cartexta {
  display: flex;
  justify-content: space-between;
}

.txt {
  /* font-weight: bold; */
  font-size: 1.1rem;
}

.modelpics {
  padding: 2rem;
  /* border: 1px solid red; */
}
.modelpic {
  width: 50rem;
  /* border: 1px solid red; */
}

/* .slider{
  border: 1px solid red;
} */

.sliderdiv {
  width: 90%;
  background-color: red;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .clrclas {
  background-color: gray;
} */

.nocar {
  margin: auto auto;
  color: red;
  /* border: 1px solid red; */
}
.car_icon{
  
}
.brndname {
  font-size: 2rem;
}
.modelvarint {
  font-size: 1.2rem;
}
.ril__toolbarSide {
  height: 50px;
  margin: 77px;
}

.wrap{
  height: 27rem;
  width: 30rem;
 
  overflow: hidden;
  
  padding:2rem ;
}
