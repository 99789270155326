.coutomer_wrap{
    height: 60vh;
}
@media only screen and (min-width: 900px) and (max-width: 992px) {
  .slick-list{
    height: 10rem !important;
  }
}



@media only screen and (min-width: 575px) and (max-width: 769px) {

.card_wrap{
  width: 95% !important;
}

}


@media only screen and (min-width: 768px) and (max-width: 810px) {

.card_wrap_main{
  width: 80% !important;
  margin: 0 auto !important;
}
  



}


@media only screen and (min-width: 810px) and (max-width: 899px) {

  .card_wrap_main{
    width: 75% !important;
    margin: 0 auto !important;
  }
    
  }
  







.car_img{
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}
.card_wrap{
  margin-top: 5px;
    width: 35rem;
    /* border: 1px solid red; */
    border-bottom-right-radius: 4rem;
    /* border-radius: 10px; */
    box-shadow: rgba(65, 48, 48, 0.02) 0px 8px 16px 0px, rgb(0 0 0 / 8%) 0px 2px 6px 0px;

}

.text_wrap_{

}

.slick-initialized .slick-slide {
  width: 450px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;

}




.customer_name{
    font-weight: bold;
    font-size: 1.2rem;
}

.card_wrap_main{
    /* border: 1px solid blue; */
    height: 25rem;
 /* display: flex;
 justify-content: space-evenly; */
    margin-bottom: 5rem;
}

.card_wrap_main_mobile {
    height: 25rem;
    margin-bottom: 5rem;
}





@media only screen and (max-width: 549px) and (min-width: 0px) {

  .slick-initialized .slick-slide{
    width: 97% !important;
    /* border: 1px solid  red; */

  }
    
  .card_pic_clas_m{
    height: 55% !important;
    width: 95% !important;
  }
.w___{
  width: 98% !important;
}
  }

  @media only screen and (max-width: 899px) and (min-width: 0px) {
    .card_wrap_main_mobile {
      display: none;
    }
    
    
  }

@media only screen and (max-width: 1920px) and (min-width: 900px) {
    .card_wrap_main {
      display: none;
    }
   
    
  }

.m_pic_wrap{
  display: flex;
  align-items: center;
  justify-content: right;
}
.card_pic_clas_m{
  height: 100%;


  background-size: 100% 100%;
background-size: contain;
background-size: cover;
}
.card_pic_clas{
 height: 100%;


    background-size: 100% 100%;
background-size: contain;
background-size: cover;
}