.watchbody1 {
    margin-bottom: 2%;
    height: 100%;
    width: 100%;
  }
  
  .watcdisplaylayout {
    padding: 2%;
    flex-direction: row;
    display: flex;
  }
  .watcdetailcontainer {
    width: 100%;
    display: flex;
  }
  .watccurrentbidContainer {
    align-items: center;
    width: 100%;
  }
  .watcseconddetail {
    width: 100%;
    height: 22%;
    display: flex;
    flex-direction: column;
  }
  .watctexttitle {
    font-size: 1rem;
    font-weight: bold;
  }
  .watctext {
    font-size: 0.8rem;
  }
  .watctimelable {
    position: absolute;
    bottom: 0%;
    right: 0%;
    height: 20%;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    display: flex;
  }
  .watcbid {
    position: absolute;
    bottom: 0%;
    left: 0%;
    height: 20%;
    width: 25%;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    display: flex;
  }
  .watcfirst {
    position: relative;
    width: 100%;
    /* background-color: green; */
  }
  img {
    height: 100%;
    width: 100%;
  }
  .watciconcontainer {

    flex-direction: row;
    display: flex;
    position: relative;
    height: 25%;
    width: 22%;
    left: 1%;
    bottom: 95%; 
  }
  .watcicon {
    border: 2px solid whitesmoke;
    border-radius: 100%;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
 align-self: center;
    /* margin-left: 2%; */
  }
  .watcicontext {
    font-size: 0.8rem;
    color: white;

    height: 100%;
    width: 100%;
  }
  .watciconi {
    font-size: 1.4rem;
    color: white;
    /* text-align: center; */
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
  }
  .watcsecond {
    position: relative;
    padding: 2%;
    width: 100%;
  }
  
  @media only screen and (max-width: 600px) {
    /* For mobile phones: */
  
    .watcbody1 {
      margin-bottom: 3%;
      height: 100%;
      width: 100%;
    }
  
    .watcdisplaylayout {
      padding: 2%;
      height: 100%;
      flex-direction: column;
      display: flex;
    }
    .watcdetailcontainer {
      width: 100%;
      height: 100%;
      display: flex;
    }
    .watcseconddetail {
      width: 100%;
      height: 100%;
      display: flex;
    }
    .watctexttitle {
      font-size: 1rem;
      font-weight: bold;
    }
    .watctext {
      font-size: 0.8rem;
    }
    .watctimelable {
      position: absolute;
      bottom: 10%;
      right: 0%;
      height: 20%;
      width: 60%;
      flex-direction: row;
      justify-content: space-between;
      text-align: center;
      display: flex;
    }
    /* .bid{
      position: absolute;
      bottom: 10%;
      left: 35%;
      height: 20%;
      width: 30%;
      flex-direction: row;
      justify-content: center;
      text-align: center;
      display: flex;
    } */
    .watcfirst {
      position: relative;
      width: 100%;
      height: 100%;
      /* background-color: green; */
    }
    img {
      height: 100%;
      width: 100%;
    }
    .watciconcontainer {
      flex-direction: row;
      display: flex;
      position: absolute;
      height: 30%;
      width: 100%;
      left: 1px;
      bottom: 90%;
    }
    .watcicon {
      margin: 2%;
      height: 60%;
      width: 15%;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
    }

    .watciconi {
      display: flex;
      justify-content: center;
      font-size: 1rem;
      height: 100%;
      width: 100%;
    }
    .watcsecond {
      position: relative;
      height: 100%;
      width: 100%;
    }
    .watcthird {
      flex-direction: row;
      width: 100%;
      height: 100%;
      background-color: whitesmoke;
      display: flex;
  
      align-items: center;
    }
  }
  
  @media (min-width: 601px) and (max-width: 887px) {
    .watcbody1 {
      margin-bottom: 1%;
      height: 100%;
      width: 100%;
    }
  
    .watcdisplaylayout {
      padding: 2%;
      height: 100%;
      flex-direction: column;
      display: flex;
    }
    .watcdetailcontainer {
      width: 100%;
      height: 100%;
      display: flex;
    }
    .watcseconddetail {
      width: 100%;
      height: 100%;
      display: flex;
    }
    .watctexttitle {
      font-size: 0.7rem;
      font-weight: bold;
    }
    .watctext {
      font-size: 0.6rem;
    }
    .watctimelable {
      position: absolute;
      bottom: 10%;
      right: 0%;
      height: 20%;
      width: 60%;
      flex-direction: row;
      justify-content: space-between;
      text-align: center;
      display: flex;
    }

    .watcfirst {
      position: relative;
      width: 100%;
      height: 100%;
      /* background-color: green; */
    }
    img {
      height: 100%;
      width: 100%;
    }
    .watciconcontainer {
      flex-direction: row;
      display: flex;
      position: absolute;
      height: 20%;
      width: 100%;
      bottom: 0%;
      left: 1px;
      justify-content: space-between;
    }
 
    .watciconcontainer {

      position: absolute;
      justify-content: center;
      height: 30%;
      width: 25%;
      bottom: 65%;
      left: 0%;
    }
    .watciconi {
      font-size: 1.4rem;
      height: 100%;
      width: 100%;
      left: 0%;
    }
    .watcsecond {
      position: relative;
      height: 100%;
      width: 100%;
    }
    .watcthird {
      flex-direction: row;
      width: 100%;
      height: 100%;
      background-color: whitesmoke;
      display: flex;
  
      align-items: center;
    }
  }
  
  @media (min-width: 888px) and (max-width:1200px){
    .watcdisplaylayout {
      padding: 2%;
      height: 100%;
    }
    .watctexttitle {
      font-size: 0.8rem;
      font-weight: bold;
    }
    .watctext {
      font-size: 0.7rem;
    }
    .watctimelable {
      position: absolute;
      bottom: 0%;
      right: 0%;
      height: 20%;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      text-align: center;
      align-items: center;
      display: flex;
    }
    .watciconcontainer {

      position: absolute;
      justify-content: center;
      height: 30%;
      width: 25%;
      bottom: 65%;
      left: 0%;
    }
    .watciconi {
      font-size: 1.4rem;
      height: 100%;
      width: 100%;
      left: 0%;
    }
    
  }

  