@import "~antd/dist/antd.css";
/*Color Pallet link https://colors.muz.li/palette/c3382a/88271d/ffd2cd/ffa49b/ffa49b */
:root {

  --primary: #178971;
  --primaryDark: #88271d;
  --primaryLight: #ffa49b;
  --secondary: #454444;
  --borderColor: #d9d9d9;
  --secondary2: #bfd3c5;

 --yellow:#FEF200;
  --info: #f4f6f5;
  --infoPlus: #f2f2f2;
  --primaryInfo: #ffd2cd;
  --success: #16c60c;
  --nav-item-select: #FEF200;
  --nav-item-unselect: #f4f6f5;
  --nav-item-hover: #FEF200;
  --nav-bar-up-side-color:#fff;
  

  /* default font */
  --font: "Montserrat", sans-serif;
}

/* removing default styles of button tag */
.showInterest_headingCont{
  background-color: var(--primary) !important;
}
button {
  display: inline-block;
  border: none;
  padding: 0px;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  background: none;
  /* transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none; */
}

/* button:hover, */

/* button:focus {
  background: var(--primary);
} */

button:focus {
  outline: none;
  outline-offset: -4px;
}

button:active {
  transform: scale(0.99);
}

/* Button styles end */
input {
  border: none;
  outline: none;
}
input:focus {
  border: none;
  outline: none;
}
ol li{
  text-indent: -2.3rem;
}
/* Link,
Link:hover,
Link:focus,
Link:active {
  text-decoration: none;
  color: inherit;
}
NavLink,
NavLink:hover,
NavLink:focus,
NavLink:active {
  text-decoration: none;
  color: inherit;
} */
a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}
/* CTAButton */
*[name="CTAButton"] {
  background: var(--primary);
  width: 100%;
  margin: 0 auto;
  padding: 0.5rem;
  color: var(--info);
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  transition: all 0.3s ease;
  border: 0.3rem solid var(--primary);
  cursor: pointer;
}
*[name="CTAButton"]:hover {
  background: var(--yellow);
  color: inherit;
}
/* TextButton */
*[name="TextButton"] {
  /* background: transparent; */
  width: 100%;
  margin: 0 auto;
  padding: 0.5rem;
  color: var(--info) !important;
  font-size: 1rem;
  font-weight: bold;
  /* text-transform: uppercase; */
  /* letter-spacing: 0.1rem; */
  transition: all 0.3s ease;
  /* border: 0.3rem solid var(--primary); */
  cursor: pointer;
}
*[name="TextButton"]:hover {
  color: var(--info) !important;
}
/* TransparentButton */
*[name="TransparentButton"] {
  /* background: transparent; */
  width: 100%;
  margin: 0 auto;
  padding: 0.5rem;
  color: black;
  font-size: 1rem;
  font-weight: bold;
  /* text-transform: uppercase; */
  /* letter-spacing: 0.1rem; */
  transition: all 0.3s ease;
  border: 0.2rem solid var(--primary);
  cursor: pointer;
}
*[name="TransparentButton"]:hover {
  background: var(--primary);
}
/* default styles using classes */

/* background colors */
.background-info {
  background: var(--info);
}
.cardShadow {
  box-shadow: -2px 2px 6px -1px rgba(69, 68, 68, 0.75);
  -webkit-box-shadow: -2px 2px 6px -1px rgba(69, 68, 68, 0.75);
  -moz-box-shadow: -2px 2px 6px -1px rgba(69, 68, 68, 0.75);
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollBar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


.ril__toolbar {
  top: 72px !important;
background: none !important;
}


.styles-module_close__2I1sI{
  opacity: unset !important;
}

textarea {
  resize: none;
}

.MuiFormGroup-root{
  width: 100% !important;
  padding-right:0px !important;
}

.MuiFormGroup-root label{
  padding-right: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
  cursor: pointer;
}