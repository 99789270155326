* {
  box-sizing: border-box;
}
img {
  border: 0;
}
.wrapper_main {
  width: 800px;
  height: 450px;
  /* margin: 0 auto; */
}

.wrapper {
  width: 100%;
  height: 100%;
  /* margin: 0 auto */
  display: flex;
  position: relative;
   
}
#zoom-preview {
  position: absolute;
  top: 0;
  z-index: 100000000;
}

.magnifier-info {
  display: none;
  float: left;
  border: solid 1px #ccc;
  width: 200px;
  height: 133px;
  clear: left;
  margin-top: -4px;
}

.magnifier-thumb-wrapper > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
/* ccccccccccccccccccccccccccccccccccc */

.img-zoom-container {
  position: relative;
}

.img-zoom-lens {
  position: absolute;
  border: 1px solid #d4d4d4;
  /*set the size of the lens:*/
  width: 40px;
  height: 40px;
}

.img-zoom-result {
  border: 1px solid #d4d4d4;
  /*set the size of the result div:*/
  width: 400px;
  height: 300px;
}
