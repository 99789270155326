@import "../../App.css";
/* desktop nav bar */

.courve-nav-bar-container-desktop {
  display: flex;
  width: 100%;
  background: var(--primary);
}

.courve-nav-bar-logo-container {
  width: 30%;
}

.courve-nav-bar-body {
  width: 70%;
}

.courve-nav-bar-body-s {
  display: flex;
  flex: 1;
}

.courve-nav-bar-body-s1-main {
  /* background: #fff; */
  width: 100%;
  position: relative;
  /* overflow: hidden; */
}

.courve-nav-bar-body-s1 {
  width: 100%;
  background: var(--nav-bar-up-side-color);
  /* padding: 0.2rem; */
  /* position: relative; */
  /* border-bottom-left-radius: 157px
; */
  /* -webkit-clip-path: polygon(100% 0%, 100% 100%, 16% 81%, 0 0);
    clip-path: polygon(100% 0%, 100% 100%, 5% 100%, 0 0); */
}

.courve-nav-bar-section-1-curve {
  /* -webkit-clip-path: ellipse(25% 40% at 50% 50%);
  clip-path: ellipse(29% 27% at 24% 69%); */
  background: var(--nav-bar-up-side-color);
  /* padding: 1rem;
  width: 200px;
  position: absolute;
  left: 0;
  z-index: 1;
  height: 200%;
  transform: rotate(25deg);
  margin-top: -10px; */
  width: 8%;
  border-bottom-left-radius: 120%;
}

.courve-nav-bar-body-s2 {
  width: 100%;
  padding: 0.25rem;
}

.courve-nav-bar-body-s1-child {
  width: 100%;
  margin-right: 6%;
}

.section1Button {
  color: #002752;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.9rem;
  transition: ease-in-out 0.2s;
  letter-spacing: 2px;
  text-transform: capitalize;
  padding: 5px;
}

.section1Button:hover {
  color: #48BA7E;
}

.vertical-divider {
  padding: 0.5px;
  background: #002752;
  margin: 5px 0px;
}

.section1Button-icon {
  font-size: 1.3rem;
  margin-right: 15px;
}

/* ///////////////////////////// desktop view styles //////////////////////////////*/

/* mobile and tablet view styles */

.courve-nav-bar-container-mobile {
  display: none;
  width: 100%;
  background: var(--primary);
  padding: 0px 10px;
  z-index: 1;
}

.mobile-drawer-main-container {
  z-index: 0;
  background: transparent;
}

.courve-nav-bar-mobile-left-button {
}

.mobile-nav-button {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.9rem;
  transition: ease-in-out 0.2s;
  letter-spacing: 2px;
  text-transform: capitalize;
  padding: 5px;
}

.mobile-nav-button:hover {
  color: #FEF200;
}

.mobile-nav-button-icon {
  font-size: 1.8rem;
  color: #fff;
}

.mobile-nav-menu-button {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.2rem;
  transition: ease-in-out 0.2s;
  letter-spacing: 2px;
  text-transform: capitalize;
  padding: 5px;
  background: none;
  outline: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
}

.mobile-nav-menu-button-s1 {
  color: #002752;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.9rem;
  transition: ease-in-out 0.2s;
  letter-spacing: 2px;
  text-transform: capitalize;
  padding: 5px;
  background: none;
  outline: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
}

.mobile-nav-menu-button-s1:hover {
  color: #48BA7E;
}

.mobile-dawer-body-cont {
  padding: 10px;
}

.mobile-dawer-body-links {
  display: flex;
  flex: 1;
  padding: 5px 10px;
  margin: 10px 5px;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: inherit;
  font-size: 1.2rem;
}

.mobile-dawer-body-links > span {
  /* background: red; */
}

.mobileDrawer_wraper_container {
  display: none;
}

/* ///////////////////////////// mobile and tablet view styles //////////////////////////////*/

@media only screen and (max-width: 1023px) and (min-width: 0px) {
  .courve-nav-bar-container-desktop {
    display: none;
  }
  .courve-nav-bar-container-mobile {
    display: flex;
    align-items: center;
  }
  .mobileDrawer_wraper_container {
    display: inline-block;
  }
}


.navlogo{
  height: 6.6rem;
  width: 14rem;
  cursor: pointer;
  /* border: 1px solid red; */
}
/*  */

@media only screen and (min-width: 400px) and (max-width: 522px) {
  .navlogo {
    object-fit: contain;
    /* width: 80%!important; */
  }


}
@media only screen and (max-width: 400px) {
  .navlogo {
    object-fit: contain;
    /* width: 3rem; */
  }
  .mobile-nav-button-icon {
    font-size: 1.4rem;
  }
  .mobile-nav-button {
    font-size: 0.5rem;
  }
  .vertical-divider {
    margin: 15px 0px;
  }
}
@media print {
  #curve-nav-bar-main-cont {
    display: none;
  }
}
