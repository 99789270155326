.displaylayout {
  padding: 2%;
  flex-direction: row;
  display: flex;
}
.detailcontainer {
  width: 100%;
  display: flex;
}

.seconddetail {
  width: 100%;
  height: 22%;
  display: flex;
  flex-direction: column;
}
.texttitle {
  font-size: 1rem;
  font-weight: bold;
}
.text {
  font-size: 0.8rem;
}
.timelable {
  position: absolute;
  bottom: 0%;
  right: 0%;
  height: 20%;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  display: flex;
}
.bid {
  position: absolute;
  bottom: 0%;
  left: 0%;
  height: 20%;
  width: 25%;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  display: flex;
}
.first {
  position: relative;
  width: 100%;
  /* background-color: green; */
}
img {
  height: 100%;
  width: 100%;
}
.iconcontainer {
  flex-direction: row;
  display: flex;
  position: relative;
  height: 15%;
  width: 100%;
  bottom: 17%;
  left: 1%;
}
.icon {
  margin-right: 2%;
  border: 1px solid whitesmoke;
  height: 100%;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2%;
}
.icontext {
  font-size: 0.8rem;
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.iconi {
  font-size: 1.2rem;
  color: white;
  align-items: center;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.second {
  position: relative;
  padding: 2%;
  width: 100%;
}
.third {
  flex-direction: column;
  width: 100%;
  background-color: whitesmoke !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ----------- wish list ----------------- */

.wish_list_main_cont {
  position: absolute;
  z-index: 1;
  background: var(--primary);
  top: 0;
  right: 0;
  padding: 10px;
  border-bottom-left-radius: 10px;
}
.wish_list_main_cont:hover {
  box-shadow: -5px 5px 7px -6px rgba(69, 68, 68, 0.51);
  -webkit-box-shadow: -5px 5px 7px -6px rgba(69, 68, 68, 0.51);
  -moz-box-shadow: -5px 5px 7px -6px rgba(69, 68, 68, 0.51);
}
.wish_list_icon {
  color: var(--white);
  font-size: 1.8rem;
  transition: 0.3s ease-in-out;
}
@media only screen and (max-width: 400px) {
  .wish_list_main_cont {
    padding: 5px;
  }
  .wish_list_icon{
    font-size: 1rem;
  }
}
