.progressIndicatorcont {
  /* background: var(--primary); */
  width: 100%;
  margin: 5px 0;
}
.progressIndicator {
  background: var(--primaryLight);
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  transition: all 0.3s linear;
}
.progressIndicatorText {
  color: var(--primary);
  padding: 0.5rem 1rem;
  margin: 0 2rem;
}

@media only screen and (max-width: 991px) {
  .progressIndicatorText {
    display: none;
  }
}
