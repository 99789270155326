.showInterest_headingCont {
    color: var(--white);
    background-color: #48BA7E;
    padding: 5rem;
    /* padding-left: 12rem; */
  }
  .showInterest_heading {
    font-size: 2.5rem;
    line-height: 2.8rem;
    font-weight: bold;
    text-transform: uppercase;
 
    
  }
  .showInterest_description {
    font-size: 1.4rem;
    line-height: 1.8rem;
    padding: 1rem;
  }


  .card_wrap_contactus{
      /* height: 30vh; */
      background-color: #F2F4F5;

  }

  .card_contact{
      background-color: white;
  }

  .mobile_svg{
      height: 4rem;
      width: 4rem;
  }

  .sell_car{
      color: #48BA7E;
  }

  .btn_contact{
      /* background-color: #FFD700; */
      width: 100%;
      height: 3rem;
      font-weight: bold;
    
  }
  .btn_contact:hover{
      /* border: 4px solid #FFD700; */
      /* background-color: white; */
      width: 100%;
      height: 3rem;
      font-weight: bold;
  }

  .card_row{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90%;
      margin: 0 auto;
  }

  .text_tow{
      font-size: 1rem;
      color: black;
      font-family: Arial, Helvetica, sans-serif;
  }

  /* @media screen and (max-width: 767px) {
    .mobile-space {
        margin-top:60px;
        background-color: red;
    }
    } */
.inp_heading{
    text-align: start;
}


    .contact_wrap{
        background-color: #F2F4F5;
        text-align: center;
        /* height: 70vh; */
    }

    .inp_wrap{
        border: 2px solid #096C59;
        height: 3.7rem;
        background-color: white;
        padding: 0.7rem;
    }

.inp_wrap_coment{

    border: 2px solid #096C59;
    height: 5.7rem;
    background-color: white;
    padding: 0.7rem;

}



    .inp_contact{
        width: 100%;
        padding: 5px;
        color: black;
        /* border: 1px solid red; */
    }

    .main_Wrap_ss{
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 0 8px #1d1d1b40;
        min-height: 460px;
        padding: 48px;
        text-align: left;
    }
    
    .inp_main{
        width: 100%;
        display: flex;
        border: 2px solid #afada3;
    }
    
    .city_list{
            background-color: var(--generic-bg-color);
        border: 2px solid #afada3;
        border-radius: 0 0 4px 4px;
        border-top: 0;
        box-shadow: 1px 1px 1px #e2e1d9, -1px 1px 1px #e2e1d9, 0 1px 1px #e2e1d9;
        left: 0;
        /* height: 300px; */
        /* /* min-height: 300px; */
        max-height: 300px; 
        
        top: 100%;
        width: 100%;
        overflow: auto;
        text-align: left;
    }
    
    .lii{
        list-style: none;
      line-height: 12px;
      padding: 5px;
        font-weight: 2rem;
        list-style-position:inside;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .lii:hover{
        /* background-color: #afada3; */
        cursor: pointer;
    }
    
    .kkkkkkkk{
        /* padding: 50px; */
        padding-bottom: 100px;
    }
    
    .inp_inp{
        -webkit-appearance: none;
        background-color: var(--generic-bg-color);
        border: 2px solid var(--generic-border-color);
        border-radius: 4px;
        color: var(--generic-text-color);
        font-size: 16px;
        font-weight: 400;
        height: 48px;
        margin: 0;
        outline: none;
        padding: 0 16px;
        text-align: left;
        width: 100%;
    
    }

    .abca{
        background-color: white;
    }
    
    
    .srech_{
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }