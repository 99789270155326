.mainDiv {
  /* width: 40rem; */
  height: 20rem;
  /* border: 1px solid red; */
}











@media screen and (max-width: 375px) {
  
 .btn12{
   width: 100% !important;
 }
 .inp1{
   width: 100% !important;
 }
  
  } 



.inp1 {
  /* text-align: center; */
  border: 0.2rem solid black;
  border-radius: 0.2rem;
  width: 25rem;
  height: 3rem;
  margin: 0 auto;
}
.inptext {
  font-size: 1.1rem;
  width: 20rem;
}

.inp12 {
  text-align: center;
  border: 0.2rem solid black;
  border-radius: 0.2rem;
  width: 300px;

  margin: 0 auto;
}

.inp1:focus {
  border: 0.2rem solid black;
  border-radius: 0.2rem;
}

.inpdiva {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid red; */
}

.btn12 {
  background: var(--primary);
  width: 25rem;
  height: 3rem;
  margin: 0 auto;
  border-radius: 0.5rem;
  padding: 0.5rem;
  color: var(--info);
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  transition: all 0.3s ease;
  border: 0.3rem solid var(--primary);
  cursor: pointer;
}

.regno {
  width: 300px;
  height: 2rem;
  border: 1px solid red;
}

.reg {
  font-size: large;
  padding: 0.5rem;
}

.heading1 {
  font-size: 2.5rem;
  text-transform: uppercase;
}

.cartext1 {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  /* border: 1px solid red; */
}
.carflx1 {
  /* border: 1px solid red; */
  width: 50%;
  display: flex;
  flex-direction: column;
}

.showInterest_headingCont {
  color: var(--white);
  /* padding: 1rem; */
}
.showInterest_heading {
  font-size: 2.5rem;
  line-height: 2.8rem;
  font-weight: bold;
  text-transform: uppercase;
}
.showInterest_description {
  font-size: 1.4rem;
  line-height: 1.8rem;
}




@media screen and (max-width:375px) {

  .showInterest_heading{
    font-size: 2.3rem !important;
  }
  


  .inpdiva{
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid red; */
    margin: auto;
  }
.inptext{
  width: auto !important;
}


  
}