.product_list_card_main_cont {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  border-radius: 10px;
  padding: 1vw;
  /* position: relative; */

  transition: 0.18s ease-in-out;
  /* margin: 10px 0px; */
  position: relative;
}
.product_list_card_main_cont_div {
  border-radius: 10px;

  -webkit-box-shadow: 0px 1px 10px -5px rgba(69, 68, 68, 0.8);
  -moz-box-shadow: 0px 1px 10px -5px rgba(69, 68, 68, 0.8);
  box-shadow: 0px 1px 10px -5px rgba(69, 68, 68, 0.8);
  transition: 0.18s ease-in-out;
  margin: 10px 0px;
  position: relative;
}

/* .product_list_card_main_cont:hover {
  background: var(--info);
}
.product_list_card_main_cont:hover .product_list_card_features_text {
  background: var(--white);
} */

.product_list_card_title {
  font-size: 1.15rem;
  font-weight: 600;
}
.product_list_card_timer_title {
  font-size: 0.7rem;
  font-weight: 600;
}

.product_list_card_col_2 {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.product_list_card_features_text {
  font-size: 0.65rem;
  background: var(--info);
  color: var(--secondary);
  padding: 2px 5px;
  margin: 2px;
  text-transform: capitalize;
  border-radius: 2px;
  display: inline-block;
  transition: 0.15s ease-in-out;
}

.product_list_card_image_wrapper {
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
}

.product_list_card_image {
  transition: transform 0.2s;
  /* Animation */
  width: 100%;
  height: 100%;
  margin: 0 auto;
  object-fit: contain;
}

.imageHover:hover .product_list_card_image {
  transform: scale(1.15);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
.list_card_timer_time {
  font-size: 0.8rem;
  font-weight: 700;
  color: var(--secondary);
}
.list_card_timer_label {
  font-size: 0.4rem;
  text-transform: uppercase;
}
.product_list_card_timer_title{

}
@media only screen and (max-width: 400px) {
  .product_list_card_title {
    font-size: 0.85rem;
  }
  .product_list_card_timer_title {
    font-size: 0.6rem;
  }
  .product_list_card_features_text {
    font-size: 0.5rem;
    padding: 0px 3px;
    margin: 1px;
  }
  .list_card_timer_time {
    font-size: 0.6rem;
    font-weight: 600;
  }
  .list_card_timer_label {
    font-size: 0.35rem;
  }
}
.product_list_card_image {
  transition: transform 0.2s;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  object-fit: contain;
}