.product_grid_card_main_cont {
  color: inherit;
  text-decoration: inherit;
  overflow: hidden;
  border-radius: 10px;
}
.product_grid_card_main_cont_div {
  color: inherit;
  text-decoration: inherit;
  border-radius: 10px;
  overflow: hidden;
  padding: 0px;
  margin: 10px;
  -webkit-box-shadow: 0px 3px 6px -1px rgba(69, 68, 68, 0.6);
  -moz-box-shadow: 0px 3px 6px -1px rgba(69, 68, 68, 0.6);
  box-shadow: 0px 3px 6px -1px rgba(69, 68, 68, 0.6);
  position: relative;
}
.product_grid_card_main_cont:hover {
  color: inherit;
  text-decoration: inherit;
}
.product_grid_card_body_cont {
  padding: 10px;
}
.list_grid_card_timer_label {
  font-size: 0.4rem;
  text-transform: uppercase;
}
@media only screen and (max-width: 400px) {
  .list_grid_card_timer_label {
    font-size: 0.3rem;
    width: 50%;
    overflow: hidden;
  }
}
.product_list_card_image {
  transition: transform 0.2s;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  object-fit: contain;
}