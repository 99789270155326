.gallery {
  width: 100%;
  margin: 0 auto;
  /* transition: 0.5s ease-in-out; */
}

.gallery .slider,
.gallery a,
.gallery .magnifier-preview {
  height: 100%;
  /* transition: 0.5s ease-in-out; */
}

.gallery ul,
.gallery li,
.gallery a,
.gallery img {
  /* transition: 0.5s ease-in-out; */
  margin: 0;
  padding: 0;
  border: 0;
  object-fit: contain;
}

.gallery .slider {
  float: left;
  border: solid 1px #aaa;
  /* width: 100%; */
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
}

.gallery ul {
  float: left;
  width: 100%;
  height: 100%;
  list-style: none;
}

.gallery  li {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  top: 0;
  left: 0;
}

.gallery a {
  display: block;
  width: 100%;
}

.gallery .magnifier-preview {
  float: left;
  width: 100%;
  border: solid 1px #aaa;
  border-left: 0;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
}

.gallery .btn-wrapper {
  clear: both;
  overflow: hidden;
  padding: 5px 0;
  width: 100%;
  text-align: center;
}

.gallery .heading {
  margin: 25px 0;
}

.gallery .title {
  font-weight: bold;
  margin: 100px 0;
}
/* */

.bg-red {
  background-color: red;
}
.bg-green {
  background-color: green;
}
.bg-yellow {
  background-color: yellow;
}
