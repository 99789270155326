

.loginbody {
  /* background-color: whitesmoke; */
  height: 100%;
  /* position: fixed; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  /* border: 1px solid red; */
  /* margin-bottom: 500px; */
}


.loginbody_signup{
  height: 100%;
  /* position: fixed; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

/* .loginbody::after{
  content: "\a";
  content: "\a";
  content: "\a";
  content: "\a";
    white-space: pre;
} */
.loginMainview {
  display: flex;
  flex-direction: row;
  margin: 5%;
  width: 100%;
  position: relative;
}
.shape {
  border-radius: 3%;
  height: 1020px;
  width: 873px;
  background-color: #343a40;
  opacity: 0.4;
  transform: rotate(51deg);
  position: absolute;
  z-index: 1000;
  top: -104px;
  right: -562px;
  overflow-y: hidden !important;
}

.shape2 {
  border-radius: 5%;
  height: 961px;
  width: 923px;
  background-color: #8e99a3;
  opacity: 0.2;
  transform: rotate(50deg);
  position: absolute;
  z-index: 900;
  top: -138px;
  right: -604px;
  overflow-y: hidden !important;
}

.Leftside {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  width: 150%;
}
.Leftsides {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 685px;
  width: 150%;
}




.logintext {
  font-size: 1.2rem;
}
.loginfield {
  box-shadow: 10%;

  margin-top: 4%;
  margin-right: 10%;
  border-radius: 1rem;
  flex-direction: column;
  background-color: white;
  display: flex;
  height: 500px;
  width: 70%;
  justify-content: center;
  align-items: center;
}

.loginfields {
  box-shadow: 10%;

  margin-top: 4%;
  margin-right: 10%;
  border-radius: 1rem;
  flex-direction: column;
  background-color: white;
  display: flex;
  height: 555px;
  width: 70%;
  /* justify-content: center; */
  align-items: center;
}






.loginfieldwrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  display: flex;
  width: 90%;
}
.formcontainer {
  width: 80%;
}
.carimage {
  box-shadow: 0px 0px 23px -5px rgb(95 96 97 / 65%);
  -webkit-box-shadow: 0px 0px 23px -5px rgb(95 96 97 / 65%);
  -moz-box-shadow: 0px 0px 23px -5px rgba(95, 96, 97, 0.65);
  border-radius: 3%;
  margin-left: 3%;
  position: relative;
  z-index: 1100;
  width: 90%;
  height: 45vh;
  /* opacity: 0.3; */
  background-color: whitesmoke !important;
}
.logincardimage {
  padding-top: 2%;
  margin: 0% auto;
  object-fit: contain;
  /* height: 50px; */
  width: 56%;
}
.imagetext {
  margin: 5%;
}
.mlogincardimage {
  display: none;
}
.registration {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

@media only screen and(min-width:500px) and (max-width: 768px) {
  .loginbody{
    margin-bottom: 100vh !important;
  }
}

@media only screen and (max-width: 499px) {
  .loginbody{
    margin-bottom: 75vh !important;
  }
  .shape{
    display: none;
  }
  .shape2{
    display: none;
  }

}

@media only screen and (max-width: 768px) {
  .loginbody {
    background-color: whitesmoke;
    /* position: fixed; */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    margin-bottom: 100vh !important;
  }
.loginbody_signup{
  margin-bottom: 40vh !important;
}
  .loginMainview {
    display: flex;
    flex-direction: column;
    /* margin: 1%; */
    width: 100%;
    position: absolute;
    z-index: 15000;
  }
  .shape {
    /* display: none; */
    border-radius: 5%;
    height: 492px;
    width: 777px;
    background-color: #343a40;
    opacity: 0.4;
    transform: rotate(45deg);
    position: absolute;
    z-index: 1000;
    top: 42px;
    right: -450px;
    overflow-y: hidden !important;
  }

  .shape2 {
    /* display: none; */

    border-radius: 5%;
    height: 897px;
    width: 962px;
    background-color: #8e99a3;
    opacity: 0.2;
    transform: rotate(47deg);
    position: absolute;
    z-index: 900;
    top: -261px;
    right: -669px;
    overflow-y: hidden !important;
  }
  .Leftside {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 580px;
    width: 100%;
  }
  .logintext {
    font-size: 1.2rem;
  }
  .loginfield {
    box-shadow: 0px 0px 23px -5px rgba(95, 96, 97, 0.65);
    -webkit-box-shadow: 0px 0px 23px -5px rgba(95, 96, 97, 0.65);
    -moz-box-shadow: 0px 0px 23px -5px rgba(95, 96, 97, 0.65);
    margin-top: 30%;
    /* margin-bottom: 10%; */
    border-radius: 1rem;
    flex-direction: column;
    background-color: white;
    display: flex;
    height: 150%;
    width: 80%;
  }
  .loginfields {
    box-shadow: 0px 0px 23px -5px rgba(95, 96, 97, 0.65);
    -webkit-box-shadow: 0px 0px 23px -5px rgba(95, 96, 97, 0.65);
    -moz-box-shadow: 0px 0px 23px -5px rgba(95, 96, 97, 0.65);
    margin-top: 50%;
    /* margin-bottom: 10%; */
    border-radius: 1rem;
    flex-direction: column;
    background-color: white;
    display: flex;
    height: 150%;
    width: 80%;
  }
  .loginfieldwrapper {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 80%;
  }
  .formcontainer {
    height: 50vh;
    width: 100%;
  }
  .carimage {
    display: none;
  }

  .imagetext {
    margin: 5%;
  }
  .mobileform {
    width: 100%;
  }
  .mlogincardimage {
    display: inline-block;
    padding-top: 5%;
    margin: 0% auto;
    object-fit: contain;
    /* height: 50px; */
    width: 50%;
  }
}
@media only screen and (max-width: 768px) {
  .mobileimagecontainer{
 display: none; 
}
.Leftsides {

  width: 100% !important;
}
}


/* @media (min-width:500px) and(max-width: 768px) {

  .loginbody{
    margin-bottom: 100vh !important;
  
  }
 
}
@media (min-width:0px) and(max-width: 500px) {
  .loginbody{
    margin-bottom: 75vh !important;
  
  }
} */


/* @media only screen and (max-width: 768px) {} */


















@media (min-width: 769px) and (max-width: 1024px) {
  .loginbody {
    background-color: whitesmoke;
    /* position: fixed; */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }

  .loginMainview {
    display: flex;
    flex-direction: row;
    margin: 5%;
    width: 100%;
    position: relative;
  }
  .shape {
    border-radius: 3%;
    height: 1020px;
    width: 873px;
    background-color: #343a40;
    opacity: 0.4;
    transform: rotate(51deg);
    position: absolute;
    z-index: 1000;
    top: -100px;
    right: -693px;
    overflow-y: hidden !important;
  }

  .shape2 {
    border-radius: 5%;
    height: 961px;
    width: 923px;
    background-color: #8e99a3;
    opacity: 0.2;
    transform: rotate(50deg);
    position: absolute;
    z-index: 900;
    top: -138px;
    right: -731px;
    overflow-y: hidden !important;
  }

  .Leftside {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    width: 150%;
  }
  .Leftsides {
    flex-direction: column;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    height: 682px;
    width: 100% !important;
  }





  .logintext {
    font-size: 1.2rem;
  }
  .loginfield {
    box-shadow: 10%;
    margin-top: 16%;
    margin-right: 10%;
    border-radius: 1rem;
    flex-direction: column;
    background-color: white;
    display: flex;
    height: 500px;
    width: 80%;
    justify-content: center;
    align-items: center;
  }
  .loginfieldwrapper {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    display: flex;
    width: 90%;
  }
  .formcontainer {
    width: 80%;
  }
  .carimage {
    box-shadow: 0px 0px 23px -5px rgb(95 96 97 / 65%);
    -webkit-box-shadow: 0px 0px 23px -5px rgb(95 96 97 / 65%);
    -moz-box-shadow: 0px 0px 23px -5px rgba(95, 96, 97, 0.65);
    border-radius: 3%;
    margin-left: 15%;
    position: relative;
    z-index: 1100;
    width: 95%;
    height: 45vh;
    /* opacity: 0.3; */
    background-color: whitesmoke !important;
  }
  .logincardimage {
    padding-top: 2%;
    margin: 0% auto;
    object-fit: contain;
    /* height: 50px; */
    width: 56%;
  }
  .imagetext {
    margin: 5%;
  }
  .mlogincardimage {
    display: none;
  }
}
