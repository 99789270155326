.showInterest_headingCont {
    color: var(--white);
    background-color: #48BA7E;
    padding: 5rem;
    /* padding-left: 12rem; */
  }
  .showInterest_heading {
    font-size: 2.5rem;
    line-height: 2.8rem;
    font-weight: bold;
    text-transform: uppercase;
 
    
  }
  .showInterest_description {
    font-size: 1.4rem;
    line-height: 1.8rem;
    padding: 1rem;
  }


  .card_wrap_contactus_{

    min-height: 43vh;
    display: flex;
    background-color: #F2F4F5;
    /* border: 1px solid red; */
    justify-content: center;

  }

  .card_contact{
      background-color: white;
      /* min-height: 45vh; */
  }

  .mobile_svga{
      height: 4rem;
      width: 4rem;
  }

  .sell_car{
      color: #48BA7E;
  }

  .btn_contact__{
      /* background-color: #FFD700; */
      width: 100%;
      height: 3rem;
      font-weight: bold;
    
  }

  .card_row_{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 0 auto;
  }

@media only screen and (max-width:992px)and (min-width:700px) {
    .card_row_{
        width: 70% !important;
    }
    
}


  .text_tow{
      font-size: 1rem;
      color: black;
      font-family: Arial, Helvetica, sans-serif;
  }

  /* @media screen and (max-width: 767px) {
    .mobile-space {
        margin-top:60px;
        background-color: red;
    }
    } */



    .contact_wrap{
        background-color: #F2F4F5;
        /* height: 70vh; */
    }

    .inp_wrap{
        /* border: 2px solid #096C59; */
        height: 3.7rem;
        background-color: white;
        padding: 0.7rem;
    }

.inp_wrap_coment{

    border: 2px solid #096C59;
    height: 5.7rem;
    background-color: white;
    padding: 0.7rem;

}



    .inp_contact{
        width: 100%;
        padding: 5px;
        color: black;
        /* border: 1px solid red; */
    }