@import url(https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css);
@import url(https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap-theme.min.css);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600&display=swap);
* {
  margin: 0px;
  padding: 0px;
  font-family: "Montserrat", sans-serif !important;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* width */

::-webkit-scrollbar {
  width: 0.7rem;
}

/* Track */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px var(--secondary2);
  border-radius: 5px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: var(--secondary2);
  border-radius: 5px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
}

/* over riding default styles */

.image-gallery-slide.center {
  height: 73vh;
}

.image-gallery-slide .image-gallery-image {
  object-fit: contain !important;
  background: var(--secondary);
  height: 84vh;
}

.image-gallery-thumbnail-inner {
  height: 12vh !important;
}

/*  */

.bg_red {
  background-color: red;
}

.bg_green {
  background-color: green;
}

.bg_yellow {
  background-color: yellow;
}
/*Color Pallet link https://colors.muz.li/palette/c3382a/88271d/ffd2cd/ffa49b/ffa49b */
:root {

  --primary: #178971;
  --primaryDark: #88271d;
  --primaryLight: #ffa49b;
  --secondary: #454444;
  --borderColor: #d9d9d9;
  --secondary2: #bfd3c5;

 --yellow:#FEF200;
  --info: #f4f6f5;
  --infoPlus: #f2f2f2;
  --primaryInfo: #ffd2cd;
  --success: #16c60c;
  --nav-item-select: #FEF200;
  --nav-item-unselect: #f4f6f5;
  --nav-item-hover: #FEF200;
  --nav-bar-up-side-color:#fff;
  

  /* default font */
  --font: "Montserrat", sans-serif;
}

/* removing default styles of button tag */
.showInterest_headingCont{
  background-color: #178971 !important;
  background-color: var(--primary) !important;
}
button {
  display: inline-block;
  border: none;
  padding: 0px;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  background: none;
  /* transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none; */
}

/* button:hover, */

/* button:focus {
  background: var(--primary);
} */

button:focus {
  outline: none;
  outline-offset: -4px;
}

button:active {
  -webkit-transform: scale(0.99);
          transform: scale(0.99);
}

/* Button styles end */
input {
  border: none;
  outline: none;
}
input:focus {
  border: none;
  outline: none;
}
ol li{
  text-indent: -2.3rem;
}
/* Link,
Link:hover,
Link:focus,
Link:active {
  text-decoration: none;
  color: inherit;
}
NavLink,
NavLink:hover,
NavLink:focus,
NavLink:active {
  text-decoration: none;
  color: inherit;
} */
a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}
/* CTAButton */
*[name="CTAButton"] {
  background: #178971;
  background: var(--primary);
  width: 100%;
  margin: 0 auto;
  padding: 0.5rem;
  color: #f4f6f5;
  color: var(--info);
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  transition: all 0.3s ease;
  border: 0.3rem solid #178971;
  border: 0.3rem solid var(--primary);
  cursor: pointer;
}
*[name="CTAButton"]:hover {
  background: #FEF200;
  background: var(--yellow);
  color: inherit;
}
/* TextButton */
*[name="TextButton"] {
  /* background: transparent; */
  width: 100%;
  margin: 0 auto;
  padding: 0.5rem;
  color: #f4f6f5 !important;
  color: var(--info) !important;
  font-size: 1rem;
  font-weight: bold;
  /* text-transform: uppercase; */
  /* letter-spacing: 0.1rem; */
  transition: all 0.3s ease;
  /* border: 0.3rem solid var(--primary); */
  cursor: pointer;
}
*[name="TextButton"]:hover {
  color: #f4f6f5 !important;
  color: var(--info) !important;
}
/* TransparentButton */
*[name="TransparentButton"] {
  /* background: transparent; */
  width: 100%;
  margin: 0 auto;
  padding: 0.5rem;
  color: black;
  font-size: 1rem;
  font-weight: bold;
  /* text-transform: uppercase; */
  /* letter-spacing: 0.1rem; */
  transition: all 0.3s ease;
  border: 0.2rem solid #178971;
  border: 0.2rem solid var(--primary);
  cursor: pointer;
}
*[name="TransparentButton"]:hover {
  background: #178971;
  background: var(--primary);
}
/* default styles using classes */

/* background colors */
.background-info {
  background: #f4f6f5;
  background: var(--info);
}
.cardShadow {
  box-shadow: -2px 2px 6px -1px rgba(69, 68, 68, 0.75);
  -webkit-box-shadow: -2px 2px 6px -1px rgba(69, 68, 68, 0.75);
  -moz-box-shadow: -2px 2px 6px -1px rgba(69, 68, 68, 0.75);
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollBar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


.ril__toolbar {
  top: 72px !important;
background: none !important;
}


.styles-module_close__2I1sI{
  opacity: unset !important;
}

textarea {
  resize: none;
}

.MuiFormGroup-root{
  width: 100% !important;
  padding-right:0px !important;
}

.MuiFormGroup-root label{
  padding-right: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
  cursor: pointer;
}
.navmain {
  background-color: gray !important;
  z-index: 10000;
}

.unselected {
  color: var(--nav-item-unselect);
  text-decoration: none;
  font-weight: bold;
  font-size: 1.1rem;
  transition: ease-in-out 0.2s;
  letter-spacing: 2px;
  text-transform: capitalize;

}
.selected {
  color: var(--nav-item-select) !important;
  text-decoration: none !important;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 1.1rem;
  text-transform: capitalize;
}
.unselected:hover {
 color: var(--nav-item-select) !important;
  text-decoration: none;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 1.1rem;
}
.back {
  padding-left: 8%;
  width: 50%;
  justify-content: center;
  align-items: center;
}

.navbt {
  height: 100%;
  width: 100%;
}
.loginsignup {
  margin-right: 5%;
  width: 13rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* float: right; */
}
.Getstarted {
  border-radius: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 20rem;
  background-color: whitesmoke;
}
.singup {
  color: grey;
  font-weight: 500;
}
.navlogo {
  object-fit: contain;
  width: 5rem;
}




a:link { text-decoration: none;

}


a:visited { text-decoration: none;
 
}

a:hover { text-decoration: none; }


a:active { text-decoration: none; 
  /* color: #FEF200 ; */
}



a{

 
}






/* ---------------------------------- */
/* .effect5 {
  color: #fff ;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.0rem;
  display: inline-block;
  overflow: hidden;
}
.effect5:before, .effect5:after {
  right: 0;
  bottom: 0;
  background: orangered;
}
.effect5:before {
  width: 100%;
  height: 2px;
  transform: translateX(-100%);
}
.effect5:after {
  width: 2px;
  height: 100%;
  transform: translateY(100%);
}
.effect5 > span {
  display: block;
  padding: 10px;
}
.effect5 > span:before, .effect5 > span:after {
  left: 0;
  top: 0;
  background: orangered;
}
.effect5 > span:before {
  width: 100%;
  height: 2px;
  transform: translateX(100%);
}
.effect5 > span:after {
  width: 2px;
  height: 100%;
  transform: translateY(-100%);
}
.effect5:hover:before, .effect5:hover:after, .effect5:hover > span:before, .effect5:hover > span:after {
  transform: translate(0, 0);
}
.effect5, .effect5 > span {
  position: relative;
   text-decoration: none;
  line-height: 24px;
  color: #fff ;
   font-weight: 500;
  font-size: 1.0rem;
   width: 50rem;
}
.effect5:before, .effect5:after, .effect5 > span:before, .effect5 > span:after {
  content: '';
  position: absolute;
  transition: transform .5s ease;
}
.effect5:hover{
color: #fff ;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.0rem;} */

/* desktop nav bar */

.courve-nav-bar-container-desktop {
  display: flex;
  width: 100%;
  background: var(--primary);
}

.courve-nav-bar-logo-container {
  width: 30%;
}

.courve-nav-bar-body {
  width: 70%;
}

.courve-nav-bar-body-s {
  display: flex;
  flex: 1 1;
}

.courve-nav-bar-body-s1-main {
  /* background: #fff; */
  width: 100%;
  position: relative;
  /* overflow: hidden; */
}

.courve-nav-bar-body-s1 {
  width: 100%;
  background: var(--nav-bar-up-side-color);
  /* padding: 0.2rem; */
  /* position: relative; */
  /* border-bottom-left-radius: 157px
; */
  /* -webkit-clip-path: polygon(100% 0%, 100% 100%, 16% 81%, 0 0);
    clip-path: polygon(100% 0%, 100% 100%, 5% 100%, 0 0); */
}

.courve-nav-bar-section-1-curve {
  /* -webkit-clip-path: ellipse(25% 40% at 50% 50%);
  clip-path: ellipse(29% 27% at 24% 69%); */
  background: var(--nav-bar-up-side-color);
  /* padding: 1rem;
  width: 200px;
  position: absolute;
  left: 0;
  z-index: 1;
  height: 200%;
  transform: rotate(25deg);
  margin-top: -10px; */
  width: 8%;
  border-bottom-left-radius: 120%;
}

.courve-nav-bar-body-s2 {
  width: 100%;
  padding: 0.25rem;
}

.courve-nav-bar-body-s1-child {
  width: 100%;
  margin-right: 6%;
}

.section1Button {
  color: #002752;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.9rem;
  transition: ease-in-out 0.2s;
  letter-spacing: 2px;
  text-transform: capitalize;
  padding: 5px;
}

.section1Button:hover {
  color: #48BA7E;
}

.vertical-divider {
  padding: 0.5px;
  background: #002752;
  margin: 5px 0px;
}

.section1Button-icon {
  font-size: 1.3rem;
  margin-right: 15px;
}

/* ///////////////////////////// desktop view styles //////////////////////////////*/

/* mobile and tablet view styles */

.courve-nav-bar-container-mobile {
  display: none;
  width: 100%;
  background: var(--primary);
  padding: 0px 10px;
  z-index: 1;
}

.mobile-drawer-main-container {
  z-index: 0;
  background: transparent;
}

.courve-nav-bar-mobile-left-button {
}

.mobile-nav-button {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.9rem;
  transition: ease-in-out 0.2s;
  letter-spacing: 2px;
  text-transform: capitalize;
  padding: 5px;
}

.mobile-nav-button:hover {
  color: #FEF200;
}

.mobile-nav-button-icon {
  font-size: 1.8rem;
  color: #fff;
}

.mobile-nav-menu-button {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.2rem;
  transition: ease-in-out 0.2s;
  letter-spacing: 2px;
  text-transform: capitalize;
  padding: 5px;
  background: none;
  outline: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
}

.mobile-nav-menu-button-s1 {
  color: #002752;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.9rem;
  transition: ease-in-out 0.2s;
  letter-spacing: 2px;
  text-transform: capitalize;
  padding: 5px;
  background: none;
  outline: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
}

.mobile-nav-menu-button-s1:hover {
  color: #48BA7E;
}

.mobile-dawer-body-cont {
  padding: 10px;
}

.mobile-dawer-body-links {
  display: flex;
  flex: 1 1;
  padding: 5px 10px;
  margin: 10px 5px;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: inherit;
  font-size: 1.2rem;
}

.mobile-dawer-body-links > span {
  /* background: red; */
}

.mobileDrawer_wraper_container {
  display: none;
}

/* ///////////////////////////// mobile and tablet view styles //////////////////////////////*/

@media only screen and (max-width: 1023px) and (min-width: 0px) {
  .courve-nav-bar-container-desktop {
    display: none;
  }
  .courve-nav-bar-container-mobile {
    display: flex;
    align-items: center;
  }
  .mobileDrawer_wraper_container {
    display: inline-block;
  }
}


.navlogo{
  height: 6.6rem;
  width: 14rem;
  cursor: pointer;
  /* border: 1px solid red; */
}
/*  */

@media only screen and (min-width: 400px) and (max-width: 522px) {
  .navlogo {
    object-fit: contain;
    /* width: 80%!important; */
  }


}
@media only screen and (max-width: 400px) {
  .navlogo {
    object-fit: contain;
    /* width: 3rem; */
  }
  .mobile-nav-button-icon {
    font-size: 1.4rem;
  }
  .mobile-nav-button {
    font-size: 0.5rem;
  }
  .vertical-divider {
    margin: 15px 0px;
  }
}
@media print {
  #curve-nav-bar-main-cont {
    display: none;
  }
}

 
.ant-drawer-body {
  padding: 0px;
}

.body {
  padding-right: 10px;
  justify-content: space-between;
  width: 100% ;
  /* background-color: red !important; */
  overflow-x: hidden;
}
.searchfilter{
  justify-content: space-between;
/* background-color: green !important; */
flex-direction: row;
display: flex;
width: 50%;
}
.serachfield{
  width: 30%;
margin-left: 5%;
}
.option{
  justify-content: center;
  flex-direction: row;
  display: flex;
}

.tap {
 border: 2px solid whitesmoke;
  margin-left: 20px;
  text-align: center;
  align-content: center;
  background-color: whitesmoke !important;
  height: 3rem;
  width: 5rem;
  border-radius: 15px;
}
@media only screen and (max-width: 768px){
  .option{
    margin-top: 3%;
    justify-content: center;
    flex-direction: row;
    display: flex;
  }
  .serachfield{
    width: 30%;
  /* margin-left: 5%; */
  }
  .searchfilter{
    justify-content: space-between;
  /* background-color: green !important; */
  flex-direction: row;
  display: flex;
  width: 70%;
  }
  
}
@media (min-width: 770px) and (max-width: 1000px){
  .option{
    margin-top: 3%;
    justify-content: center;
    flex-direction: row;
    display: flex;
  }
  .serachfield{
    width: 30%;
  /* margin-left: 5%; */
  }
  .searchfilter{
    margin-left: 5%;
    justify-content: space-between;
  /* background-color: green !important; */
  flex-direction: row;
  display: flex;
  width: 40%;
  }
}
.displaylayout {
  padding: 2%;
  flex-direction: row;
  display: flex;
}
.detailcontainer {
  width: 100%;
  display: flex;
}

.seconddetail {
  width: 100%;
  height: 22%;
  display: flex;
  flex-direction: column;
}
.texttitle {
  font-size: 1rem;
  font-weight: bold;
}
.text {
  font-size: 0.8rem;
}
.timelable {
  position: absolute;
  bottom: 0%;
  right: 0%;
  height: 20%;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  display: flex;
}
.bid {
  position: absolute;
  bottom: 0%;
  left: 0%;
  height: 20%;
  width: 25%;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  display: flex;
}
.first {
  position: relative;
  width: 100%;
  /* background-color: green; */
}
img {
  height: 100%;
  width: 100%;
}
.iconcontainer {
  flex-direction: row;
  display: flex;
  position: relative;
  height: 15%;
  width: 100%;
  bottom: 17%;
  left: 1%;
}
.icon {
  margin-right: 2%;
  border: 1px solid whitesmoke;
  height: 100%;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2%;
}
.icontext {
  font-size: 0.8rem;
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.iconi {
  font-size: 1.2rem;
  color: white;
  align-items: center;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.second {
  position: relative;
  padding: 2%;
  width: 100%;
}
.third {
  flex-direction: column;
  width: 100%;
  background-color: whitesmoke !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ----------- wish list ----------------- */

.wish_list_main_cont {
  position: absolute;
  z-index: 1;
  background: var(--primary);
  top: 0;
  right: 0;
  padding: 10px;
  border-bottom-left-radius: 10px;
}
.wish_list_main_cont:hover {
  box-shadow: -5px 5px 7px -6px rgba(69, 68, 68, 0.51);
  -webkit-box-shadow: -5px 5px 7px -6px rgba(69, 68, 68, 0.51);
  -moz-box-shadow: -5px 5px 7px -6px rgba(69, 68, 68, 0.51);
}
.wish_list_icon {
  color: var(--white);
  font-size: 1.8rem;
  transition: 0.3s ease-in-out;
}
@media only screen and (max-width: 400px) {
  .wish_list_main_cont {
    padding: 5px;
  }
  .wish_list_icon{
    font-size: 1rem;
  }
}

.dropdown-button{
    padding: 5px 10px 5px 10px;
    margin: 0 auto;
    cursor: pointer;
    width: 100%;
    font-weight: 500 !important;
}
.expanded-div{
    padding: 5px 10px 5px 10px;
    margin: 0 auto;
    width: 100%;
    
}
.divider{
    width: 90%;
    border-bottom: 1.5px solid #ccc;
    margin: 10px 0px 10px 0px;
}
.border{
    border: 2px solid #ccc;width: 100%;
}
.dropdownItems {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.tabstyle {
  background-color: #ccc;
}
.tabIcons {
  font-size: 1.2rem;
  font-weight: bold;
}
.barstyle {
  margin-top: 1%;
}
.insidetab {
  flex-direction: column;
  display: flex;
  margin-top: 1%;
  /* background-color: aqua; */
}
.outertab {
  margin: 1%;
  width: 100%;
}
.listcontainer {
  width: 100%;
  /* justify-content: center;
   flex-direction: row;
   display: flex; */
}
.liststyle {
  margin-top: 2%;
  border-bottom: 1.5px solid #ccc;
  background-color: whitesmoke;
}
.viewtext {
  font-size: 1rem;
}
.ui.menu > .item {
  background-color: #ccc !important;
}
.ui.menu > .item:not(.active) {
  background-color: whitesmoke !important;
}
.ui.pointing.menu .active.item:after {
  background-color: #ccc !important;
}
.textbox {
  justify-content: space-between;
  display: flex;
  align-items: center;
  height: 30px;
  margin: 2%;
  width: 20%;
}
.tabtext {
  font-size: 100%;
  font-weight: bold;
}
@media only screen and (max-width: 600px) {
  .insidetab {
    flex-direction: column;
    display: flex;
    margin-top: 1%;
    /* background-color: aqua; */
  }
  .outertab {
    margin: 1%;
    width: 100%;
  }
  .listcontainer {
    width: 100%;
    /* justify-content: center;
     flex-direction: row;
     display: flex; */
  }
  .logo {
    display: flex;
    justify-content: space-between;
  }

  .viewtext {
    font-size: 0.8rem;
  }
  .ui.menu > .item {
    background-color: #ccc !important;
  }
  .ui.menu > .item:not(.active) {
    background-color: whitesmoke !important;
  }
  .ui.pointing.menu .active.item:after {
    background-color: #ccc !important;
  }
  .textbox {
    justify-content: space-between;
    display: flex;
    align-items: center;
    height: 10%;
    margin: 2%;
    width: 100%;
    background-color: #ccc;
  }
  .tabtext {
    font-size: 59%;
    font-weight: w900;
  }
}
@media (min-width: 769px) and (max-width: 1200px) {
  .insidetab {
    flex-direction: column;
    display: flex;
    margin-top: 1%;
    /* background-color: aqua; */
  }
  .outertab {
    margin: 1%;
    width: 100%;
  }
  .listcontainer {
    width: 100%;
    /* justify-content: center;
     flex-direction: row;
     display: flex; */
  }
  .logo {
    display: flex;
    justify-content: space-between;
  }
  .dropdownItems {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .viewtext {
    font-size: 0.8rem;
  }
  .ui.menu > .item {
    background-color: #ccc !important;
  }
  .ui.menu > .item:not(.active) {
    background-color: whitesmoke !important;
  }
  .ui.pointing.menu .active.item:after {
    background-color: #ccc !important;
  }
  .textbox {
    justify-content: space-between;
    display: flex;
    align-items: center;
    height: 10%;
    margin: 2%;
    width: 100%;
    /* background-color: #ccc; */
  }
  .tabtext {
    font-size: 63%;
    font-weight: w900;
  }
}

.abab{
  font-weight: bold;
  font-size: 1.5rem;
}

.counter_main_container {
  background: var(--info);
  width: 80%;
  padding: 2rem;
  padding-bottom: 1.5rem;
  border-radius: 5px;
  position: relative;
}
.slider_{
  position: relative;
  width: 100%;
  /* height: 45% !important; */
  overflow: hidden;
}
.counter_text {
  font-size: 2rem;
  font-weight: 700;
  color: var(--secondary);
  /* margin: 0.2vw; */
}
.image-gallery-slide{
  height: auto !important;;
}


.image-gallery-image{
  height: auto !important;;
}




.counter_buy_now_text {
  font-size: 0.9rem;
  font-weight: 650;
  color: var(--white);
  text-transform: uppercase;
}

.counter_place_bid_cont_text {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--white);
  text-transform: capitalize;
  margin: 5px;
}

.counter_time_title {
  text-transform: uppercase;
  font-size: 12px;
}

.viewtitle_underline {
  background: var(--secondary);
  width: 30%;
  padding: 2.5px;
  margin: 8px 0px;
}

.counter_total_bidder_cont {
  position: absolute;
  top: 0;
  left: 0;
}

.counter_buy_now_cont {
  background: var(--primary);
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  padding: 0.2rem;
  border-radius: 0.2rem;
}

.counter_place_bid_cont {
  background: var(--secondary);
  border-radius: 0.5rem;
  padding: 10px;
}
.counter_buy_now_cont_ {
  background: var(--white);
  border: 1.5px solid var(--primaryDark);
  border-radius: 0.5rem;
  transition: 0.3s ease-out;
  cursor: pointer;
}
img {
    max-width: unset !important;
}
.counter_buy_now_cont_:hover {
  /* background: #fae9e7; */
  box-shadow: -2px 2px 12px -3px rgba(136, 39, 29, 0.8);
  -webkit-box-shadow: -2px 2px 12px -3px rgba(136, 39, 29, 0.8);
  -moz-box-shadow: -2px 2px 12px -3px rgba(136, 39, 29, 0.8);
}

.place_bid_input_cont {
  position: relative;
  z-index: 0;
  display: flex;
  flex: 1 1;
  width: 100%;
  /* background: red; */
  border-radius: 5px;
  border: 1px solid var(--secondary);
  /* padding: 0 0.2vw; */
  overflow: hidden;
  margin: 1vw 0px;
  box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.75);
}

.place_bid_input_cont > input {
  display: flex;
  /* flex: 1; */
  width: 95%;
  border: none;
  outline: none;
  background: none;
  margin: 0 0.5vw;
  /* font-family: var(--font); */
}

.place_bid_input_cont > input:focus {
  outline: none;
}

.data_row_item {
  box-shadow: -3px 3px 9px -4px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: -3px 3px 9px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -3px 3px 9px -4px rgba(0, 0, 0, 0.5);
  border-radius: 0.2rem;
  padding: 0.4rem 1vw;
  margin: 0.4rem 1vw;
}

.data_row_item_icon {
  color: var(--primary);
  font-size: 1.2rem;
  margin: 0.2rem;
  /* padding: 0.2rem  ; */
  text-transform: capitalize;
  text-align: center;
}

.data_row_item_title {
  font-size: 1rem;
  color: var(--secondary);
}

.data_row_item_body {
  color: var(--secondary);
  font-weight: 600;
}
.bid_animating_car_cont {
  /* width: 100%; */
  overflow: hidden;
}
.bid_animating_car {
  z-index: 1;
  width: 40px;
  position: absolute;
  left: -50px;
  -webkit-animation: bid_animating_car_animation 3s;
          animation: bid_animating_car_animation 3s;
}
@-webkit-keyframes bid_animating_car_animation {
  0% {
    left: -50px;
  }
  100% {
    left: calc(100% + 50px);
  }
}
@keyframes bid_animating_car_animation {
  0% {
    left: -50px;
  }
  100% {
    left: calc(100% + 50px);
  }
}
.ImageGallery_originalClass {
  width: 20px;
}
/* ------------------------------------------------ */

.productFeaturesList {
  flex-wrap: wrap;
  width: 90%;
  border: 1px solid #c3c3c3;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.productFeaturesList > span {
  /* background-color: yellow; */
  margin: 2px 5px;
  text-transform: capitalize;
}

.incrementDecrementBtn {
  padding: 0.09rem 0.35rem;
  /* background: #f5f5f5; */
  /* border-bottom: 0.5px solid #c9cbd8; */
  cursor: pointer;
  transition: 0.15s ease-in-out;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.incrementDecrementBtn:hover {
  background: var(--infoPlus);
  /* border-bottom: 0.5px solid #73737c; */
}

.ViewMain {
  padding: 1%;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20%;
}

.leftview {
  display: flex;
  flex-direction: column;
  max-width: 50vw;
}

.rightview {
  /* margin-left: 1%; */
  align-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tabstyle {
  border-bottom: 1.5px solid #ccc;
  /* background-color: beige; */
}

.liststyle {
  border-bottom: 1.5px solid #ccc;
  background-color: whitesmoke;
}

.Bidingtitle {
  margin-top: 2%;
  font-size: 0.9rem;
  font-weight: 600;
}

.cartitle {
  width: 100%;
  /* background-color: antiquewhite; */
}

.cardetail {
  border-radius: 10px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  width: 100%;
  /* background-color: red; */
  align-items: center;
}

.cardetailmain {
  width: 100%;
  /* background: red; */
  padding: 1rem;
  /* background-color: whitesmoke;
  border-radius: 2rem;
  padding: 5%;
  margin: 2%; */
}

.viewtitle {
  font-size: 2.5rem;
  font-weight: 800;
  color: var(--secondary);
  line-height: 2.5rem;
}

.viewtext1 {
  font-size: 1rem;
  font-weight: 500;
  margin: 0px;
}

.bidingbar {
  border-bottom: 1.5px solid #ccc;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1%;
  padding: 1%;
}

.btnbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.bidingbar2 {
  border-bottom: 1.5px solid #ccc;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40%;
  margin-top: 1%;
  padding: 2%;
}

.bidingbartext {
  color: black;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

.bidingbutton {
  border-radius: 5px;
  /* padding: 2%; */
  margin: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 25%;
  background-color: grey;
  color: white;
}

.sellertitle {
  border-bottom: 1px solid #ccc;
}

.timermain {
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: space-around;
  align-self: center;
  margin-bottom: 5%;
  margin-top: 2%;
}

.timerMobile {
  display: none;
}

/* ================clock timer============ */

#clockdiv {
  font-family: sans-serif;
  color: #fff;
  display: flex;
  font-weight: 100;
  text-align: center;
  font-size: 40px;
  width: 100%;
}

#clockdiv > div {
  padding: 15px;
  border-radius: 10px;
  background: whitesmoke;
  display: inline-block;
  margin: 3%;
  width: 80%;
  height: 120px;
}

#clockdiv div > span {
  padding: 20px;
  border-radius: 5px;
  background: grey;
  display: inline-block;
}

.smalltext {
  padding-top: 10px;
  font-size: 16px;
  color: black;
}

.mobiletabbar {
  display: none;
}

.mobileTabBar {
  display: none;
}

@media only screen and (max-width: 991px) {
  .desktopbar {
    display: none;
  }
  .mobileTabBar {
    display: inline-block;
    width: 100%;
    overflow-x: scroll;
  }
}

@media only screen and (max-width: 479px) {
  .counter_text {
    font-size: 1.5rem;
    font-weight: 650;
  }
  .counter_time_title {
    font-size: 8px;
  }
  .counter_place_bid_cont_text {
    font-size: 0.9rem;
    font-weight: 580;
   }
  .counter_buy_now_text {
    font-size: 0.8rem;
    font-weight: 620;
  }
  .viewtitle {
    font-size: 2rem;
    font-weight: 760;
    line-height: 2rem;
  }
}

.activeTab {
  background: var(--white);
}
.tab {
  padding: 1rem;
  font-weight: bold;
  color: var(--secondary);
  cursor: pointer;
  text-transform: capitalize;
}
.tabs_body_wrapper {
    background: var(--white);
    color: var(--secondary);
    padding: 1rem;
}

/* I've Webkit vendor-prefixed my CSS */

input[type="range"] {
  width: 100%;
  /* margin: 100px; */
  /* background: -webkit-gradient(linear, 0 0, 0 bottom, from(#000), to(#1f1f1f)); */
  background: var(--secondary);
  -webkit-appearance: none;
  border-radius: 10px;
  padding: 5px;
  transition: opacity 0.5s;
  position: relative;
}
input[type="range"]:hover {
  cursor: grab;
}
input[type="range"]:active {
  cursor: grabbing;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border-radius: 10px;
  /* background: -webkit-linear-gradient(top, #fafafa 0%,#dedede 50%,#cfcfcf 51%,#a3a3a3 100%); */
  /* background: var(--primary); */
  background-image: url(/static/media/right-arrow-bg-white.1de46a5a.svg);
  background-repeat: no-repeat;
  z-index: 1;
  width: 55px;
  position: relative;
  height: 35px;
  content: "Slide";
}

input[type="range"]:before {
  content: "Slide left to bid";
  color: var(--info);
  position: absolute;
  left: 100px;
  top: 10px;
  z-index: 1;
  font-size: 25px;
}

input[type="range"]::-webkit-slider-thumb:before {
  color: var(--info);
  position: absolute;
  left: 5px;
  top: -10px;
  z-index: 1;
  font-size: 56px;
  font-weight: bold;
  content: "→";
}
input[type="range"] {
  pointer-events: none;
}
input[type="range"]::-webkit-slider-thumb {
  /*Webkit Browsers like Chrome and Safari*/
  pointer-events: auto;
}
input[type="range"]::-moz-range-thumb {
  /*Firefox*/
  pointer-events: auto;
}
input[type="range"]::-ms-thumb {
  /*Internet Explorer*/
  pointer-events: auto;
}
@media only screen and (max-width: 1060px) {
   input[type="range"]:before {
  left: 80px;
  top: 10px;
   font-size: 20px;
}
}
@media only screen and (max-width: 991px) {
	input[type="range"]:before {
	left: 120px;
	top: 8px;
	font-size: 25px;
	}

}
@media only screen and (max-width: 570px) {
	input[type="range"]:before {
	left: 80px;
	top: 10px;
	font-size: 20px;
	}

}
@media only screen and (max-width: 450px) {
	input[type="range"]:before {
	left: 65px;
	top: 12px;
	font-size: 16px;
	}
}
@media only screen and (max-width: 390px) {
	input[type="range"]:before {
	left: 70px;
	top: 13px;
	font-size: 12px;
	}
}
/* .pullee {
  width: 10rem;
  appearance: none;
}
.pullee:active::-webkit-slider-thumb {
  appearance: none;
  transform: scale(1.1);
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.pullee:active::-moz-range-thumb {
  border: 0;
  transform: scale(1.1);
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.pullee:active::-ms-thumb {
  transform: scale(1.1);
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.pullee:focus {
  outline: none;
}
.pullee::-webkit-slider-thumb {
  appearance: none;
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: var(--primary);
  transform-origin: 50% 50%;
  transform: scale(1);
  transition: transform ease-out 100ms;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.pullee::-moz-range-thumb {
  border: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: var(--primary);
  transform-origin: 50% 50%;
  transform: scale(1);
  transition: transform ease-out 100ms;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.pullee::-ms-thumb {
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: var(--primary);
  transform-origin: 50% 50%;
  transform: scale(1);
  transition: transform ease-out 100ms;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.pullee::-webkit-slider-runnable-track {
  height: 1rem;
  padding: 0.25rem;
  box-sizing: content-box;
  border-radius: 1rem;
  background-color: var(--secondary2);
}
.pullee::-moz-range-track {
  height: 1rem;
  padding: 0.25rem;
  box-sizing: content-box;
  border-radius: 1rem;
  background-color: var(--secondary2);
}
.pullee::-moz-focus-outer {
  border: 0;
}
.pullee::-ms-track {
  border: 0;
  height: 1rem;
  padding: 0.25rem;
  box-sizing: content-box;
  border-radius: 1rem;
  background-color: var(--secondary2);
  color: transparent;
}
.pullee::-ms-fill-lower, .pullee::-ms-fill-upper {
  background-color: transparent;
}
.pullee::-ms-tooltip {
  display: none;
} */

* {
  box-sizing: border-box;
}
img {
  border: 0;
}
.wrapper_main {
  width: 800px;
  height: 450px;
  /* margin: 0 auto; */
}

.wrapper {
  width: 100%;
  height: 100%;
  /* margin: 0 auto */
  display: flex;
  position: relative;
   
}
#zoom-preview {
  position: absolute;
  top: 0;
  z-index: 100000000;
}

.magnifier-info {
  display: none;
  float: left;
  border: solid 1px #ccc;
  width: 200px;
  height: 133px;
  clear: left;
  margin-top: -4px;
}

.magnifier-thumb-wrapper > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
/* ccccccccccccccccccccccccccccccccccc */

.img-zoom-container {
  position: relative;
}

.img-zoom-lens {
  position: absolute;
  border: 1px solid #d4d4d4;
  /*set the size of the lens:*/
  width: 40px;
  height: 40px;
}

.img-zoom-result {
  border: 1px solid #d4d4d4;
  /*set the size of the result div:*/
  width: 400px;
  height: 300px;
}

.magnifier-thumb-wrapper {
  position: relative;
  display: block;
  top: 0;
  left: 0;

}

.magnifier-lens {
  position: absolute;
  border: solid 1px #ccc;
  z-index: 1000;
  top: 0;
  left: 0;
  overflow: hidden;
}

.magnifier-loader {
  position: absolute;
  top: 0;
  left: 0;
  border: solid 1px #ccc;
  color: #fff;
  text-align: center;
  background: transparent;
  background: rgba(50, 50, 50, 0.5);
  z-index: 1000;
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#7F323232,endColorstr=#7F323232)";
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#7F323232,endColorstr=#7F323232);
}

.magnifier-loader-text {
  font: 13px Arial;
  margin-top: 10px;
}

.magnifier-large {
  position: absolute;
  z-index: 100;
}

.magnifier-preview {
  padding: 0;
  width: 100%;
  /* height: 100%; */
  height: 550px;
  position: relative;
  overflow: hidden;
}
.magnifier-preview_wrapper {
  background: green;
  position: absolute;
  top: 0;
  right: -100%;
}
.magnifier-preview img {
  position: absolute;
  top: 0;
  left: 0;
}

.opaque {
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
}

.hidden {
  display: none;
}

.gallery {
  width: 100%;
  margin: 0 auto;
  /* transition: 0.5s ease-in-out; */
}

.gallery .slider,
.gallery a,
.gallery .magnifier-preview {
  height: 100%;
  /* transition: 0.5s ease-in-out; */
}

.gallery ul,
.gallery li,
.gallery a,
.gallery img {
  /* transition: 0.5s ease-in-out; */
  margin: 0;
  padding: 0;
  border: 0;
  object-fit: contain;
}

.gallery .slider {
  float: left;
  border: solid 1px #aaa;
  /* width: 100%; */
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
}

.gallery ul {
  float: left;
  width: 100%;
  height: 100%;
  list-style: none;
}

.gallery  li {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  top: 0;
  left: 0;
}

.gallery a {
  display: block;
  width: 100%;
}

.gallery .magnifier-preview {
  float: left;
  width: 100%;
  border: solid 1px #aaa;
  border-left: 0;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
}

.gallery .btn-wrapper {
  clear: both;
  overflow: hidden;
  padding: 5px 0;
  width: 100%;
  text-align: center;
}

.gallery .heading {
  margin: 25px 0;
}

.gallery .title {
  font-weight: bold;
  margin: 100px 0;
}
/* */

.bg-red {
  background-color: red;
}
.bg-green {
  background-color: green;
}
.bg-yellow {
  background-color: yellow;
}

.product_list_card_main_cont {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  border-radius: 10px;
  padding: 1vw;
  /* position: relative; */

  transition: 0.18s ease-in-out;
  /* margin: 10px 0px; */
  position: relative;
}
.product_list_card_main_cont_div {
  border-radius: 10px;
  box-shadow: 0px 1px 10px -5px rgba(69, 68, 68, 0.8);
  transition: 0.18s ease-in-out;
  margin: 10px 0px;
  position: relative;
}

/* .product_list_card_main_cont:hover {
  background: var(--info);
}
.product_list_card_main_cont:hover .product_list_card_features_text {
  background: var(--white);
} */

.product_list_card_title {
  font-size: 1.15rem;
  font-weight: 600;
}
.product_list_card_timer_title {
  font-size: 0.7rem;
  font-weight: 600;
}

.product_list_card_col_2 {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 100%;
}

.product_list_card_features_text {
  font-size: 0.65rem;
  background: var(--info);
  color: var(--secondary);
  padding: 2px 5px;
  margin: 2px;
  text-transform: capitalize;
  border-radius: 2px;
  display: inline-block;
  transition: 0.15s ease-in-out;
}

.product_list_card_image_wrapper {
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
}

.product_list_card_image {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  /* Animation */
  width: 100%;
  height: 100%;
  margin: 0 auto;
  object-fit: contain;
}

.imageHover:hover .product_list_card_image {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
.list_card_timer_time {
  font-size: 0.8rem;
  font-weight: 700;
  color: var(--secondary);
}
.list_card_timer_label {
  font-size: 0.4rem;
  text-transform: uppercase;
}
.product_list_card_timer_title{

}
@media only screen and (max-width: 400px) {
  .product_list_card_title {
    font-size: 0.85rem;
  }
  .product_list_card_timer_title {
    font-size: 0.6rem;
  }
  .product_list_card_features_text {
    font-size: 0.5rem;
    padding: 0px 3px;
    margin: 1px;
  }
  .list_card_timer_time {
    font-size: 0.6rem;
    font-weight: 600;
  }
  .list_card_timer_label {
    font-size: 0.35rem;
  }
}

.product_grid_card_main_cont {
  color: inherit;
  text-decoration: inherit;
  overflow: hidden;
  border-radius: 10px;
}
.product_grid_card_main_cont_div {
  color: inherit;
  text-decoration: inherit;
  border-radius: 10px;
  overflow: hidden;
  padding: 0px;
  margin: 10px;
  box-shadow: 0px 3px 6px -1px rgba(69, 68, 68, 0.6);
  position: relative;
}
.product_grid_card_main_cont:hover {
  color: inherit;
  text-decoration: inherit;
}
.product_grid_card_body_cont {
  padding: 10px;
}
.list_grid_card_timer_label {
  font-size: 0.4rem;
  text-transform: uppercase;
}
@media only screen and (max-width: 400px) {
  .list_grid_card_timer_label {
    font-size: 0.3rem;
    width: 50%;
    overflow: hidden;
  }
}

.grid_container {
  width: 100%;
  padding: 1rem;
}
.grid_card_wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.grid_card {
  width: 48%;
  margin: 0.2rem;
  display: block;
}

.auction_dropdown_wrapper {
  padding: 2vh;
  border-top: 0.2px solid var(--secondary);
  background: var(--info);
}
.filter_bar_mobile_footer {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.filter_bar_mobile_footer_button {
  font-size: 1.2rem;
  font-weight: 700;
  padding: 1.5vh;
  width: 90%;
  color: var(--white);
  align-self: center;
  border-radius: 5px;
  background: var(--primary);
}
.Abody {
  padding: 5%;
}
.listtext {
  font-size: 0.8rem;
}
.heading {
  font-size: 1.2rem;
  font-weight: 500;
}
hr.solid {
  border-top: 4px solid #bbb;
}

.Auctionbody {
  padding: 5%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.side_bar_header_main_cont {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2vh;
  background: var(--white);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  box-shadow: 0px 1px 3px -2px rgba(0, 0, 0, 0.5);
}
.side_bar_header_title {
  font-size: 1.2rem;
  font-weight: 700;
  padding: 1vh;
  color: var(--secondary);
}
.side_bar_header_title_2 {
  font-size: 1rem;
  padding: 1vh;
  color: var(--primary);
}
/* searchableListPicker */
.searchableListPicker_main_cont {
  width: 100%;
}
.searchableListPicker_input_wrapper {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 0.5px solid var(--infoPlus);
  border-radius: 5px;
  padding: 0px;
  margin: 0px;
  height: 2.5rem;
}
.searchableListPicker_input {
  font-size: 1.2rem;
  padding: 1vh;
  display: flex;
  flex: 1 1;
  background: inherit;
}
.searchableListPicker_input:focus {
  background: var(--white);
}
.searchableListPicker_input {
  padding: 1vh;
  display: flex;
  flex: 1 1;
}
.searchableListPicker_search_icon_wrapper {
  width: 2.2rem;
  height: 100%;
  /* padding: 5px; */
  background: var(--primary);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
}
.searchableListPicker_search_icon {
  font-size: 1.3rem;
  /* color: var(--white); */
}
.display_data_list_cont {
  width: 100%;
  margin-top: 1rem;

  overflow-y: hidden;
}
.display_data_list_item_cont {
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;

  padding-bottom: 1rem;
  margin: 1rem 0px;
}
.display_data_list_item_icon {
  padding: 0.5 rem;
  font-size: 1.45rem;
  font-weight: 800;

  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  text-transform: uppercase;
  color: var(--secondary);
}
.display_data_list_item_text {
  font-size: 1.2rem;
  color: var(--secondary);
  text-transform: capitalize;
  display: flex;
  flex: 1 1;
}
.display_data_show_more {
  color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchableListPicker_selected_item_text {
  font-size: 1rem;
  text-transform: capitalize;
  font-style: italic;
  font-weight: 500;
}
.searchableListPicker_remove_selected {
  width: 1.8rem;
  height: 100%;
  padding: 3px;
  cursor: pointer;
}
/* DataListPicker */
.dataListPicker_main_cont {
  padding: 0px 10px;
}
.dataListPicker_input {
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  border: 0.5px solid var(--infoPlus);
}
.dataListPicker_input:focus {
  border: 0.5px solid var(--infoPlus);
}
/* searchAbleRangePicker */

/* .searchAbleRangePicker_main_cont{} */
.searchAbleRangePicker_row {
  padding: 10px 5px;
  /* box-sizing: border-box; */
}
.searchAbleRangePicker_col_1 {
  flex: 1 1;
  text-transform: capitalize;
  color: var(--secondary);
  font-weight: 400;
}

.searchAbleRangePicker_col_2 {
  flex: 4 1;
}
/* ------------------------- */
input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--info);
  margin: 0px 5px;
  font: inherit;
  color: var(--secondary);
  width: 1.3em;
  height: 1.3em;
  border: 0.15em solid var(--secondary);
  border-radius: 0.15em;
  -webkit-transform: translateY(-0.075em);
          transform: translateY(-0.075em);
}

input[type="checkbox"] {
  /* ...existing styles */

  display: grid;
  place-content: center;
}
input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: 120ms -webkit-transform ease-in-out;
  transition: 120ms transform ease-in-out;
  transition: 120ms transform ease-in-out, 120ms -webkit-transform ease-in-out;
  box-shadow: inset 1em 1em var(--info);
}
input[type="checkbox"] {
  /* ...existing styles */
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: 120ms -webkit-transform ease-in-out;
  transition: 120ms transform ease-in-out;
  transition: 120ms transform ease-in-out, 120ms -webkit-transform ease-in-out;
  box-shadow: inset 1em 1em var(--secondary);
}

input[type="checkbox"]:checked::before {
  -webkit-transform: scale(1);
          transform: scale(1);
}
input[type="checkbox"]::before {
  /* ...existing styles */

  /* Windows High Contrast Mode */
  background-color: var(--secondary);
}
input[type="checkbox"]::before {
  /* ...existing styles */

  -webkit-transform-origin: bottom left;

          transform-origin: bottom left;
  -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
          clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
/*  */
.labeledCheckBox_label {
  text-transform: capitalize;
  color: var(--secondary);
  font-weight: 400;
  padding: 5px;
}

.wonbody{
width: 100%;
}

.wontitle{
font-size: 0.9rem;
font-weight: 500
}

.wonheading{
  font-size: 1rem;
  font-weight: 700
  }
/* .wonheading {
  font-size: 1rem;
  font-weight: 500;
} */
.wonheading2 {
    display: flex;
    text-align: center;
    font-size: 0.8rem;
    font-weight: 700;
    color: lightskyblue;
  }
hr.solid {
    border-top: 4px solid #bbb;
  }
.wonAuctionbody{
    padding: 2%;
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content:space-between ;

}
.wondetail{

 justify-content: center;
  display: flex;
  width: 100%;
  height: 5rem;
}
.wfirst{
  border-bottom-left-radius: 2px;
  border-width: 3px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
 
}
.wsecond{
  flex-direction: column;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;

}
.wthird{
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  width: 100%;

}
@media (min-width:601) and (max-width:800){

  .wonAuctionbody{
    padding: 1%;
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content:space-between ;

}
.wfirst{
  border-bottom-left-radius: 2px;
  border-width: 3px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
 
}
.wsecond{
  flex-direction: column;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;

}
.wthird{
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  width: 100%;

}
    
}
.watchbody1 {
    margin-bottom: 2%;
    height: 100%;
    width: 100%;
  }
  
  .watcdisplaylayout {
    padding: 2%;
    flex-direction: row;
    display: flex;
  }
  .watcdetailcontainer {
    width: 100%;
    display: flex;
  }
  .watccurrentbidContainer {
    align-items: center;
    width: 100%;
  }
  .watcseconddetail {
    width: 100%;
    height: 22%;
    display: flex;
    flex-direction: column;
  }
  .watctexttitle {
    font-size: 1rem;
    font-weight: bold;
  }
  .watctext {
    font-size: 0.8rem;
  }
  .watctimelable {
    position: absolute;
    bottom: 0%;
    right: 0%;
    height: 20%;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    display: flex;
  }
  .watcbid {
    position: absolute;
    bottom: 0%;
    left: 0%;
    height: 20%;
    width: 25%;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    display: flex;
  }
  .watcfirst {
    position: relative;
    width: 100%;
    /* background-color: green; */
  }
  img {
    height: 100%;
    width: 100%;
  }
  .watciconcontainer {

    flex-direction: row;
    display: flex;
    position: relative;
    height: 25%;
    width: 22%;
    left: 1%;
    bottom: 95%; 
  }
  .watcicon {
    border: 2px solid whitesmoke;
    border-radius: 100%;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
 align-self: center;
    /* margin-left: 2%; */
  }
  .watcicontext {
    font-size: 0.8rem;
    color: white;

    height: 100%;
    width: 100%;
  }
  .watciconi {
    font-size: 1.4rem;
    color: white;
    /* text-align: center; */
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
  }
  .watcsecond {
    position: relative;
    padding: 2%;
    width: 100%;
  }
  
  @media only screen and (max-width: 600px) {
    /* For mobile phones: */
  
    .watcbody1 {
      margin-bottom: 3%;
      height: 100%;
      width: 100%;
    }
  
    .watcdisplaylayout {
      padding: 2%;
      height: 100%;
      flex-direction: column;
      display: flex;
    }
    .watcdetailcontainer {
      width: 100%;
      height: 100%;
      display: flex;
    }
    .watcseconddetail {
      width: 100%;
      height: 100%;
      display: flex;
    }
    .watctexttitle {
      font-size: 1rem;
      font-weight: bold;
    }
    .watctext {
      font-size: 0.8rem;
    }
    .watctimelable {
      position: absolute;
      bottom: 10%;
      right: 0%;
      height: 20%;
      width: 60%;
      flex-direction: row;
      justify-content: space-between;
      text-align: center;
      display: flex;
    }
    /* .bid{
      position: absolute;
      bottom: 10%;
      left: 35%;
      height: 20%;
      width: 30%;
      flex-direction: row;
      justify-content: center;
      text-align: center;
      display: flex;
    } */
    .watcfirst {
      position: relative;
      width: 100%;
      height: 100%;
      /* background-color: green; */
    }
    img {
      height: 100%;
      width: 100%;
    }
    .watciconcontainer {
      flex-direction: row;
      display: flex;
      position: absolute;
      height: 30%;
      width: 100%;
      left: 1px;
      bottom: 90%;
    }
    .watcicon {
      margin: 2%;
      height: 60%;
      width: 15%;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
    }

    .watciconi {
      display: flex;
      justify-content: center;
      font-size: 1rem;
      height: 100%;
      width: 100%;
    }
    .watcsecond {
      position: relative;
      height: 100%;
      width: 100%;
    }
    .watcthird {
      flex-direction: row;
      width: 100%;
      height: 100%;
      background-color: whitesmoke;
      display: flex;
  
      align-items: center;
    }
  }
  
  @media (min-width: 601px) and (max-width: 887px) {
    .watcbody1 {
      margin-bottom: 1%;
      height: 100%;
      width: 100%;
    }
  
    .watcdisplaylayout {
      padding: 2%;
      height: 100%;
      flex-direction: column;
      display: flex;
    }
    .watcdetailcontainer {
      width: 100%;
      height: 100%;
      display: flex;
    }
    .watcseconddetail {
      width: 100%;
      height: 100%;
      display: flex;
    }
    .watctexttitle {
      font-size: 0.7rem;
      font-weight: bold;
    }
    .watctext {
      font-size: 0.6rem;
    }
    .watctimelable {
      position: absolute;
      bottom: 10%;
      right: 0%;
      height: 20%;
      width: 60%;
      flex-direction: row;
      justify-content: space-between;
      text-align: center;
      display: flex;
    }

    .watcfirst {
      position: relative;
      width: 100%;
      height: 100%;
      /* background-color: green; */
    }
    img {
      height: 100%;
      width: 100%;
    }
    .watciconcontainer {
      flex-direction: row;
      display: flex;
      position: absolute;
      height: 20%;
      width: 100%;
      bottom: 0%;
      left: 1px;
      justify-content: space-between;
    }
 
    .watciconcontainer {

      position: absolute;
      justify-content: center;
      height: 30%;
      width: 25%;
      bottom: 65%;
      left: 0%;
    }
    .watciconi {
      font-size: 1.4rem;
      height: 100%;
      width: 100%;
      left: 0%;
    }
    .watcsecond {
      position: relative;
      height: 100%;
      width: 100%;
    }
    .watcthird {
      flex-direction: row;
      width: 100%;
      height: 100%;
      background-color: whitesmoke;
      display: flex;
  
      align-items: center;
    }
  }
  
  @media (min-width: 888px) and (max-width:1200px){
    .watcdisplaylayout {
      padding: 2%;
      height: 100%;
    }
    .watctexttitle {
      font-size: 0.8rem;
      font-weight: bold;
    }
    .watctext {
      font-size: 0.7rem;
    }
    .watctimelable {
      position: absolute;
      bottom: 0%;
      right: 0%;
      height: 20%;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      text-align: center;
      align-items: center;
      display: flex;
    }
    .watciconcontainer {

      position: absolute;
      justify-content: center;
      height: 30%;
      width: 25%;
      bottom: 65%;
      left: 0%;
    }
    .watciconi {
      font-size: 1.4rem;
      height: 100%;
      width: 100%;
      left: 0%;
    }
    
  }

  

.car_l{
  display: flex;
  justify-content: center;
  align-items: center;
}


@media only  screen and (max-width:450px) {
  .animi{
    margin-left: 20px !important;
  }

  .no_car{
    font-size: 1.4rem !important;
  }
}

.landing_page_main_cont {
  display: flex;
  flex-direction: row;
  flex: 1 1;
}
.landing_page_left_cont_wrapper {
  width: calc(320px);
}
.landing_page_left_cont {
  width: calc(320px);
  overflow-y: scroll;
}
.landing_page_right_cont {
  width: calc(300px);
  overflow-y: scroll;
}
.landing_page_right_cont_wrapper {
  width: calc(300px);
}
.product-list-cont {
  display: flex;
  flex: 7 1;
  flex-direction: column;
  padding: 7px;
}
.buttons_tab_main_cont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.grid_list_toggle_button_group {
  display: flex;
  justify-content: center;
  align-items: center;
  /* align-self: center; */
}
.grid_list_toggle_button {
  border-radius: 5px;
  padding: 1vh;
  font-size: 1rem;
  font-weight: 500;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.15s ease-in-out;
  margin: 5px;
}
.mobile_filter_drawer_wrapper {
  display: none;
}
.mobile_filter_drawer_button {
  color: var(--white);
  padding: 1vh;
  margin: 0.5vh 1vw;
  border-radius: 5px;
  font-size: 1.1rem;
  background: var(--secondary);
  font-weight: 400;
}
.mobile_filter_drawer_button:hover {
  background: var(--primary);
}
/* --------------------- */
.search {
  background-color: white;
  width: 100%;
  height: 20% !important;
}
.buttonbox {
  padding: 2%;
  margin-bottom: 2%;
  flex-direction: row;
  display: flex;
  /* background-color: aqua; */
  width: 100%;
  height: 5%;
  align-items: center;
}
.buutonheading {
  font-size: 1rem;
  font-weight: 600;
}
.grid {
  margin-left: 2%;
  background-color: whitesmoke;
  /* justify-content: space-around; */
  width: 7%;
  flex-direction: row;
  display: flex;
  cursor: pointer;
}
.Bottom {
  width: 100%;
  padding: 1%;
  flex-direction: row;
  display: flex;
}
.mfirst {
  width: 50%;
  /* background-color: antiquewhite; */
}
.msecond {
  width: 100%;
}
.mthird {
  width: 50%;
  /* background-color: aquamarine; */
}

@media only screen and (max-width: 948px) and (min-width: 0px) {
  .landing_page_left_cont_wrapper {
    display: none;
    /* background: aqua; */
  }
  .filter_bar_mobile_footer {
    display: flex;
  }
  .mobile_filter_drawer_wrapper {
    display: inline-block;
  }
}
@media only screen and (max-width: 1023px) and (min-width: 0px) {
  .landing_page_right_cont_wrapper {
    display: none;
  }
}
@media (min-width: 888px) and (max-width: 1070px) {
  .mthird {
    width: 60%;
  }
}

@media (min-width: 360px) and (max-width: 700px) {
  .mthird {
    display: none;
  }
  .mfirst {
    display: none;
  }
  .buttonbox {
    padding: 2%;
    flex-direction: row;
    display: flex;
    width: 100%;
    height: 1%;
    align-items: center;
  }
  .buutonheading {
    font-size: 1rem;
    font-weight: 600;
  }
  .grid {
    margin-left: 2%;
    background-color: whitesmoke;
    justify-content: center;
    width: 13%;
    flex-direction: row;
    display: flex;
    cursor: pointer;
  }
}
@media (min-width: 700px) and (max-width: 1200px) {
  .mfirst {
    width: 50%;
    /* background-color: antiquewhite; */
  }
  .mthird {
    width: 50%;
  }
  .msecond {
    width: 100%;
  }
  .buttonbox {
    padding: 2%;
    flex-direction: row;
    display: flex;
    width: 100%;
    height: 5%;
    align-items: center;
  }
  .buutonheading {
    font-size: 1rem;
    font-weight: 600;
  }
  .grid {
    margin-left: 2%;
    background-color: whitesmoke;
    justify-content: center;
    width: 13%;
    flex-direction: row;
    display: flex;
    cursor: pointer;
  }
}

.card {
  /* background-color: aquamarine !important; */
  padding: 0%;
  margin-bottom: 2%;
}

.gridisplaylayout {
  flex-direction: column;
  display: flex;
}
.griddetailcontainer {
  width: 100%;
  display: flex;
}
.gridcurrentbidContainer {
  align-items: center;
  width: 100%;
}
.gridseconddetail {
  width: 100%;
  height: 22%;
  display: flex;
  flex-direction: column;
}
.gridtexttitle {
  font-size: 1rem;
  font-weight: bold;
}
.gridtext {
  font-size: 0.8rem;
}
.gridtimelable {
  /* position: absolute;
    bottom: 0%;
    right: 0%;
    height: 20%; */
  width: 100%;
  flex-direction: row;
  justify-content: end;
  text-align: center;
  align-items: center;
  display: flex;
}
.gridbid {
  position: absolute;
  bottom: 0%;
  left: 0%;
  height: 20%;
  width: 25%;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  display: flex;
}
.gridfirst {
  position: relative;
  /* height: 80% !important; */
  width: 100%;
  /* background-color: green; */
}
img {
  height: 100%;
  width: 100%;
}
.gridiconcontainer {
  flex-direction: row;
  display: flex;
  position: relative;
  height: 10%;
  width: 100%;
  bottom: 12%;
  left: 1%;
  /* justify-content: center; */
  align-items: center;
}
.gridicon {
  margin-right: 2%;
  border: 1px solid whitesmoke;
  height: 100%;
  width: 20%;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
}
.gridicontext {
  font-size: 0.8rem;
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.gridiconi {
  font-size: 1.2rem;
  color: white;
  align-items: center;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.gridsecond {
  position: relative;
  padding: 2%;
  width: 100%;
}
.gridthird {
  flex-direction: column;
  width: 100%;
  background-color: whitesmoke !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .main {
    background-color: aqua !important;
  }

  .gridisplaylayout {
    width: 100%;
    flex-direction: column;
    display: flex;
  }
  .griddetailcontainer {
    width: 100%;
    display: flex;
  }
  .gridcurrentbidContainer {
    align-items: center;
    width: 100%;
  }
  .gridseconddetail {
    width: 100%;
    height: 22%;
    display: flex;
    flex-direction: column;
  }
  .gridtexttitle {
    font-size: 1rem;
    font-weight: bold;
  }
  .gridtext {
    font-size: 0.8rem;
  }
  .gridtimelable {
    /* position: absolute;
      bottom: 10%;
      right: -25%;
      height: 20%; */
    width: 100%;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
  }
  .gridbid {
    position: absolute;
    bottom: 0%;
    left: 0%;
    height: 20%;
    width: 25%;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    display: flex;
  }
  .gridfirst {
    position: relative;
    height: 80% !important;
    width: 100%;
    /* background-color: green; */
  }
  img {
    height: 100%;
    width: 100%;
  }
  .gridiconcontainer {
    flex-direction: row;
    display: flex;
    position: relative;
    height: 10%;
    width: 100%;
    bottom: 12%;
    left: 1%;
    /* justify-content: center; */
    align-items: center;
  }
  .gridicon {
    margin-right: 2%;
    border: 1px solid whitesmoke;
    height: 100%;
    width: 20%;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
  }
  .gridicontext {
    font-size: 0.8rem;
    color: white;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .gridiconi {
    font-size: 1.2rem;
    color: white;
    align-items: center;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .gridsecond {
    flex-direction: row;
    width: 100%;
    position: relative;
    /* padding: 2%; */
  }
  .gridthird {
    flex-direction: row;
    width: 100%;
    background-color: whitesmoke !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 770px) and (max-width: 887px) {
}

@media (min-width: 888px) and (max-width: 1200px) {
}

.grid_container {
  width: 100%;
  padding: 1rem;
}
.grid_card_wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.grid_carda {
  /* width: 48%; */
  margin: 0.2rem;
  display: block;
}

.product_grid_card_main_cont {
  color: inherit;
  text-decoration: inherit;
  overflow: hidden;
  border-radius: 10px;
}
.product_grid_card_main_cont_div {
  color: inherit;
  text-decoration: inherit;
  border-radius: 10px;
  overflow: hidden;
  padding: 0px;
  margin: 10px;
  box-shadow: 0px 3px 6px -1px rgba(69, 68, 68, 0.6);
  position: relative;
}
.product_grid_card_main_cont:hover {
  color: inherit;
  text-decoration: inherit;
}
.product_grid_card_body_cont {
  padding: 10px;
}
.list_grid_card_timer_label {
  font-size: 0.4rem;
  text-transform: uppercase;
}
@media only screen and (max-width: 400px) {
  .list_grid_card_timer_label {
    font-size: 0.3rem;
    width: 50%;
    overflow: hidden;
  }
}
.product_list_card_image {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  object-fit: contain;
}
.grid_container {
  width: 100%;
  padding: 1rem;
}
.grid_card_wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.grid_card {
  width: 48%;
  margin: 0.2rem;
  display: block;
}
.product_list_card_image {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  object-fit: contain;
}
.product_list_card_main_cont {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  border-radius: 10px;
  padding: 1vw;
  /* position: relative; */

  transition: 0.18s ease-in-out;
  /* margin: 10px 0px; */
  position: relative;
}
.product_list_card_main_cont_div {
  border-radius: 10px;
  box-shadow: 0px 1px 10px -5px rgba(69, 68, 68, 0.8);
  transition: 0.18s ease-in-out;
  margin: 10px 0px;
  position: relative;
}

/* .product_list_card_main_cont:hover {
  background: var(--info);
}
.product_list_card_main_cont:hover .product_list_card_features_text {
  background: var(--white);
} */

.product_list_card_title {
  font-size: 1.15rem;
  font-weight: 600;
}
.product_list_card_timer_title {
  font-size: 0.7rem;
  font-weight: 600;
}

.product_list_card_col_2 {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 100%;
}

.product_list_card_features_text {
  font-size: 0.65rem;
  background: var(--info);
  color: var(--secondary);
  padding: 2px 5px;
  margin: 2px;
  text-transform: capitalize;
  border-radius: 2px;
  display: inline-block;
  transition: 0.15s ease-in-out;
}

.product_list_card_image_wrapper {
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
}

.product_list_card_image {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  /* Animation */
  width: 100%;
  height: 100%;
  margin: 0 auto;
  object-fit: contain;
}

.imageHover:hover .product_list_card_image {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
.list_card_timer_time {
  font-size: 0.8rem;
  font-weight: 700;
  color: var(--secondary);
}
.list_card_timer_label {
  font-size: 0.4rem;
  text-transform: uppercase;
}
.product_list_card_timer_title{

}
@media only screen and (max-width: 400px) {
  .product_list_card_title {
    font-size: 0.85rem;
  }
  .product_list_card_timer_title {
    font-size: 0.6rem;
  }
  .product_list_card_features_text {
    font-size: 0.5rem;
    padding: 0px 3px;
    margin: 1px;
  }
  .list_card_timer_time {
    font-size: 0.6rem;
    font-weight: 600;
  }
  .list_card_timer_label {
    font-size: 0.35rem;
  }
}
.product_list_card_image {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  object-fit: contain;
}
.buy_{
    /* border: 1px solid red; */
}


@media only screen and (max-width: 399px) {

    .bton_wrap{
        width: 100% !important;
margin: 0 auto !important;
    }
    .buy_{
        width: auto;
        /* margin-bottom: 1rem; */
        margin: 10px auto;
    }
    
    .buy_l{
        width: auto;
        margin-bottom: 1rem;
    }
}


@media only screen and (min-width:400px) and (max-width: 500px) {

    .bton_wrap{
        width: 50% !important;
margin: 0 auto !important;
    }
.buy_{
    width: auto;
    /* margin-bottom: 1rem; */
    margin: 10px auto;
}

.buy_l{
    width: auto;
    margin-bottom: 1rem;
}
}


.foter_list{
    list-style: none;
    line-height: 3rem;
    text-decoration: none;
}

.list_wrap{
    font-size: 1.2rem;
    /* line-height: 2rem; */
}

.button_heder{
    width: 10rem;
    height: 3.5rem;
    /* background-color: #FFD700; */
    font-weight: bold;
    /* color: black; */
}

.button_heder:hover{
    /* border: 4px solid #FFD700 ; */
    /* background-color: #F2F4F5; */
}


  .link {
    color: black;
  }
  


.loginbody {
  /* background-color: whitesmoke; */
  height: 100%;
  /* position: fixed; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  /* border: 1px solid red; */
  /* margin-bottom: 500px; */
}


.loginbody_signup{
  height: 100%;
  /* position: fixed; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

/* .loginbody::after{
  content: "\a";
  content: "\a";
  content: "\a";
  content: "\a";
    white-space: pre;
} */
.loginMainview {
  display: flex;
  flex-direction: row;
  margin: 5%;
  width: 100%;
  position: relative;
}
.shape {
  border-radius: 3%;
  height: 1020px;
  width: 873px;
  background-color: #343a40;
  opacity: 0.4;
  -webkit-transform: rotate(51deg);
          transform: rotate(51deg);
  position: absolute;
  z-index: 1000;
  top: -104px;
  right: -562px;
  overflow-y: hidden !important;
}

.shape2 {
  border-radius: 5%;
  height: 961px;
  width: 923px;
  background-color: #8e99a3;
  opacity: 0.2;
  -webkit-transform: rotate(50deg);
          transform: rotate(50deg);
  position: absolute;
  z-index: 900;
  top: -138px;
  right: -604px;
  overflow-y: hidden !important;
}

.Leftside {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  width: 150%;
}
.Leftsides {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 685px;
  width: 150%;
}




.logintext {
  font-size: 1.2rem;
}
.loginfield {
  box-shadow: 10%;

  margin-top: 4%;
  margin-right: 10%;
  border-radius: 1rem;
  flex-direction: column;
  background-color: white;
  display: flex;
  height: 500px;
  width: 70%;
  justify-content: center;
  align-items: center;
}

.loginfields {
  box-shadow: 10%;

  margin-top: 4%;
  margin-right: 10%;
  border-radius: 1rem;
  flex-direction: column;
  background-color: white;
  display: flex;
  height: 555px;
  width: 70%;
  /* justify-content: center; */
  align-items: center;
}






.loginfieldwrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  display: flex;
  width: 90%;
}
.formcontainer {
  width: 80%;
}
.carimage {
  box-shadow: 0px 0px 23px -5px rgb(95 96 97 / 65%);
  -webkit-box-shadow: 0px 0px 23px -5px rgb(95 96 97 / 65%);
  -moz-box-shadow: 0px 0px 23px -5px rgba(95, 96, 97, 0.65);
  border-radius: 3%;
  margin-left: 3%;
  position: relative;
  z-index: 1100;
  width: 90%;
  height: 45vh;
  /* opacity: 0.3; */
  background-color: whitesmoke !important;
}
.logincardimage {
  padding-top: 2%;
  margin: 0% auto;
  object-fit: contain;
  /* height: 50px; */
  width: 56%;
}
.imagetext {
  margin: 5%;
}
.mlogincardimage {
  display: none;
}
.registration {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

@media only screen and(min-width:500px) and (max-width: 768px) {
  .loginbody{
    margin-bottom: 100vh !important;
  }
}

@media only screen and (max-width: 499px) {
  .loginbody{
    margin-bottom: 75vh !important;
  }
  .shape{
    display: none;
  }
  .shape2{
    display: none;
  }

}

@media only screen and (max-width: 768px) {
  .loginbody {
    background-color: whitesmoke;
    /* position: fixed; */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    margin-bottom: 100vh !important;
  }
.loginbody_signup{
  margin-bottom: 40vh !important;
}
  .loginMainview {
    display: flex;
    flex-direction: column;
    /* margin: 1%; */
    width: 100%;
    position: absolute;
    z-index: 15000;
  }
  .shape {
    /* display: none; */
    border-radius: 5%;
    height: 492px;
    width: 777px;
    background-color: #343a40;
    opacity: 0.4;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    position: absolute;
    z-index: 1000;
    top: 42px;
    right: -450px;
    overflow-y: hidden !important;
  }

  .shape2 {
    /* display: none; */

    border-radius: 5%;
    height: 897px;
    width: 962px;
    background-color: #8e99a3;
    opacity: 0.2;
    -webkit-transform: rotate(47deg);
            transform: rotate(47deg);
    position: absolute;
    z-index: 900;
    top: -261px;
    right: -669px;
    overflow-y: hidden !important;
  }
  .Leftside {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 580px;
    width: 100%;
  }
  .logintext {
    font-size: 1.2rem;
  }
  .loginfield {
    box-shadow: 0px 0px 23px -5px rgba(95, 96, 97, 0.65);
    -webkit-box-shadow: 0px 0px 23px -5px rgba(95, 96, 97, 0.65);
    -moz-box-shadow: 0px 0px 23px -5px rgba(95, 96, 97, 0.65);
    margin-top: 30%;
    /* margin-bottom: 10%; */
    border-radius: 1rem;
    flex-direction: column;
    background-color: white;
    display: flex;
    height: 150%;
    width: 80%;
  }
  .loginfields {
    box-shadow: 0px 0px 23px -5px rgba(95, 96, 97, 0.65);
    -webkit-box-shadow: 0px 0px 23px -5px rgba(95, 96, 97, 0.65);
    -moz-box-shadow: 0px 0px 23px -5px rgba(95, 96, 97, 0.65);
    margin-top: 50%;
    /* margin-bottom: 10%; */
    border-radius: 1rem;
    flex-direction: column;
    background-color: white;
    display: flex;
    height: 150%;
    width: 80%;
  }
  .loginfieldwrapper {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 80%;
  }
  .formcontainer {
    height: 50vh;
    width: 100%;
  }
  .carimage {
    display: none;
  }

  .imagetext {
    margin: 5%;
  }
  .mobileform {
    width: 100%;
  }
  .mlogincardimage {
    display: inline-block;
    padding-top: 5%;
    margin: 0% auto;
    object-fit: contain;
    /* height: 50px; */
    width: 50%;
  }
}
@media only screen and (max-width: 768px) {
  .mobileimagecontainer{
 display: none; 
}
.Leftsides {

  width: 100% !important;
}
}


/* @media (min-width:500px) and(max-width: 768px) {

  .loginbody{
    margin-bottom: 100vh !important;
  
  }
 
}
@media (min-width:0px) and(max-width: 500px) {
  .loginbody{
    margin-bottom: 75vh !important;
  
  }
} */


/* @media only screen and (max-width: 768px) {} */


















@media (min-width: 769px) and (max-width: 1024px) {
  .loginbody {
    background-color: whitesmoke;
    /* position: fixed; */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }

  .loginMainview {
    display: flex;
    flex-direction: row;
    margin: 5%;
    width: 100%;
    position: relative;
  }
  .shape {
    border-radius: 3%;
    height: 1020px;
    width: 873px;
    background-color: #343a40;
    opacity: 0.4;
    -webkit-transform: rotate(51deg);
            transform: rotate(51deg);
    position: absolute;
    z-index: 1000;
    top: -100px;
    right: -693px;
    overflow-y: hidden !important;
  }

  .shape2 {
    border-radius: 5%;
    height: 961px;
    width: 923px;
    background-color: #8e99a3;
    opacity: 0.2;
    -webkit-transform: rotate(50deg);
            transform: rotate(50deg);
    position: absolute;
    z-index: 900;
    top: -138px;
    right: -731px;
    overflow-y: hidden !important;
  }

  .Leftside {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    width: 150%;
  }
  .Leftsides {
    flex-direction: column;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    height: 682px;
    width: 100% !important;
  }





  .logintext {
    font-size: 1.2rem;
  }
  .loginfield {
    box-shadow: 10%;
    margin-top: 16%;
    margin-right: 10%;
    border-radius: 1rem;
    flex-direction: column;
    background-color: white;
    display: flex;
    height: 500px;
    width: 80%;
    justify-content: center;
    align-items: center;
  }
  .loginfieldwrapper {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    display: flex;
    width: 90%;
  }
  .formcontainer {
    width: 80%;
  }
  .carimage {
    box-shadow: 0px 0px 23px -5px rgb(95 96 97 / 65%);
    -webkit-box-shadow: 0px 0px 23px -5px rgb(95 96 97 / 65%);
    -moz-box-shadow: 0px 0px 23px -5px rgba(95, 96, 97, 0.65);
    border-radius: 3%;
    margin-left: 15%;
    position: relative;
    z-index: 1100;
    width: 95%;
    height: 45vh;
    /* opacity: 0.3; */
    background-color: whitesmoke !important;
  }
  .logincardimage {
    padding-top: 2%;
    margin: 0% auto;
    object-fit: contain;
    /* height: 50px; */
    width: 56%;
  }
  .imagetext {
    margin: 5%;
  }
  .mlogincardimage {
    display: none;
  }
}

.OverLayLoaderCont {
  background: rgb(247, 247, 247,1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex: 1 1;
  z-index: 10000000;
  justify-content: center;
  align-items: center;
  /* opacity: 0.5; */
}



.landingmain_banner_cont {
  background-image: url(/static/media/city.21787154.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: var(--primary);
}
/* .landingmain_banner_body_wrapper {
} */
.landingmain_banner_left_cont {
  flex: 1 1;
}
.landingmain_banner_left_cont > .viewtitle {
  color: var(--info);
  width: 70%;
}
.landingmain_banner_right_cont {
  flex: 1 1;
}
/*  */
.offer_card_row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.offer_card {
  margin: 0.5rem;
  padding: 1rem;
  display: block;
  border-radius: 0.5rem;
  width: 25rem;
  height: 15rem;
}
.offer_card_title {
  margin: 0.5rem 0;
  font-weight: bold;
  color: var(--primary);
}
.offer_card_title_icon {
  font-size: 2.5rem;
}
.offer_card_title_text {
  font-size: 1.4rem;
}
.offer_card_description {
  margin: 0.5rem 0;
  font-size: 1.2rem;
}

/* small screen media query */
@media only screen and (max-width: 540px) {
  .landingmain_banner_left_cont {
    display: none;
  }
  .landingmain_banner_left_cont > .viewtitle {
    width: 95%;
  }
  .form_main_wrapper {
    width: 95%;
  }
  .banner_description {
    width: 95%;
  }
  /*  */
  .offer_card_row {
    justify-content: center;
    align-items: center;
  }
  .offer_card {
    display: block;
    border-radius: 0.5rem;
    width: 95%;
  }
}
/* bootstrap md breakpoint */
@media only screen and (max-width: 991px) {
  .landingmain_banner_left_cont > .viewtitle {
    width: 80%;
  }
  .form_main_wrapper {
    width: 80%;
  }
  .banner_description {
    width: 70%;
  }
  /*  */
  .offer_card_row {
    justify-content: center;
    /* align-items: center; */
  }
}
/* bootstrap md breakpoint */
@media only screen and (max-width: 350px) {
  .landingmain_banner_left_cont > .viewtitle {
    width: 95%;
  }
  .form_main_wrapper {
    width: 98%;
  }
  .banner_description {
    width: 95%;
  }
}

.form_main_wrapper {
  background: var(--info);
  height: auto;
  border-radius: 0.5rem;
  min-width: 322px;
}
.form_main_wrapper_width {
  width: 60%;
}
.form_main_wrapper_width_fluid {
  width: 100%;
}
.form_header {
  width: 100%;
  padding: 1rem;
}
.form_header_text {
  font-size: 1.6rem;
  font-weight: bold;
  color: var(--secondary);
  text-transform: capitalize;
}
/*  */
.dropdown_wrapper {
  padding: 0.5rem 1rem;
  /* padding-top: 0px; */
}
@media only screen and (max-width: 991px) {
  .form_main_wrapper_width {
    width: 90%;
  }
}
@media only screen and (max-width: 954px) {
  .form_main_wrapper_width {
    width: 95%;
  }
}
@media only screen and (max-width: 548px) {
  .dropdown_wrapper {
    padding: 0.2rem 0.5 rem;
    /* padding-top: 0px; */
  }



}

@media only screen and (min-width: 300px) and (max-width:400px) {
  
  .form_main_wrapper{
    max-width: 350px;
  }
}

@media only screen and (min-width: 400px) and (max-width:500px) {
  
  .form_main_wrapper{
    max-width: 400px !important;
    min-height: 350px !important;
  }
}

@media only screen and (min-width: 500px) and (max-width:600px) {
  
  .form_main_wrapper{
    max-width: 500px;
  }
}






@media print {
  .hideButtonOnPrint {
    display: none;
  }
}

.banner_main_cont {
  position: relative;
  
}
.banner_background_wrapper {
  /* background: #ccc; */
  position: absolute;
  top: 0;
  z-index: -1;
  height: 680px;
}

.img_d2{
  margin-left: -1px;
  width: auto;
}


.bothcard_wrap{
  align-items: center !important;

 }


.bothcard_wrap2{
  /* align-items: flex-end !important; */
width: 100%;
 }
 
.bothcard_wra{
  margin-top: 19px;
}
 @media only screen and (min-width: 991px){



  .bothcard_wrap{
    align-items: flex-end !important;
    /* width: 9%; */
    width: 85%;
   }
   .bothcard_wrap2{
     /* align-items: flex-end !important; */
   width: 100%;
    }

 }







.banner_background_wrapper2 {
  background: #ccc;
  position: absolute;
  top: 0;
  z-index: -1;
  height: 640px;
}



.card_hide{
  border: 1px solid red;
  height: 400px;
}
@media only screen and (max-width: 992px){
  .reg_car_wrap{
    width: 80% !important;
  }
}
@media only screen and (max-width: 390px) {
  .reg_car_wrap{
    width: 90% !important;
  }
  .banner_background_image {

    height: 1450px !important;
  }
  .banner_background_wrapper {
    height: 1450px !important;
  }

  .banner_background_gradient{
    height: 1450px !important;
  }

  .reg_car_wrap{
    width: 90% !important;
  }
  .banner_background_image2 {

    height: 933px !important;
  }
  .banner_background_wrapper2 {
    height: 933px !important;
  }

  .banner_background_gradient2{
    height: 933px !important;
  }


}





@media only screen and (min-width: 390px)and (max-width: 509px) {
  .reg_car_wrap{
    width: 90% !important;
  }
  .banner_background_image {

    height: 1400px !important;
  }
  .banner_background_wrapper {
    height: 1400px !important;
  }

  .banner_background_gradient{
    height: 1400px !important;
  }




  .banner_background_image2 {
     
    height: 883px   !important;
  }
  .banner_background_wrapper2 {
    height: 883px  !important;
  }

  .banner_background_gradient2{
    height: 883px  !important;
  }

}


@media only screen and (min-width: 458px)and (max-width: 480px) {



  .banner_background_image2 {
     
    height: 813px   !important;
  }
  .banner_background_wrapper2 {
    height: 813px  !important;
  }

  .banner_background_gradient2{
    height: 813px  !important;
  }

}




@media only screen and (min-width: 509px)and (max-width: 580px) {
  .banner_background_image {

    height: 1360px !important;
  }
  .banner_background_wrapper {
    height: 1360px !important;
  }

  .banner_background_gradient{
    height: 1360px !important;
  }



  .banner_background_image2 {

    height: 853px !important;
  }
  .banner_background_wrapper2 {
    height: 853px !important;
  }

  .banner_background_gradient2{
    height: 853px !important;
  }

}
@media only screen and (min-width: 581px) and (max-width: 683px) {
  .banner_background_image {

    height: 1295px !important;
  }
  .banner_background_wrapper {
    height: 1295px !important;
  }
  .banner_background_gradient{
    height: 1295px !important;
  }

  .banner_background_image2 {

    height: 800px !important;
  }
  .banner_background_wrapper2 {
    height: 800px !important;
  }
  .banner_background_gradient2{
    height: 800px !important;
  }


}
@media only screen and (min-width:683px) and (max-width: 991px) {
  .banner_background_image {

    height: 1223px !important;
  }
  .banner_background_wrapper {
    height: 1223px !important;
  }
  .banner_background_gradient{
    height: 1223px !important;
  }



  .banner_background_image2 {

    height: 810px !important;
  }
  .banner_background_wrapper2 {
    height: 810px !important;
  }
  .banner_background_gradient2{
    height: 810px !important;
  }




}
@media only screen and (min-width: 992px)  and (max-width: 1026px) {
  .banner_background_image {

    height: 760px !important;
  }
  .banner_background_wrapper {
    height: 760px !important;
  }
  .banner_background_gradient{
    height: 760px !important
  }


  .banner_background_image2 {

    height: 703px !important;
  }
  .banner_background_wrapper2 {
    height: 703px !important;
  }
  .banner_background_gradient2{
    height: 703px !important
  }


}








.banner_background_image {
  /* background-image: url(../../../assets/images/landingPageBanner.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: var(--primary);
  height: 680px;
}

.banner_background_image2 {
  /* background-image: url(../../../assets/images/landingPageBanner.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: var(--primary);
  height: 640px;
}





.banner_background_gradient {
  background-image: linear-gradient(var(--primary), var(--info));
  position: absolute;
  top: 0;
  opacity: 0.4;
  height: 680px;
}

.banner_background_gradient2 {
  background-image: linear-gradient(var(--primary), var(--info));
  position: absolute;
  top: 0;
  opacity: 0.4;
  height: 640px;
}
.banner_heading {
  line-height: 3rem;
}
.banner_description {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--info);
  line-height: 2rem;
  width: 70%;
}
@media only screen and (max-width: 991px) {
  /* .landing_page_s1 {
    height: auto !important;
  } */
}

.main_ttt{
font-size: x-large;
line-height: 40px;
}
.reg_car_wrap{
  /* border: 1px solid red; */
  /* height: 30vh; */
  width: 60%;
  max-width: 500px;
  padding: 46px;
  background-color: #f4f6f5;
  border-radius: 20px;
}

.input_main_wrap{
  border-radius: 5px;
  /* padding: 20px; */
/* background-color: white; */
  margin: 0 auto;
  height: 90px;
  border: 2px solid black;
}
.button_main_wrap{
  border-radius: 5px;
  /* padding: 20px; */
/* background-color: white; */
  margin: 0 auto;
  height: 90px;
  /* background-color: #0460cc; */
  /* color: white; */
  /* border: 2px solid black; */
}


@media only screen and (max-width: 500px){

  .value_btn{
    font-size: 15px !important;
  }
}
.value_btn{
  height: 100%;
  border-radius: 5px;
  width: 100%;
  /* color: white; */
  font-size: 22px;
  /* height: 64px; */
  line-height: 32px;
  font-weight: bold;

}
.reg_input::-webkit-input-placeholder {
  text-align: center;
}

.reg_input:-moz-placeholder {
  text-align: center;
}


.arow_{
  font-weight: bold;
}







.reg_input{
  text-align: center;
  height: 100%;
  text-transform: uppercase;

  -webkit-appearance: none;
  background-color: var(--generic-bg-color);
  border: 2px solid var(--generic-border-color);
  border-radius: 4px;
  color: var(--generic-text-color);
  font-size: 27px;
  font-weight: 700;
  
  /* height: 48px; */
  margin: 0;
  outline: none;
  padding: 0 16px;
  /* text-align: left; */
  width: 100%;
  background-color: white;
}
.landingBody {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;

  /* background-color: aquamarine; */
  /* background-color: aqua; */
}
.topsection {
  display: flex;
  flex-direction: row;
  /* margin: 1%; */
  height: 400px;
}
.topsectionright {
  display: flex;
  flex-direction: row;
  width: 125%;
  /* background-color: red; */
}
.toprightimage {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  object-fit: contain;
  border-bottom-right-radius: 7rem;
  width: 100%;
  box-shadow: 2px 2px 14px -6px rgb(0 0 0 / 50%);
  -webkit-box-shadow: 2px 2px 14px -6px rgb(0 0 0 / 50%);
  -moz-box-shadow: 2px 2px 14px -6px rgba(0, 0, 0, 0.5);
}
.circleshape {
  border-radius: 100%;
  right: -56px;
  top: -28px;
  position: absolute;
  height: 200px;
  width: 200px;
  border: 30px solid #ccc;
  opacity: 0.2;
  z-index: -10;
}

.circleshape2 {
  border-radius: 100%;
  right: 141px;
  top: 150px;
  position: absolute;
  height: 80px;
  width: 80px;
  border: 50px solid #ccc;
  opacity: 0.2;
}
.abovetext {
  width: 100%;
  /* background-color: aqua; */
  /* left: 63px; */
  /* top: 127px; */
  /* position: absolute; */
  z-index: 10;
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fixedimg {
  /* background: red; */
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -10;
}
.toptext {
  color: black;
  font-size: 1.5rem;
}
.companylogo {
  object-fit: contain;
  width: 130px;
}
.topmiddleimage {
  border: 10px solid #f2f2f2;
  margin-left: 30%;
  margin-top: 20%;
  overflow: hidden;
  object-fit: contain;
  width: 200px;
  height: 200px;
}
.regbutton {
  border-radius: 0.7rem;
  display: flex;
  justify-content: center;
  background-color: #f2f2f2;
  align-items: center;
  width: 150px;
  height: 50px;
  color: black;
}
.regbutton:hover {
  border-radius: 0.7rem;
  display: flex;
  justify-content: center;
  background-color: #f2f2f2;
  align-items: center;
  width: 150px;
  height: 50px;
  color: black;
}
.framecar {
  top: 106px;
  left: 133px;
  position: absolute;
  z-index: 0;
  object-fit: contain;
  width: 250px;
}
.framecar2 {
  -webkit-transform: rotate(3deg);
          transform: rotate(3deg);
  top: 34px;
  left: 302px;
  position: absolute;
  z-index: 15;
  object-fit: contain;
  width: 180px;
  opacity: 0.7;
}
.carwraper {
  position: relative;
  width: 100%;
}
.searchwrapper {
  display: flex;
  margin-top: 1%;
  /* background-color: aqua; */
  flex-direction: "row";
  justify-content: "space-between";
  height: 15vh !important;

  width: 100%;
}
.searchleft {
  -webkit-clip-path: polygon(0% 0, 100% 0, 75% 120%, 0 100%);
  clip-path: polygon(0% 0, 100% 0, 75% 120%, 0 100%);
  display: flex;
  height: 100%;
  width: 45%;
  flex-direction: column;
  background-color: grey;
  /* background-color: green !important; */
}
.searchright {
  flex-direction: row;
  display: flex;
  -webkit-clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
  clip-path: polygon(9% 0, 100% 0, 100% 100%, 0 100%);
  display: flex;
  height: 100%;
  width: 100%;
  /* padding-top:5% ; */
  padding-left: 5%;
  padding-bottom: 2%;
  justify-content: center;
  align-items: center;
  background-color: grey;
}
.searchcol {
  justify-content: center;
  margin: 1%;
  flex-direction: column;
  display: flex;
  width: 20%;
  height: 15vh;
}
.containerbox {
  border: 5px #ccc solid;
  width: 100%;
  height: 100%;
  background-color: #ccc !important;
}
.searchbutton {
  /* padding: 2%; */
  border: 5px solid #ccc;
  height: 100%;
  width: 80%;
  background-color: #ccc;
  display: flex;
  /* align-self: center; */
  justify-content: center;
  align-items: center;
}
.searchtext {
  margin: 2px;
  color: whitesmoke;
  font-size: 0.9rem;
  font-weight: 500;
}
.Servicescontainer {
  object-fit: contain;
  padding: 1%;
  height: 30vh;
  /* overflow: hidden; */
  margin-top: 2%;
  flex-direction: row;
  justify-content: space-evenly;
  display: flex;
  /* background-color: grey; */
}
.cardstyle {
  /* margin: 1%; */
  border-radius: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
  width: 20rem;
  height: 15rem;
}
.cardimage {
  float: right;
  object-fit: contain;
  width: 120px;
}

.sliderimage {
  border-radius: 0% 10% 10% 0%;
  background-color: grey;
  object-fit: fill;
  height: 100%;
  width: 80%;
  -webkit-clip-path: polygon(18% 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(18% 0, 100% 0, 100% 100%, 0 100%);
}
.sliderwrap {
  border-radius: 10%;
  height: 25vh;
  width: 70%;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  background-color: white;
}
.slidertextwrap {
  padding: 5%;
  border-radius: 10% 0% 0% 10%;
  background-color: grey;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
  clip-path: polygon(0% 0, 100% 0, 74% 100%, 0 100%);
  width: 50%;
  overflow: hidden;
  /* margin: 2%; */
  font-size: 0.9rem;
  font-weight: bold;
}

.SliderMain {
  margin-top: 3%;
  height: 40vh;
  background-color: #f2f2f2;
}
.sliderheader {
  padding-top: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  justify-content: center;
}
.sliderdisplay {
  width: 134% !important;
  height: 25vh;
}
.Workmain {
  display: flex;
  flex-direction: column;
  height: 60vh;
}
.workwrap {
  flex-direction: row;
  display: flex;
  margin-left: 30px;
  margin-right: 30px;
}
.animationcontainer {
  height: 20vh;
}

/* =================mobile screen ==================== */
@media only screen and (max-width: 768px) {
  .landingBody {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    /* overflow-x: hidden; */

    /* background-color: aquamarine; */
    /* background-color: aqua; */
  }
  .topsection {
    display: flex;
    flex-direction: row;
    /* margin: 1%; */
    height: 400px;
  }
  .topsectionright {
    display: flex;
    flex-direction: row;
    width: 125%;
    /* background-color: red; */
  }
  .toprightimage {
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    object-fit: contain;
    border-bottom-right-radius: 7rem;
    width: 100%;
    box-shadow: 2px 2px 14px -6px rgb(0 0 0 / 50%);
    -webkit-box-shadow: 2px 2px 14px -6px rgb(0 0 0 / 50%);
    -moz-box-shadow: 2px 2px 14px -6px rgba(0, 0, 0, 0.5);
  }
  .circleshape {
    border-radius: 100%;
    right: -56px;
    top: -28px;
    position: absolute;
    height: 200px;
    width: 200px;
    border: 30px solid #ccc;
    opacity: 0.2;
  }

  .circleshape2 {
    border-radius: 100%;
    right: 141px;
    top: 150px;
    position: absolute;
    height: 80px;
    width: 80px;
    border: 50px solid #ccc;
    opacity: 0.2;
  }
  .abovetext {
    width: 100%;
    /* background-color: aqua; */
    /* left: 63px; */
    /* top: 127px; */
    /* position: absolute; */
    z-index: 10;
    height: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .fixedimg {
    background: red;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -10;
  }
  .toptext {
    color: black;
    font-size: 1.5rem;
  }
  .companylogo {
    object-fit: contain;
    width: 130px;
  }
  .topmiddleimage {
    border: 10px solid #f2f2f2;
    margin-left: 30%;
    margin-top: 20%;
    overflow: hidden;
    object-fit: contain;
    width: 200px;
    height: 200px;
  }
  .regbutton {
    color: black;
    border-radius: 0.7rem;
    display: flex;
    justify-content: center;
    background-color: #f2f2f2;
    align-items: center;
    width: 150px;
    height: 50px;
  }
  .regbutton:hover {
    color: black;
    border-radius: 0.7rem;
    display: flex;
    justify-content: center;
    background-color: #f2f2f2;
    align-items: center;
    width: 150px;
    height: 50px;
  }
  .framecar {
    top: 106px;
    left: 133px;
    position: absolute;
    z-index: 20;
    object-fit: contain;
    width: 250px;
  }
  .framecar2 {
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg);
    top: 34px;
    left: 302px;
    position: absolute;
    z-index: 15;
    object-fit: contain;
    width: 180px;
    opacity: 0.7;
  }
  .carwraper {
    position: relative;
    width: 100%;
  }
  .searchwrapper {
    display: flex;
    margin-top: 1%;
    /* background-color: aqua; */
    flex-direction: column;
    justify-content: "space-between";
    height: 20% !important;

    width: 100%;
  }
  .searchleft {
    -webkit-clip-path: polygon(0% 0, 100% 0, 75% 120%, 0 100%);
    clip-path: polygon(0% 0, 100% 0, 75% 120%, 0 100%);
    display: flex;
    height: 45%;
    width: 100%;
    flex-direction: row;
    background-color: transparent;
    /* background-color: green !important; */
  }
  .searchright {
    margin-top: 5%;
    /* margin: 0% auto; */
    flex-direction: row;
    /* -webkit-clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%); */
    -webkit-clip-path: polygon(0% 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0% 0, 100% 0, 100% 100%, 0 100%);
    height: 30%;
    width: 100%;
  }
  .searchcol {
    justify-content: space-between;
    /* margin: 1%; */
    flex-direction: column;
    display: flex;
    width: 27%;
    height: 45%;
  }
  .containerbox {
    border: 5px #ccc solid;
    width: 100%;
    height: 100%;
    background-color: whitesmoke !important;
  }
  .searchbutton {
    /* padding: 2%; */
    border: 5px solid #ccc;
    height: 100%;
    width: 80%;
    background-color: aliceblue;
    display: flex;
    /* align-self: center; */
    justify-content: center;
    align-items: center;
  }
  .searchtext {
    margin: 2px;
    color: whitesmoke;
    font-size: 0.9rem;
    font-weight: 500;
  }

  /* =================services card csss================== */
  .Servicescontainer {
    object-fit: contain;
    padding: 1%;
    height: 36%;
    /* overflow: hidden; */
    margin-top: 2%;
    flex-direction: column;
    justify-content: space-around;
    display: flex;
    align-items: center;
    /* background-color: grey; */
  }
  .cardstyle {
    /* margin: 1%; */
    border-radius: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: whitesmoke;
    width: 79%;
    height: 31%;
  }
  .cardimage {
    float: right;
    object-fit: contain;
    width: 120px;
  }

  /* ===============slider ======================cssss  */
  .SliderMain {
    margin-top: 5%;
    height: 45vh;
    background-color: #f2f2f2;
  }
  .sliderdisplay {
    width: 134% !important;
    height: 25vh;
  }
  .sliderimage {
    border-radius: 0% 10% 10% 0%;
    background-color: grey;
    object-fit: fill;
    height: 100%;
    width: 80%;
    -webkit-clip-path: polygon(18% 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(18% 0, 100% 0, 100% 100%, 0 100%);
  }
  .sliderwrap {
    border-radius: 10%;
    height: 25vh;
    width: 70%;
    background-color: white;
  }
  .slidertextwrap {
    flex-direction: column;
    display: flex;
    padding: 5%;
    border-radius: 10% 0% 0% 10%;
    background-color: grey;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
    clip-path: polygon(0% 0, 100% 0, 74% 100%, 0 100%);
    width: 50%;
    overflow: hidden;
    /* margin: 2%; */
    font-size: 0.9rem;
    font-weight: bold;
  }

  .sliderheader {
    padding-top: 20px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    justify-content: center;
  }
  /* ====================how its work csss =================== */
  .workcontainer {
    flex-direction: column;
    display: flex;
    width: 100%;
    height: 80vh;
  }
  .workwrap {
    flex-direction: column;
    display: flex;
    margin-left: 30px;
    margin-right: 30px;
  }
  .animationcontainer {
    height: 30vh;
  }
}

.landingSection_main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem 0;
  /* margin: 1rem 0; */
}
.landingSection_body_wrapper {
  width: 100%;
  padding: 2rem;
  margin: 1rem 0;
}
.landingSection_header_title {
  font-size: 1.9rem;
  font-weight: bold;
  margin: 1rem 0;
  text-align: center;
}
.landingSection_header_description {
  font-size: 1.3rem;
  margin: 1rem 0;
  text-align: center;

}
@media only screen and (max-width: 991px) {
  .landingSection_body_wrapper {
    width: 85%;
  }
}
@media only screen and (max-width: 680px) {
  .landingSection_body_wrapper {
    width: 100%;
  }

  .landingSection_header_title {
    font-size: 1.6rem;
  }
  .landingSection_header_description {
    font-size: 1.1rem;
  }
}

.coutomer_wrap{
    height: 60vh;
}
@media only screen and (min-width: 900px) and (max-width: 992px) {
  .slick-list{
    height: 10rem !important;
  }
}



@media only screen and (min-width: 575px) and (max-width: 769px) {

.card_wrap{
  width: 95% !important;
}

}


@media only screen and (min-width: 768px) and (max-width: 810px) {

.card_wrap_main{
  width: 80% !important;
  margin: 0 auto !important;
}
  



}


@media only screen and (min-width: 810px) and (max-width: 899px) {

  .card_wrap_main{
    width: 75% !important;
    margin: 0 auto !important;
  }
    
  }
  







.car_img{
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}
.card_wrap{
  margin-top: 5px;
    width: 35rem;
    /* border: 1px solid red; */
    border-bottom-right-radius: 4rem;
    /* border-radius: 10px; */
    box-shadow: rgba(65, 48, 48, 0.02) 0px 8px 16px 0px, rgb(0 0 0 / 8%) 0px 2px 6px 0px;

}

.text_wrap_{

}

.slick-initialized .slick-slide {
  width: 450px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;

}




.customer_name{
    font-weight: bold;
    font-size: 1.2rem;
}

.card_wrap_main{
    /* border: 1px solid blue; */
    height: 25rem;
 /* display: flex;
 justify-content: space-evenly; */
    margin-bottom: 5rem;
}

.card_wrap_main_mobile {
    height: 25rem;
    margin-bottom: 5rem;
}





@media only screen and (max-width: 549px) and (min-width: 0px) {

  .slick-initialized .slick-slide{
    width: 97% !important;
    /* border: 1px solid  red; */

  }
    
  .card_pic_clas_m{
    height: 55% !important;
    width: 95% !important;
  }
.w___{
  width: 98% !important;
}
  }

  @media only screen and (max-width: 899px) and (min-width: 0px) {
    .card_wrap_main_mobile {
      display: none;
    }
    
    
  }

@media only screen and (max-width: 1920px) and (min-width: 900px) {
    .card_wrap_main {
      display: none;
    }
   
    
  }

.m_pic_wrap{
  display: flex;
  align-items: center;
  justify-content: right;
}
.card_pic_clas_m{
  height: 100%;


  background-size: 100% 100%;
background-size: contain;
background-size: cover;
}
.card_pic_clas{
 height: 100%;


    background-size: 100% 100%;
background-size: contain;
background-size: cover;
}
.work_text{
    font-weight: bolder;
    font-size: 1.2rem;
}

.how_pic{
    width: 20rem;
    height: 17rem;
}


@media only screen and (max-width: 899px) and (min-width: 600px) {
    .how_wrap {
    margin-top: 14rem;
    }
    
    
  }

@media only screen and (max-width: 600px) and (min-width: 500px) {
    .how_wrap {
    margin-top: 10rem;
    }
    
    
  }

  @media only screen and (max-width: 499px) and (min-width: 400px) {
    .how_wrap {
    margin-top: 3rem;
    }
    
    
  }

  


  @media only screen and (max-width: 400px) and (min-width: 380px) {
    .how_wrap {
    margin-top: 8rem;
    }
    
    
  }


  @media only screen and (max-width: 380px) and (min-width: 0px) {
    .how_wrap {
    margin-top: 16rem;
    }
    
    
  }

  @media only screen and (max-width: 1400px) and (min-width: 900px) {
    .how_wrap {
    /* margin-top: 5%; */
    }
    
    
  }

  /* .img_4{
      width: 210px;
  } */
  /* .img_2{
      width: 210px;
  } */
.heading {
text-align: start;
}
.marginLeft{
  margin-left: 2.3rem;
}
.dropdownItems {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.tabstyle {
  background-color: #ccc;
}
.tabIcons {
  font-size: 1.2rem;
  font-weight: bold;
}
.barstyle {
  margin-top: 1%;
}
.insidetab {
  flex-direction: column;
  display: flex;
  margin-top: 1%;
  /* background-color: aqua; */
}
.outertab {
  margin: 1%;
  width: 100%;
}
.listcontainer {
  width: 100%;
  /* justify-content: center;
   flex-direction: row;
   display: flex; */
}
.liststyle {
  margin-top: 2%;
  border-bottom: 1.5px solid #ccc;
  background-color: whitesmoke;
}
.viewtext {
  font-size: 1rem;
}
.ui.menu > .item {
  background-color: #ccc !important;
}
.ui.menu > .item:not(.active) {
  background-color: whitesmoke !important;
}
.ui.pointing.menu .active.item:after {
  background-color: #ccc !important;
}
.textbox {
  justify-content: space-between;
  display: flex;
  align-items: center;
  height: 30px;
  margin: 2%;
  width: 20%;
}
.tabtext {
  font-size: 100%;
  font-weight: bold;
}
@media only screen and (max-width: 600px) {
  .insidetab {
    flex-direction: column;
    display: flex;
    margin-top: 1%;
    /* background-color: aqua; */
  }
  .outertab {
    margin: 1%;
    width: 100%;
  }
  .listcontainer {
    width: 100%;
    /* justify-content: center;
     flex-direction: row;
     display: flex; */
  }
  .logo {
    display: flex;
    justify-content: space-between;
  }

  .viewtext {
    font-size: 0.8rem;
  }
  .ui.menu > .item {
    background-color: #ccc !important;
  }
  .ui.menu > .item:not(.active) {
    background-color: whitesmoke !important;
  }
  .ui.pointing.menu .active.item:after {
    background-color: #ccc !important;
  }
  .textbox {
    justify-content: space-between;
    display: flex;
    align-items: center;
    height: 10%;
    margin: 2%;
    width: 100%;
    background-color: #ccc;
  }
  .tabtext {
    font-size: 59%;
    font-weight: w900;
  }
}
@media (min-width: 769px) and (max-width: 1200px) {
  .insidetab {
    flex-direction: column;
    display: flex;
    margin-top: 1%;
    /* background-color: aqua; */
  }
  .outertab {
    margin: 1%;
    width: 100%;
  }
  .listcontainer {
    width: 100%;
    /* justify-content: center;
     flex-direction: row;
     display: flex; */
  }
  .logo {
    display: flex;
    justify-content: space-between;
  }
  .dropdownItems {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .viewtext {
    font-size: 0.8rem;
  }
  .ui.menu > .item {
    background-color: #ccc !important;
  }
  .ui.menu > .item:not(.active) {
    background-color: whitesmoke !important;
  }
  .ui.pointing.menu .active.item:after {
    background-color: #ccc !important;
  }
  .textbox {
    justify-content: space-between;
    display: flex;
    align-items: center;
    height: 10%;
    margin: 2%;
    width: 100%;
    /* background-color: #ccc; */
  }
  .tabtext {
    font-size: 63%;
    font-weight: w900;
  }
}

.counter_main_container {
  background: var(--info);
  width: 80%;
  padding: 2rem;
  padding-bottom: 1.5rem;
  border-radius: 5px;
  position: relative;
}
.slider{
  position: relative;
  width: 100%;
  height:   45% !important;
  overflow: hidden;
}
.counter_text {
  font-size: 2rem;
  font-weight: 700;
  color: var(--secondary);
  /* margin: 0.2vw; */
}

.counter_buy_now_text {
  font-size: 0.9rem;
  font-weight: 650;
  color: var(--white);
  text-transform: uppercase;
}

.counter_place_bid_cont_text {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--white);
  text-transform: capitalize;
  margin: 5px;
}

.counter_time_title {
  text-transform: uppercase;
  font-size: 12px;
}

.viewtitle_underline {
  background: var(--secondary);
  width: 30%;
  padding: 2.5px;
  margin: 8px 0px;
}

.counter_total_bidder_cont {
  position: absolute;
  top: 0;
  left: 0;
}

.counter_buy_now_cont {
  background: var(--primary);
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  padding: 0.2rem;
  border-radius: 0.2rem;
}

.counter_place_bid_cont {
  background: var(--secondary);
  border-radius: 0.5rem;
  padding: 10px;
}
.counter_buy_now_cont_ {
  background: var(--white);
  border: 1.5px solid var(--primaryDark);
  border-radius: 0.5rem;
  transition: 0.3s ease-out;
  cursor: pointer;
}
img {
    max-width: unset !important;
}
.counter_buy_now_cont_:hover {
  /* background: #fae9e7; */
  box-shadow: -2px 2px 12px -3px rgba(136, 39, 29, 0.8);
  -webkit-box-shadow: -2px 2px 12px -3px rgba(136, 39, 29, 0.8);
  -moz-box-shadow: -2px 2px 12px -3px rgba(136, 39, 29, 0.8);
}

.place_bid_input_cont {
  position: relative;
  z-index: 0;
  display: flex;
  flex: 1 1;
  width: 100%;
  /* background: red; */
  border-radius: 5px;
  border: 1px solid var(--secondary);
  /* padding: 0 0.2vw; */
  overflow: hidden;
  margin: 1vw 0px;
  box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.75);
}

.place_bid_input_cont > input {
  display: flex;
  /* flex: 1; */
  width: 95%;
  border: none;
  outline: none;
  background: none;
  margin: 0 0.5vw;
  /* font-family: var(--font); */
}

.place_bid_input_cont > input:focus {
  outline: none;
}

.data_row_item {
  box-shadow: -3px 3px 9px -4px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: -3px 3px 9px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -3px 3px 9px -4px rgba(0, 0, 0, 0.5);
  border-radius: 0.2rem;
  padding: 0.4rem 1vw;
  margin: 0.4rem 1vw;
}

.data_row_item_icon {
  color: var(--primary);
  font-size: 1.2rem;
  margin: 0.2rem;
  /* padding: 0.2rem  ; */
  text-transform: capitalize;
  text-align: center;
}

.data_row_item_title {
  font-size: 1rem;
  color: var(--secondary);
}

.data_row_item_body {
  color: var(--secondary);
  font-weight: 600;
}
.bid_animating_car_cont {
  /* width: 100%; */
  overflow: hidden;
}
.bid_animating_car {
  z-index: 1;
  width: 40px;
  position: absolute;
  left: -50px;
  -webkit-animation: bid_animating_car_animation 3s;
          animation: bid_animating_car_animation 3s;
}
@-webkit-keyframes bid_animating_car_animation {
  0% {
    left: -50px;
  }
  100% {
    left: calc(100% + 50px);
  }
}
@keyframes bid_animating_car_animation {
  0% {
    left: -50px;
  }
  100% {
    left: calc(100% + 50px);
  }
}
.ImageGallery_originalClass {
  width: 20px;
}
/* ------------------------------------------------ */

.productFeaturesList {
  flex-wrap: wrap;
  width: 90%;
  border: 1px solid #c3c3c3;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.productFeaturesList > span {
  /* background-color: yellow; */
  margin: 2px 5px;
  text-transform: capitalize;
}

.incrementDecrementBtn {
  padding: 0.09rem 0.35rem;
  /* background: #f5f5f5; */
  /* border-bottom: 0.5px solid #c9cbd8; */
  cursor: pointer;
  transition: 0.15s ease-in-out;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.incrementDecrementBtn:hover {
  background: var(--infoPlus);
  /* border-bottom: 0.5px solid #73737c; */
}

.ViewMain {
  padding: 1%;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20%;
}

.leftview {
  display: flex;
  flex-direction: column;
  max-width: 50vw;
}

.rightview {
  /* margin-left: 1%; */
  align-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tabstyle {
  border-bottom: 1.5px solid #ccc;
  /* background-color: beige; */
}

.liststyle {
  border-bottom: 1.5px solid #ccc;
  background-color: whitesmoke;
}

.Bidingtitle {
  margin-top: 2%;
  font-size: 0.9rem;
  font-weight: 600;
}

.cartitle {
  width: 100%;
  /* background-color: antiquewhite; */
}

.cardetail {
  border-radius: 10px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  width: 100%;
  /* background-color: red; */
  align-items: center;
}

.cardetailmain {
  width: 100%;
  /* background: red; */
  padding: 1rem;
  /* background-color: whitesmoke;
  border-radius: 2rem;
  padding: 5%;
  margin: 2%; */
}

.viewtitle {
  font-size: 2.5rem;
  font-weight: 800;
  color: var(--secondary);
  line-height: 2.5rem;
}

.viewtext1 {
  font-size: 1rem;
  font-weight: 500;
  margin: 0px;
}

.bidingbar {
  border-bottom: 1.5px solid #ccc;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1%;
  padding: 1%;
}

.btnbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.bidingbar2 {
  border-bottom: 1.5px solid #ccc;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40%;
  margin-top: 1%;
  padding: 2%;
}

.bidingbartext {
  color: black;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

.bidingbutton {
  border-radius: 5px;
  /* padding: 2%; */
  margin: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 25%;
  background-color: grey;
  color: white;
}

.sellertitle {
  border-bottom: 1px solid #ccc;
}

.timermain {
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: space-around;
  align-self: center;
  margin-bottom: 5%;
  margin-top: 2%;
}

.timerMobile {
  display: none;
}

/* ================clock timer============ */

#clockdiv {
  font-family: sans-serif;
  color: #fff;
  display: flex;
  font-weight: 100;
  text-align: center;
  font-size: 40px;
  width: 100%;
}

#clockdiv > div {
  padding: 15px;
  border-radius: 10px;
  background: whitesmoke;
  display: inline-block;
  margin: 3%;
  width: 80%;
  height: 120px;
}

#clockdiv div > span {
  padding: 20px;
  border-radius: 5px;
  background: grey;
  display: inline-block;
}

.smalltext {
  padding-top: 10px;
  font-size: 16px;
  color: black;
}

.mobiletabbar {
  display: none;
}

.mobileTabBar {
  display: none;
}

@media only screen and (max-width: 991px) {
  .desktopbar {
    display: none;
  }
  .mobileTabBar {
    display: inline-block;
    width: 100%;
    overflow-x: scroll;
  }
}

@media only screen and (max-width: 479px) {
  .counter_text {
    font-size: 1.5rem;
    font-weight: 650;
  }
  .counter_time_title {
    font-size: 8px;
  }
  .counter_place_bid_cont_text {
    font-size: 0.9rem;
    font-weight: 580;
   }
  .counter_buy_now_text {
    font-size: 0.8rem;
    font-weight: 620;
  }
  .viewtitle {
    font-size: 2rem;
    font-weight: 760;
    line-height: 2rem;
  }
}



.main_wrap_onboth{
  padding-top: 5px;
  margin-top: 2%;
  height: 72%;
  /* overflow: auto; */

}





.car_num_clr{
    background-color: var(--yellow);
    color: black;
    font-weight: 700;
    font-size: 1rem;
    /* border-radius: 4px; */
    /* padding: 2px 20px; */
    display: flex;
    /* text-align: center; */
    justify-content: center;
    align-items: center;
    width: 14rem;
}
.img_d{
  width: 2rem;
  height: 3rem;
}

.bot_wrap{
border: 2px solid;
    border-radius: 4px
}
.car_info_wrap{
  text-align: center;
}
.model_img{
  /* width: 50px; */
  height: 6rem;
  width: 8rem;
  /* border-radius: 50%; */
  background-size: 100% 100% ;
}

.text-area{
    box-sizing: border-box;
    width: 100%;
    /* text-align: center; */
    margin: auto;
    display: flex;
    border-color: gainsboro;
}


.text_cap.dropdown_wrapper.ant-input{
  text-transform: capitalize !important;
  
}



.evaluation_background_gradient_wrapper {
  height: calc(100vh / 1.7);
  width: 100%;
}
.evaluation_background_gradient {
  /* background-image: linear-gradient(
    to bottom right,
    var(--primaryDark),
    var(--primary),
    var(--infprimaryLighto)
    ); */
  height: calc(100vh / 1.7);
  width: 100%;
  background-color: var(--primary);
  /* background-image: linear-gradient(
    to left top,
    #c33b2a,
    #b83525,
    #ae3021,
    #a32a1c,
    #992518
  ); */
  /* background-image: linear-gradient(to bottom right, #88271d, #c33b2a, #88271d); */
  position: absolute;
  top: 0;
}
.numberIcon {
  background: var(--infoPlus);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary);
  font-weight: bold;
  margin: 0 0.2rem 0 0;
}
.evaluation_section_1 {
  height: calc(100vh / 1.7);
  width: 100%;
}
.elevation_s1_lc {
  width: 93%;
}
.elevation_s1_lc_cont_2_r {
  display: none;
}
.breadcrumb_icon {
  width: 20px;
  height: 20px;
  font-size: 15px;
}
.elevation_s1_lc_cont_1_price {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  font-weight: 600;
  color: var(--secondary);
  line-height: 2.1rem;
  justify-content: center;
}
.elevation_s1_lc_cont_1_price > span:nth-child(1) {
  font-size: 2.3rem;
  display: block;
}
.elevation_s1_lc_cont_1_price > span:nth-child(2) {
  font-size: 2.5rem;
  display: block;
}
.elevation_s1_lc_cont_1_t_1 {
  font-size: 1.3rem;
}
.elevation_s1_lc_cont_1_t_2 {
  font-size: 1.4rem;
  font-weight: 500;
}
.evaluation_s2_lc_heading {
  width: 85%;
  padding-top: 2rem;
  margin-top: 2rem;
}
.evaluation_s2_heading {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
/* order list */
.evaluation_s2_orderlist {
  list-style: none;
  counter-reset: list-count;
  display: flex;
  text-align: justify !important;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.evaluation_s2_orderlist > li {
  counter-increment: list-count;
  font-size: 1.4rem;
  margin-top: 0.8rem;
}
.evaluation_s2_orderlist > li::before {
  content: counter(list-count) ".";
  font-weight: bold;
  font-size: 1.4rem;
  margin-right: 1rem;
  color: var(--primary);
}
.evaluation_footer {
  border-top: 1px solid var(--secondary);
  margin-top: 2rem;
}
/* ================================= */
@media only screen and (max-width: 610px) {
  .elevation_s1_lc_cont_1_price > span:nth-child(1) {
    font-size: 1rem;
    display: block;
  }
  .elevation_s1_lc_cont_1_price > span:nth-child(2) {
    font-size: 2.3rem;
    display: block;
  }
  /*  */
  .evaluation_s2_heading {
    font-size: 1.5rem;
    font-weight: 700;
  }
  .evaluation_s2_orderlist > li {
    font-size: 1.35rem;
    margin-top: 0.75rem;
  }
  .evaluation_s2_orderlist > li::before {
    font-size: 1.35rem;
    margin-right: 0.9rem;
  }
}
@media only screen and (max-width: 540px) {
 
 .step_num{
  font-size: .7rem !important;
 }
 
  .elevation_s1_lc_cont_1_price > span:nth-child(1) {
    font-size: 1rem;
    display: block;
  }
  .elevation_s1_lc_cont_1_price > span:nth-child(2) {
    font-size: 2.1rem;
    display: block;
  }
  .breadcrumb_icon {
    width: 15px;
    height: 15px;
    font-size: 10px;
  }

  .elevation_s1_lc_cont_1_price{
    line-height: normal !important;
  }
  /*  */
  .evaluation_s2_heading {
    font-size: 1.4rem;
    font-weight: 600;
  }
  .evaluation_s2_orderlist > li {
    font-size: 1.2rem;
    margin-top: 0.6rem;
  }
  .evaluation_s2_orderlist > li::before {
    font-size: 1.2rem;
    margin-right: 0.8rem;
  }
}
@media only screen and (max-width: 991px) {
  .evaluation_section_1 {
    height: auto;
  }
  .evaluation_background_gradient_wrapper {
    height: calc(100vh / 2.7);
  }
  .evaluation_background_gradient {
    height: calc(100vh / 2.7);
  }
  /* .elevation_s1_lc_cont_2 {
    display: none;
  } */
  .elevation_s1_lc_cont_2_r {
    display: inline-block;
  }
  .evaluation_s2_heading {
    margin-bottom: 1rem;
  }
  .evaluation_s2_lc_heading {
    width: 95%;
    padding-top: 1rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}

.img1 {
  width: 7rem;
  height: 7rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
}

.picdiv {
  display: flex;
  flex-wrap: wrap;

  /* border: 1px solid black; */
  width: 100%;
}

.imgpiker {
  position: relative;
  /* border: 1px solid blue; */
  height: 7rem;
  width: 7rem;
  /* padding: 0.8rem; */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  margin: 0.5rem;
  overflow: hidden;
  box-shadow: -2px 2px 6px -1px rgba(69, 68, 68, 0.75);
  -webkit-box-shadow: -2px 2px 6px -1px rgba(69, 68, 68, 0.75);
  -moz-box-shadow: -2px 2px 6px -1px rgba(69, 68, 68, 0.75);
}

.greating_section {
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  margin: 1rem 0;
}
.greating_main_cont {
  min-height: calc(100vh / 1.7);
  width: 100%;
}
.greating_cont_heading {
  font-size: 4rem;
  font-weight: 900;
  line-height: 1;
  color: var(--white);
  text-transform: capitalize;
  text-align: center;
}
.token_cont_item {
  font-size: 3rem;
  font-weight: 900;
  line-height: 1;
  color: var(--white);
  text-transform: capitalize;
  text-align: center;
}
.greating_cont_desc {
  margin: 1rem 0;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.3;
  text-align: center;
  width: 60%;
  color: var(--white);
  /* text-transform: capitalize; */
}
.greating_section_2 {
  background: var(--info);
  display: flex;
  flex-wrap: wrap;
}
.greating_section_2_card {
  margin: 0.5rem;
  padding: 1rem;
  display: block;
  border-radius: 0.5rem;
  width: 16rem;
}
.token_cont {
  background-color: var(--info);
  /* height: 4.7rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.2rem;
  margin: 1.5rem;
  overflow: hidden;
}

.token_text {
  margin: .75rem;
  color: var(--secondary);
  flex: 1 1;
}
.token_button {
  background: var(--primary);
}
/*  */
@media only screen and (max-width: 1135px) {
  .greating_section {
    width: 80%;
  }
  .greating_cont_heading {
    font-size: 3.5rem;
  }
  .token_cont_item {
    font-size: 2.8rem;
  }
}
/*  */
@media only screen and (max-width: 991px) {
  .greating_main_cont {
    min-height: calc(100vh / 2.7);
  }
  .greating_section {
    width: 95%;
  }
  .greating_cont_heading {
    font-size: 3rem;
  }
  .token_cont_item {
    font-size: 2.8rem;
  }
  .greating_cont_desc {
    width: 85%;
  }
}
/*  */
@media only screen and (max-width: 685px) {
  .greating_section {
    width: 100%;
  }
  .greating_cont_heading {
    font-size: 2.4rem;
  }
  .token_cont_item {
    font-size: 1.8rem;
  }
  .greating_cont_desc {
    width: 90%;
    line-height: 1.1;
  }
}
/*  */
@media only screen and (max-width: 545px) {
  .greating_section {
    padding: 0px;
  }
  .greating_cont_heading {
    font-size: 1.9rem;
  }
  .token_cont_item {
    font-size: 1.2rem;
  }
  .greating_cont_desc {
    width: 95%;
    line-height: 1.2;
    font-size: 1.1rem;
  }
  .token_cont {
    margin: 1rem;
  }
}
@media print {
  .token_cont {
    display: none;
  }
  .greating_cont_heading {
    color: var(--secondary);
  }
  .greating_cont_desc {
    color: var(--secondary);
    width: 85%;
  }
  .hide_in_print {
    display: none;
  }
}

.evaluation_background_gradient_wrapper {
  height: calc(100vh / 1.7);
  width: 100%;
}
.evaluation_background_gradient {
  /* background-image: linear-gradient(
    to bottom right,
    var(--primaryDark),
    var(--primary),
    var(--infprimaryLighto)
    ); */
  height: calc(100vh / 1.7);
  width: 100%;
  background-color: var(--primary);
  /* background-image: linear-gradient(
    to left top,
    #c33b2a,
    #b83525,
    #ae3021,
    #a32a1c,
    #992518
  ); */
  /* background-image: linear-gradient(to bottom right, #88271d, #c33b2a, #88271d); */
  position: absolute;
  top: 0;
}
.numberIcon {
  background: var(--infoPlus);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary);
  font-weight: bold;
  margin: 0 0.2rem 0 0;
}
.evaluation_section_1 {
  height: calc(100vh / 1.7);
  width: 100%;
}
.elevation_s1_lc {
  width: 93%;
}
.elevation_s1_lc_cont_2_r {
  display: none;
}
.breadcrumb_icon {
  width: 20px;
  height: 20px;
  font-size: 15px;
}
.elevation_s1_lc_cont_1_price {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  font-weight: 800;
  color: var(--secondary);
  line-height: 2.5rem;
  justify-content: center;
}
.elevation_s1_lc_cont_1_price > span:nth-child(1) {
  font-size: 2.3rem;
  display: block;
}
.elevation_s1_lc_cont_1_price > span:nth-child(2) {
  font-size: 2.6rem;
  display: block;
}
.elevation_s1_lc_cont_1_t_1 {
  font-size: 1.3rem;
}
.elevation_s1_lc_cont_1_t_2 {
  font-size: 1.4rem;
  font-weight: 500;
}
.evaluation_s2_lc_heading {
  width: 85%;
  padding-top: 2rem;
  margin-top: 2rem;
}
.evaluation_s2_heading {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
/* order list */
.evaluation_s2_orderlist {
  list-style: none;
  counter-reset: list-count;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.evaluation_s2_orderlist > li {
  counter-increment: list-count;
  font-size: 1.4rem;
  margin-top: 0.8rem;
}
.evaluation_s2_orderlist > li::before {
  content: counter(list-count) ".";
  font-weight: bold;
  font-size: 1.4rem;
  margin-right: 1rem;
  color: var(--primary);
}
.evaluation_footer {
  border-top: 1px solid var(--secondary);
  margin-top: 2rem;
}
/* ================================= */
@media only screen and (max-width: 610px) {
  .elevation_s1_lc_cont_1_price > span:nth-child(1) {
    font-size: 2rem;
    display: block;
  }
  .elevation_s1_lc_cont_1_price > span:nth-child(2) {
    font-size: 2.3rem;
    display: block;
  }
  /*  */
  .evaluation_s2_heading {
    font-size: 1.5rem;
    font-weight: 700;
  }
  .evaluation_s2_orderlist > li {
    font-size: 1.35rem;
    margin-top: 0.75rem;
  }
  .evaluation_s2_orderlist > li::before {
    font-size: 1.35rem;
    margin-right: 0.9rem;
  }
}
@media only screen and (max-width: 540px) {
  .elevation_s1_lc_cont_1_price > span:nth-child(1) {
    font-size: 1rem;
    display: block;
  }

  .elevation_s1_lc_cont_1_t_1 {
    font-size: .8rem;
  }
  .elevation_s1_lc_cont_1_price > span:nth-child(2) {
    font-size: 2.1rem;
    display: block;
  }
  .breadcrumb_icon {
    width: 15px;
    height: 15px;
    font-size: 10px;
  }
  /*  */
  .evaluation_s2_heading {
    font-size: 1.4rem;
    font-weight: 600;
  }
  .evaluation_s2_orderlist > li {
    font-size: 1.2rem;
    margin-top: 0.6rem;
  }
  .evaluation_s2_orderlist > li::before {
    font-size: 1.2rem;
    margin-right: 0.8rem;
  }
}
@media only screen and (max-width: 991px) {
  .evaluation_section_1 {
    height: auto;
  }
  .evaluation_background_gradient_wrapper {
    height: calc(100vh / 2.7);
  }
  .evaluation_background_gradient {
    height: calc(100vh / 2.7);
  }
  /* .elevation_s1_lc_cont_2 {
    display: none;
  } */
  .elevation_s1_lc_cont_2_r {
    display: inline-block;
  }
  .evaluation_s2_heading {
    margin-bottom: 1rem;
  }
  .evaluation_s2_lc_heading {
    width: 95%;
    padding-top: 1rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}

.img1 {
  width: 7rem;
  height: 7rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
}

.picdiv {
  display: flex;
  flex-wrap: wrap;

  /* border: 1px solid black; */
  width: 100%;
}

.imgpiker {
  position: relative;
  /* border: 1px solid blue; */
  height: 7rem;
  width: 7rem;
  /* padding: 0.8rem; */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  margin: 0.5rem;
  overflow: hidden;
  box-shadow: -2px 2px 6px -1px rgba(69, 68, 68, 0.75);
  -webkit-box-shadow: -2px 2px 6px -1px rgba(69, 68, 68, 0.75);
  -moz-box-shadow: -2px 2px 6px -1px rgba(69, 68, 68, 0.75);
}

.greating_section {
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  margin: 1rem 0;
}
.greating_main_cont {
  min-height: calc(100vh / 1.7);
  width: 100%;
}
.greating_cont_heading {
  font-size: 4rem;
  font-weight: 900;
  line-height: 1;
  color: var(--white);
  text-transform: capitalize;
  text-align: center;
}
.token_cont_item {
  font-size: 3rem;
  font-weight: 900;
  line-height: 1;
  color: var(--white);
  text-transform: capitalize;
  text-align: center;
}
.greating_cont_desc {
  margin: 1rem 0;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.3;
  text-align: center;
  width: 60%;
  color: var(--white);
  /* text-transform: capitalize; */
}
.greating_section_2 {
  background: var(--info);
  display: flex;
  flex-wrap: wrap;
}
.greating_section_2_card {
  margin: 0.5rem;
  padding: 1rem;
  display: block;
  border-radius: 0.5rem;
  width: 16rem;
}
.token_cont {
  background-color: var(--info);
  /* height: 4.7rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.2rem;
  margin: 1rem;
  overflow: hidden;
}

.token_text {
  margin: .75rem;
  color: var(--secondary);
  flex: 1 1;
}
.token_button {
  background: var(--primary);
}
/*  */
@media only screen and (max-width: 1135px) {
  .greating_section {
    width: 80%;
  }
  .greating_cont_heading {
    font-size: 3.5rem;
  }
  .token_cont_item {
    font-size: 2.8rem;
  }
}
/*  */
@media only screen and (max-width: 991px) {
  .greating_main_cont {
    min-height: calc(100vh / 2.7);
  }
  .greating_section {
    width: 95%;
  }
  .greating_cont_heading {
    font-size: 3rem;
  }
  .token_cont_item {
    font-size: 2.8rem;
  }
  .greating_cont_desc {
    width: 85%;
  }
}
/*  */
@media only screen and (max-width: 685px) {
  .greating_section {
    width: 100%;
  }
  .greating_cont_heading {
    font-size: 2.4rem;
  }
  .token_cont_item {
    font-size: 1.8rem;
  }
  .greating_cont_desc {
    width: 90%;
    line-height: 1.1;
  }
}
/*  */
@media only screen and (max-width: 545px) {
  .greating_section {
    padding: 0px;
  }
  .greating_cont_heading {
    font-size: 1.9rem;
  }
  .token_cont_item {
    font-size: 1.2rem;
  }
  .greating_cont_desc {
    width: 95%;
    line-height: 1.2;
    font-size: 1.1rem;
  }
  .token_cont {
    margin: 1rem;
  }
}
@media print {
  .token_cont {
    display: none;
  }
  .greating_cont_heading {
    color: var(--secondary);
  }
  .greating_cont_desc {
    color: var(--secondary);
    width: 85%;
  }
  .hide_in_print {
    display: none;
  }
}



.main_wrap_onboth{
  padding-top: 5px;
  margin-top: 2%;
  height: 72%;
  /* overflow: auto; */

}




.text_cap.dropdown_wrapper.ant-input{
  text-transform: capitalize !important;
  
}



.evaluation_background_gradient_wrapper {
  height: calc(100vh / 1.7);
  width: 100%;
}
.evaluation_background_gradient {
  /* background-image: linear-gradient(
    to bottom right,
    var(--primaryDark),
    var(--primary),
    var(--infprimaryLighto)
    ); */
  height: calc(100vh / 1.7);
  width: 100%;
  background-color: var(--primary);
  /* background-image: linear-gradient(
    to left top,
    #c33b2a,
    #b83525,
    #ae3021,
    #a32a1c,
    #992518
  ); */
  /* background-image: linear-gradient(to bottom right, #88271d, #c33b2a, #88271d); */
  position: absolute;
  top: 0;
}
.numberIcon {
  background: var(--infoPlus);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary);
  font-weight: bold;
  margin: 0 0.2rem 0 0;
}
.evaluation_section_1 {
  height: calc(100vh / 1.7);
  width: 100%;
}
.elevation_s1_lc {
  width: 93%;
}
.elevation_s1_lc_cont_2_r {
  display: none;
}
.breadcrumb_icon {
  width: 20px;
  height: 20px;
  font-size: 15px;
}
.elevation_s1_lc_cont_1_price {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  font-weight: 800;
  color: var(--secondary);
  line-height: 2.1rem;
  justify-content: center;
}
.elevation_s1_lc_cont_1_price > span:nth-child(1) {
  font-size: 2.3rem;
  display: block;
}
.elevation_s1_lc_cont_1_price > span:nth-child(2) {
  font-size: 2.6rem;
  display: block;
}
.elevation_s1_lc_cont_1_t_1 {
  font-size: 1.3rem;
}
.elevation_s1_lc_cont_1_t_2 {
  font-size: 1.4rem;
  font-weight: 500;
}
.evaluation_s2_lc_heading {
  width: 85%;
  padding-top: 2rem;
  margin-top: 2rem;
}
.evaluation_s2_heading {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
/* order list */
.evaluation_s2_orderlist {
  list-style: none;
  counter-reset: list-count;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.evaluation_s2_orderlist > li {
  counter-increment: list-count;
  font-size: 1.4rem;
  margin-top: 0.8rem;
}
.evaluation_s2_orderlist > li::before {
  content: counter(list-count) ".";
  font-weight: bold;
  font-size: 1.4rem;
  margin-right: 1rem;
  color: var(--primary);
}
.evaluation_footer {
  border-top: 1px solid var(--secondary);
  margin-top: 2rem;
}
/* ================================= */
@media only screen and (max-width: 610px) {
  .elevation_s1_lc_cont_1_price > span:nth-child(1) {
    font-size: 1rem;
    display: block;
  }
  .elevation_s1_lc_cont_1_price > span:nth-child(2) {
    font-size: 2.3rem;
    display: block;
  }
  /*  */
  .evaluation_s2_heading {
    font-size: 1.5rem;
    font-weight: 700;
  }
  .evaluation_s2_orderlist > li {
    font-size: 1.35rem;
    margin-top: 0.75rem;
  }
  .evaluation_s2_orderlist > li::before {
    font-size: 1.35rem;
    margin-right: 0.9rem;
  }
}
@media only screen and (max-width: 540px) {
 
 .step_num{
  font-size: .7rem !important;
 }
 
  .elevation_s1_lc_cont_1_price > span:nth-child(1) {
    font-size: 1rem;
    display: block;
  }
  .elevation_s1_lc_cont_1_price > span:nth-child(2) {
    font-size: 2.1rem;
    display: block;
  }
  .breadcrumb_icon {
    width: 15px;
    height: 15px;
    font-size: 10px;
  }

  .elevation_s1_lc_cont_1_price{
    line-height: normal !important;
  }
  /*  */
  .evaluation_s2_heading {
    font-size: 1.4rem;
    font-weight: 600;
  }
  .evaluation_s2_orderlist > li {
    font-size: 1.2rem;
    margin-top: 0.6rem;
  }
  .evaluation_s2_orderlist > li::before {
    font-size: 1.2rem;
    margin-right: 0.8rem;
  }
}
@media only screen and (max-width: 991px) {
  .evaluation_section_1 {
    height: auto;
  }
  .evaluation_background_gradient_wrapper {
    height: calc(100vh / 2.7);
  }
  .evaluation_background_gradient {
    height: calc(100vh / 2.7);
  }
  /* .elevation_s1_lc_cont_2 {
    display: none;
  } */
  .elevation_s1_lc_cont_2_r {
    display: inline-block;
  }
  .evaluation_s2_heading {
    margin-bottom: 1rem;
  }
  .evaluation_s2_lc_heading {
    width: 95%;
    padding-top: 1rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}

.img1 {
  width: 7rem;
  height: 7rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
}

.picdiv {
  display: flex;
  flex-wrap: wrap;

  /* border: 1px solid black; */
  width: 100%;
}

.imgpiker {
  position: relative;
  /* border: 1px solid blue; */
  height: 7rem;
  width: 7rem;
  /* padding: 0.8rem; */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  margin: 0.5rem;
  overflow: hidden;
  box-shadow: -2px 2px 6px -1px rgba(69, 68, 68, 0.75);
  -webkit-box-shadow: -2px 2px 6px -1px rgba(69, 68, 68, 0.75);
  -moz-box-shadow: -2px 2px 6px -1px rgba(69, 68, 68, 0.75);
}

.greating_section {
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  margin: 1rem 0;
}

.greating_main_cont {
  min-height: calc(100vh / 1.7);
  width: 100%;
}

.greating_cont_heading {
  font-size: 4rem;
  font-weight: 900;
  line-height: 1;
  color: var(--white);
  text-transform: capitalize;
  text-align: center;
}

.token_cont_item {
  font-size: 3rem;
  font-weight: 900;
  line-height: 1;
  color: var(--white);
  text-transform: capitalize;
  text-align: center;
}

.greating_cont_desc {
  margin: 1rem 0;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.3;
  text-align: center;
  width: 60%;
  color: var(--white);
  /* text-transform: capitalize; */
}

.greating_section_2 {
  background: var(--info);
  display: flex;
  flex-wrap: wrap;
}

.greating_section_2_card {
  margin: 0.5rem;
  padding: 1rem;
  display: block;
  border-radius: 0.5rem;
  width: 16rem;
}

.token_cont {
  background-color: var(--info);
  /* height: 4.7rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.2rem;
  margin: 1rem;
  overflow: hidden;
}

.token_text {
  margin: .75rem;
  color: var(--secondary);
  flex: 1 1;
}

.token_button {
  background: var(--primary);
}

/*  */
@media only screen and (max-width: 1135px) {
  .greating_section {
    width: 80%;
  }

  .greating_cont_heading {
    font-size: 3.5rem;
  }

  .token_cont_item {
    font-size: 2.8rem;
  }
}

/*  */
@media only screen and (max-width: 991px) {
  .greating_main_cont {
    min-height: calc(100vh / 2.7);
  }

  .greating_section {
    width: 95%;
  }

  .greating_cont_heading {
    font-size: 3rem;
  }

  .token_cont_item {
    font-size: 2.8rem;
  }

  .greating_cont_desc {
    width: 85%;
  }
}

/*  */
@media only screen and (max-width: 685px) {
  .greating_section {
    width: 100%;
  }

  .greating_cont_heading {
    font-size: 2.4rem;
  }

  .token_cont_item {
    font-size: 1.8rem;
  }

  .greating_cont_desc {
    width: 90%;
    line-height: 1.1;
  }
}

/*  */
@media only screen and (max-width: 545px) {
  .greating_section {
    padding: 0px;
  }

  .greating_cont_heading {
    font-size: 1.9rem;
  }

  .token_cont_item {
    font-size: 1.2rem;
  }

  .greating_cont_desc {
    width: 95%;
    line-height: 1.2;
    font-size: 1.1rem;
  }

  .token_cont {
    margin: 1rem;
  }
}

@media print {
  .token_cont {
    display: none;
  }

  .greating_cont_heading {
    color: var(--secondary);
  }

  .greating_cont_desc {
    color: var(--secondary);
    width: 85%;
  }

  .hide_in_print {
    display: none;
  }
}
.showInterest_mainCont {
  width: 100%;
  height: auto;
  position: relative;
}
.showInterest_topBackgroundSection {
  background: var(--primary);
  width: 100%;
  height: 18rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
.showInterest_body {
  /* margin: 1rem; */
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
.showInterest_headingCont_ {
  color: var(--white);
  /* background-color: #48BA7E; */
  padding: 5rem;
}
.showInterest_heading {
  font-size: 2.5rem;
  line-height: 2.8rem;
  font-weight: bold;
  text-transform: uppercase;
}
.showInterest_description {
  font-size: 1.4rem;
  line-height: 1.8rem;
}
/* .showInterest_body_column {

} */
.showInterest_body_column_left {
  display: flex;
  flex: 1 1;
  align-items: center;
}
.showInterest_body_column_right {
  flex: 3 1;
  overflow: hidden;
}
.showInterest_columns_wrapper {
  display: flex;
  flex-direction: row;
  background: var(--white);
  border-radius: 0.5rem;
  padding: 0.5rem;
  height: 90vh !important;
}
.showInterest_form_wrapper {
  align-self: center;
  width: 90%;
  background: var(--white);
  box-shadow: none;
  height: 96%;
}
.step.active {
  color: var(--primary) !important;
}

/*  */
@media only screen and (max-width: 400px) {
  .showInterest_body_column_left {
    display: none;
  }
  .showInterest_body_column_right {
    flex: 1 1;
  }
}

.progressIndicatorcont {
  /* background: var(--primary); */
  width: 100%;
  margin: 5px 0;
}
.progressIndicator {
  background: var(--primaryLight);
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  transition: all 0.3s linear;
}
.progressIndicatorText {
  color: var(--primary);
  padding: 0.5rem 1rem;
  margin: 0 2rem;
}

@media only screen and (max-width: 991px) {
  .progressIndicatorText {
    display: none;
  }
}

.formComponent_searchCont {
  background: var(--info);
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.formComponent_searchText {
  background: transparent;
  font-size: 1.2rem;
  line-height: 1.2rem;
  margin: 0 0.2rem;
}
.showInterest_form_button {
  border-radius: 0.5rem;
}
.formComponent_listSectionHeading {
  font-weight: bold;
  padding: 0.2rem;
  color: var(--primary);
  text-transform: capitalize;
}
.formComponent_sectionListCont {
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background: var(--info); */
  margin: 0.5rem 0;
  border-radius: 0.5rem;
  width: 100%;
}
.formComponent_sectionListDivider {
  width: 98%;
  height: 1px;
  background: var(--infoPlus);
}
/*  */
.showInterest_form_child {
  max-height: 78%;
  overflow-y: scroll;
}

/* Handle */

.showInterest_form_child::-webkit-scrollbar-thumb {
  background: var(--primaryInfo);
  border-radius: 5px;
}

/* Handle on hover */

.showInterest_form_child::-webkit-scrollbar-thumb:hover {
  background: var(--primaryLight);
}
/*  */
@media only screen and (max-width:1274px) {
  .ui.fluid.steps {
    height: 100% !important;
    overflow-y: scroll !important ;
    overflow-x: hidden !important ;
  }
}



.shimer_showintest{
  width: 80%;
  /* border: 1px solid red; */
  margin: 0 auto;
  
  box-shadow: -1px 0px 10px -5px rgba(69,68,68,0.5);
  -webkit-box-shadow: -1px 0px 10px -5px rgba(69,68,68,0.5);
  -moz-box-shadow: -1px 0px 10px -5px rgba(69,68,68,0.5);
}
.Cardiv_wrap{
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    /* border: 1px solid red; */
    margin: 0 auto;
}



.mainDiv1{
    width: 100%;
   background-color: var(--primary);
   /* border: 1px solid red; */
   color: white;

}


.hedingcc1{
color: white;
font-weight: bold;
font-size: 2rem;
/* border: 1px solid white; */
}

.mdiv2{
    width: 100%;
    
}


.shimer-div{
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    /* border: 1px solid red; */
}


.shimer{
    width:48%;
    margin: 1.5rem auto;
}

.cardDiv{

    width:48%;
    margin: 1.5rem auto;
    /* border: 1px solid red; */
    padding: 5px 0px 4px 9px;
    
    box-shadow: -1px 0px 10px -5px rgba(69,68,68,0.5);
    -webkit-box-shadow: -1px 0px 10px -5px rgba(69,68,68,0.5);
    -moz-box-shadow: -1px 0px 10px -5px rgba(69,68,68,0.5);
   
}


.card_Div{
    /* border: 1px solid blue; */
    display: flex;
}


.fotDiv{
    width: 100%;
    /* border: 1px solid red; */
    display: flex;
flex: 1 1;
align-items: center;
flex-direction: row;
}


.fotDiv_last{
    display: flex;
}

.fot1{
    display: flex;
    flex-direction: row;
    /* border: 1px solid rgb(0, 255, 42); */
}


@media screen and (max-width: 534px) {
    

    .fotDiv_last{
        margin-top: 10px !important;
        display: flex;
        justify-content: flex-end;
    }
}

 @media screen and (max-width: 475px) {
  
.cardDiv{
    width: 100% !important;
}


} 

.car-model{
    font-size: 1.3rem;
}


.fueltype{
    font-size: 1.3rem;
    padding-left: 2px;
    display: flex;
    /* border:1px solid blue; */

}


.btntnext{
    width: 8rem;
    height: 2.5rem;
    border-radius: 0.5rem;
    display: inline-block;
    border: none;
    padding: 0px;
    margin: 0;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    background: none;
    background-color: var(--yellow);
    color: black;
}

.btntnext:hover{
    background-color:var(--yellow);
    color: var(--primary);
}

.date{
    /* border: 1px solid blue; */
    font-size: 1.2rem;
    display: block;
}
.showInterest_headingCont {
    color: var(--white);
    padding: 1rem;
  }
  .showInterest_heading {
    font-size: 2.5rem;
    line-height: 2.8rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  .showInterest_description {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }




  @media only screen   and (max-width: 1000px) {
    .cardDiv{
        width: 100% !important;
    }

  }
  @media only screen   and (max-width: 400px) {

    .showInterest_headingCont{
        padding: 20px 0px 24px 30px !important;
    

    }
    .showInterest_description{
        width: 100% !important;
        /* text-align: justify; */
    }
  }
.mainDiv {
  /* width: 40rem; */
  height: 20rem;
  /* border: 1px solid red; */
}











@media screen and (max-width: 375px) {
  
 .btn12{
   width: 100% !important;
 }
 .inp1{
   width: 100% !important;
 }
  
  } 



.inp1 {
  /* text-align: center; */
  border: 0.2rem solid black;
  border-radius: 0.2rem;
  width: 25rem;
  height: 3rem;
  margin: 0 auto;
}
.inptext {
  font-size: 1.1rem;
  width: 20rem;
}

.inp12 {
  text-align: center;
  border: 0.2rem solid black;
  border-radius: 0.2rem;
  width: 300px;

  margin: 0 auto;
}

.inp1:focus {
  border: 0.2rem solid black;
  border-radius: 0.2rem;
}

.inpdiva {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid red; */
}

.btn12 {
  background: var(--primary);
  width: 25rem;
  height: 3rem;
  margin: 0 auto;
  border-radius: 0.5rem;
  padding: 0.5rem;
  color: var(--info);
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  transition: all 0.3s ease;
  border: 0.3rem solid var(--primary);
  cursor: pointer;
}

.regno {
  width: 300px;
  height: 2rem;
  border: 1px solid red;
}

.reg {
  font-size: large;
  padding: 0.5rem;
}

.heading1 {
  font-size: 2.5rem;
  text-transform: uppercase;
}

.cartext1 {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  /* border: 1px solid red; */
}
.carflx1 {
  /* border: 1px solid red; */
  width: 50%;
  display: flex;
  flex-direction: column;
}

.showInterest_headingCont {
  color: var(--white);
  /* padding: 1rem; */
}
.showInterest_heading {
  font-size: 2.5rem;
  line-height: 2.8rem;
  font-weight: bold;
  text-transform: uppercase;
}
.showInterest_description {
  font-size: 1.4rem;
  line-height: 1.8rem;
}




@media screen and (max-width:375px) {

  .showInterest_heading{
    font-size: 2.3rem !important;
  }
  


  .inpdiva{
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid red; */
    margin: auto;
  }
.inptext{
  width: auto !important;
}


  
}
.cardma {
    width: 300px;
    box-shadow: 0px 0px 14px -5px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 14px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 14px -5px rgba(0,0,0,0.75);
  }
  
  .mdiv1 {
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    border-radius: 0.8rem;
  cursor: pointer;
    /* flex-wrap: nowrap; */
    /* background-color: red; */
    /* display: flex; */
    /* justify-content: center; */
    width: 80%;
   margin: 0 auto;
  }
  
  /* @media ( max-width: 800px) {
   .mdiv1{
    width: 90%;
    background-color: orange;
   }
  } */
  
  
  
  
  
  
  
  
  
  .sliderDiv{
    width: 100%;
    /* border: 1px solid blue; */
    /* background-color: var(--primary); */
    height: 300px;
  }
  
  .sliderDiv2{
    width: 80%;
    margin: 0 auto;
    /* border: 1px solid green; */
  }
  .slick-arrow{
    /* background-color: black; */
    /* color: black; */
  }
  .slick-prev{
    color: black;
    
  }
  
  
  .slick-prev:before{
    color: black;
  }
  .slick-next:before{
    color: black;
  }
  
  .head{
    font-weight: bold;
    font-size: 1.5rem;
  }
  .head2{
    font-size: 1.2rem;
  }
  
  
  .ttt{
      /* background-color: rgb(218, 210, 210); */
  }
  .notfoundpic{
      width: 30rem;
      height: 30rem;
      margin: 0 auto;
  }
  
  
  .maindivcar {
    display: flex;
    /* justify-content: center; */
    flex-wrap: nowrap;
    width:100%;
    align-items: center;
  
    /* border: 1px solid blue; */
  
    /* flex-direction: column; */
    /* background-color: red; */
  }
  
  
  .cola{
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
  }
  
  .colb{
    /* border: 1px solid blue; */
  }
  
  .carflxa {
    flex: none;
  }
  .picdiv {
  }
  .imga1 {
    height: 16rem;
    width: 25rem;
    border-radius: 7px;
    /* border: 1px solid gold; */
  }
  
  .cardtil {
  }
  
  .cartexta {
    display: flex;
    justify-content: space-between;
  }
  
  .txt {
    /* font-weight: bold; */
    font-size: 1.1rem;
  }
  
  
  .modelpics{
    padding: 2rem;
    /* border: 1px solid red; */
  }
  .modelpic{
    width: 50rem;
    /* border: 1px solid red; */
  }
  
  /* .slider{
    border: 1px solid red;
  } */
  
  .sliderdiv{
    width: 90%;
    background-color: red;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .clrclas{
    /* background-color: gray; */
  }
  
  .nocar{
    margin: auto auto;
    /* border: 1px solid red; */
  }
  
  
  
  
  .brndname{
    font-size: 2rem;
  }
  .modelvarint{
    font-size: 1.2rem;
  
  }
  
  
  .App {
    font-family: sans-serif;
    text-align: center;
    border: 1px red solid;
     width: 600px;
    height: 500px;
  }
  
  
  .Card_Div{
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
.carDetailBannerCont {
  width: 100%;
  background: var(--primary);
  padding: 1rem 0;
  
}

.cardma {
  width: 300px;
  box-shadow: 0px 0px 14px -5px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 14px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 14px -5px rgba(0, 0, 0, 0.75);
}

.mdiv1 {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  border-radius: 0.8rem;

  /* flex-wrap: nowrap; */
  /* background-color: red; */
  /* display: flex; */
  /* justify-content: center; */
  width: 80%;
}
.mdiv1shimer {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  border-radius: 0.8rem;

  /* flex-wrap: nowrap; */
  /* background-color: red; */
  /* display: flex; */
  /* justify-content: center; */
  width: 80%;
  /* margin: 0 auto; */
  /* margin-left: 50px; */
}


.shimerpic{
  width: 100%;
  height: 100%;
}
/* @media ( max-width: 800px) {
 .mdiv1{
  width: 90%;
  background-color: orange;
 }
} */

.sliderDiv {
  width: 100%;
  /* border: 1px solid blue; */
  /* background-color: var(--primary); */
  height: 300px;
}

.sliderDiv2 {
  width: 80%;
  margin: 0 auto;
  /* border: 1px solid green; */
}
/* .slick-arrow {
  background-color: black;
  color: black;
} */

.head {
  font-weight: bold;
  font-size: 1.5rem;
}
.head2 {
  font-size: 1.2rem;
}
/* 
.ttt {
  background-color: rgb(218, 210, 210);
} */
.notfoundpic {
  width: 30rem;
  height: 30rem;
  margin: 0 auto;
}

.maindivcar {
  display: flex;
  /* justify-content: center; */
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;

  /* border: 1px solid blue; */

  /* flex-direction: column; */
  /* background-color: red; */
}

.cola {
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
}

/* .colb {
  border: 1px solid blue;
} */

.carflxa {
  flex: none;
}
/* .picdiv {
} */
.img1 {
  height: 20rem;
  width: 17rem;
  /* border: 1px solid gold; */
}

/* .cardtil {
} */

.cartexta {
  display: flex;
  justify-content: space-between;
}

.txt {
  /* font-weight: bold; */
  font-size: 1.1rem;
}

.modelpics {
  padding: 2rem;
  /* border: 1px solid red; */
}
.modelpic {
  width: 50rem;
  /* border: 1px solid red; */
}

/* .slider{
  border: 1px solid red;
} */

.sliderdiv {
  width: 90%;
  background-color: red;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .clrclas {
  background-color: gray;
} */

.nocar {
  margin: auto auto;
  color: red;
  /* border: 1px solid red; */
}
.car_icon{
  
}
.brndname {
  font-size: 2rem;
}
.modelvarint {
  font-size: 1.2rem;
}
.ril__toolbarSide {
  height: 50px;
  margin: 77px;
}

.wrap{
  height: 27rem;
  width: 30rem;
 
  overflow: hidden;
  
  padding:2rem ;
}

.slider_main_cont {
  width: 100%;
  display: flex;
  justify-content: center;
}
.slider_carousel_wrapper {
  width: 80%;
  margin-top: 1rem;
}
.slider_card {
  background: rgba(255, 164, 155, 0.45);
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 0.5rem;
  margin: 0 0.5rem;
  height: 5rem;
}

.Shimer_car_Div{
  width: 80%;
  margin: 0 auto ;
  /* border: 1px solid black; */
}









.slider_card_active {
  /* background: rgba(255, 255, 255, 0.45);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px); */
  background: var(---white);

  border-radius: 0.5rem 0.5rem 0 0;
  padding: 0.5rem;
  margin: 0 0.5rem;
  height: 5rem;
}
.slider_card_title {
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.3rem;
}
.slider_card_description {
  /* font-weight: bold; */
  font-size: 1rem;
  line-height: 1.1rem;
}
@media only screen and (max-width: 1024px) {
  .slider_carousel_wrapper {
    width: 90%;
  }
}
@media only screen and (max-width: 464px) {
  .slider_carousel_wrapper {
    width: 98%;
  }
}

html,
body,
#root,
.App {
  height: 100%;
}

.App {
  /* width: 100%; */
}

/* Images Component CSS */

.react-fb-image-grid {
  margin: auto;
}

.react-fb-image-grid * {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  /* cursor: pointer; */
}

.col-md-4 {
  width: 33.33333333%;
}

.border {
  border: none;
  border-radius: 0;
}

.background {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.height-one {
  height: 100%;
  width: 100% !important;
}

.height-two {
  height: 65%;
  width: 100% !important;
}

.cover {
  background-color: #222;
  opacity: 0.8;
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.cover-text {
  right: 0;
  left: 0;
  bottom: 0;
  color: white;
  font-size: 1.2em !important;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  text-align: center;
}

.cover-text > p {
  margin: 0;
  position: absolute;
  font-size: 1.5em !important;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.slide {
  bottom: 0 !important;
  transition: 0.4s ease;
  overflow: hidden;
}

.border:hover .slide {
  bottom: 0;
  height: auto;
}

.border:hover .animate-text {
  top: 62%;
}

.showInterest_headingCont {
  color: var(--white);
  padding: 1rem;
}
.showInterest_heading {
  font-size: 2.5rem;
  line-height: 2.8rem;
  font-weight: bold;
  text-transform: uppercase;
}
.showInterest_description {
  font-size: 1.4rem;
  line-height: 1.8rem;
}


.car_step{
  cursor: pointer;
}


@media only screen and (max-width:500px){

  .list_row_text{
    font-size: 0.9rem !important;
  }

  .list_text_secound{
    margin-left: 20px !important;
  }

  .heading1{
    font-size: 2rem !important;
  }
}














.car_Data {
  /* border: 1px solid red; */
  width: 80%;

  margin: 0 auto;
}
/* ========================= */
.list_data_wrapper {
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  flex-direction: column;
}
.list_row {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0.5rem 0;

}
.list_row_text {
  font-size: 1.4rem;
  line-height: 1.4rem;
}



.iii{
 width: 100%;
 height: 100%;
 object-fit: contain;
}

.slider_div{
  width: 100vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}


.edit_btn_wrap{
  /* border: 1px solid red; */
  display: flex;
  justify-content: flex-end;

}

.edit_button{
 color: var(--primary);
  /* width: 100px ; */
  /* position: absolute; */
  /* right: 0; */
  /* bottom: ; */

}


.btn_wrap{
  width: 80%;
  /* position: relative; */
  /* border: 1px solid blue; */
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
}



.crosbtn{
  color: var(--primary);
  width: 100px ;
 



}




.Update_Sction{
  width: 100%;
  /* position: relative; */
  /* display: flex;
  justify-content: center; */
  
}

.Udate_form{
  width: 80%;
  margin: 0 auto;
  /* border: 1px solid red; */
}



.car_part_pic{
  width: 17rem;
  height: 16rem;
  /* border: 1px solid red; */
  padding: 3px;
}

.Car_Parts1_pic{
  width: 100%;
  /* border: 1px solid blue; */
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* align-items: center; */

}


.car_Pic{
    width: 70%;
    height: 20rem;
}


.car_Main_Wrap{
    /* border: 1px solid red; */
    width: 100%;
    margin: 0 auto;
    background-color: #1D1D1B;
    color: white;
}

.btn_wrap{
    /* border: 1px solid blue; */
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.icon_div{
    width: 3rem;
    height: 3rem;
    background-color: gray;
    color: white;
    border-radius: 50%;
    padding: 0.9rem;
    font-size: 1.1rem;
    margin: 0 auto;
}

.wwww{
    width: 400px;
    height: 400px;
    /* border: 1px solid red; */

}



/* .ruler_div{
    width: 100%;
    height: 100%;
    border: 1px solid red;
    overflow: hidden;
    padding-top: 5rem;
} */
.Car_Pic_Wrap{
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
}

#draggable { width: 150px; height: 150px; padding: 0.5em; }

.heading-size{
    font-weight: bold;
    font-size: 1.6rem;
}

.buble{
    width: 30px;
    height: 30px;
    background-color: yellow;
    border-radius: 50%;
    position: absolute;
   
}




#d1 {
    width: 120px;
    height: 120px;
    background-color :aqua;
    padding:20px;
    float:left;
    margin:5px;
    }
#d2 {
    width: 120px;
    height: 120px;
    background-color :orange;
    padding:20px;
    float:left;
    margin:5px;
    }
#d3 {
    width: 120px;
    height: 120px;
    background-color :yellow;
    padding:20px;
    float:left;
    margin:5px;
    }


    .lotie_wrap{
        width: 100%;
        /* border: 1px solid red; */
        display: flex;
        justify-content: center;
        align-items: center;
        /* overflow: hidden; */
        
    }

    .Lotie_Main_Wrap{
        overflow: hidden;
    }

    .web_wrap{
        /* height: 100vh; */
        width: 100%;
        justify-content: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: #FEF200;
        padding: 7.4rem;
       
    }

    .text_wrap_2{
        display: flex;
        justify-content: center;
        text-align: center;
        
    }

    .text_2{
        text-align: center;
    }
    .text_3{
        font-size: 1.2rem;
        text-align: center;
    }
    /* .aaa{
        background-color: #a9aeb9;

    } */


.Dmage_wrap{
    width: 100%;
    /* border: 1px solid red; */

}


.damage_img{
    width: 13rem;
    height: 13rem;
    border-radius: 10px;
  
}
.damage_img_a{
    width: 13rem;
    height: 13rem;
    border-radius: 10px;
    background-color: #F2F2F2;
}

.Dmage_inside{
    display: flex;
    flex-wrap: wrap;
}


.retake_btn{
    position: absolute;
    z-index: 1000;
    /* border: 1px solid red; */
    color: white;
    /* top: px; */
    left: 5px;
    top: 107px;
    font-size: 2rem;
}

.edit_icon{
    border: 1px solid gray;
    position: absolute;
    background-color: white;
    border-radius: 50%;
    padding: 0.6rem;
    right: -5px;
    top: -5px;
    font-size: 1.5rem;

}

.imge_wrap{
    position: relative;
}


.no_pic_div{
    background-color: #F2F2F2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.add_text{
    color: blue;
}
.showInterest_headingCont {
    color: var(--white);
    background-color: #48BA7E;
    padding: 5rem;
    /* padding-left: 12rem; */
  }
  .showInterest_heading {
    font-size: 2.5rem;
    line-height: 2.8rem;
    font-weight: bold;
    text-transform: uppercase;
 
    
  }
  .showInterest_description {
    font-size: 1.4rem;
    line-height: 1.8rem;
    padding: 1rem;
  }


  .card_wrap_contactus_{

    min-height: 43vh;
    display: flex;
    background-color: #F2F4F5;
    /* border: 1px solid red; */
    justify-content: center;

  }

  .card_contact{
      background-color: white;
      /* min-height: 45vh; */
  }

  .mobile_svga{
      height: 4rem;
      width: 4rem;
  }

  .sell_car{
      color: #48BA7E;
  }

  .btn_contact__{
      /* background-color: #FFD700; */
      width: 100%;
      height: 3rem;
      font-weight: bold;
    
  }

  .card_row_{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 0 auto;
  }

@media only screen and (max-width:992px)and (min-width:700px) {
    .card_row_{
        width: 70% !important;
    }
    
}


  .text_tow{
      font-size: 1rem;
      color: black;
      font-family: Arial, Helvetica, sans-serif;
  }

  /* @media screen and (max-width: 767px) {
    .mobile-space {
        margin-top:60px;
        background-color: red;
    }
    } */



    .contact_wrap{
        background-color: #F2F4F5;
        /* height: 70vh; */
    }

    .inp_wrap{
        /* border: 2px solid #096C59; */
        height: 3.7rem;
        background-color: white;
        padding: 0.7rem;
    }

.inp_wrap_coment{

    border: 2px solid #096C59;
    height: 5.7rem;
    background-color: white;
    padding: 0.7rem;

}



    .inp_contact{
        width: 100%;
        padding: 5px;
        color: black;
        /* border: 1px solid red; */
    }
.span_text{
    cursor: pointer;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
/* background-color: yellow; */
color: black;
margin: 2px;
}

.css-1n3h4wo{
    height: auto !important;
}
.us_text{
    font-size: 1.5rem;
}
.doc_list{
    display: flex;
    flex-direction: row;
}

.curor_pointer{
background-color: var(--primary);
    cursor: pointer;
    color: white;
    padding-left: 5px;
}
.showInterest_headingCont {
    color: var(--white);
    background-color: #48BA7E;
    padding: 5rem;
    /* padding-left: 12rem; */
  }
  .showInterest_heading {
    font-size: 2.5rem;
    line-height: 2.8rem;
    font-weight: bold;
    text-transform: uppercase;
 
    
  }
  .showInterest_description {
    font-size: 1.4rem;
    line-height: 1.8rem;
    padding: 1rem;
  }


  .card_wrap_contactus{
      /* height: 30vh; */
      background-color: #F2F4F5;

  }

  .card_contact{
      background-color: white;
  }

  .mobile_svg{
      height: 4rem;
      width: 4rem;
  }

  .sell_car{
      color: #48BA7E;
  }

  .btn_contact{
      /* background-color: #FFD700; */
      width: 100%;
      height: 3rem;
      font-weight: bold;
    
  }
  .btn_contact:hover{
      /* border: 4px solid #FFD700; */
      /* background-color: white; */
      width: 100%;
      height: 3rem;
      font-weight: bold;
  }

  .card_row{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90%;
      margin: 0 auto;
  }

  .text_tow{
      font-size: 1rem;
      color: black;
      font-family: Arial, Helvetica, sans-serif;
  }

  /* @media screen and (max-width: 767px) {
    .mobile-space {
        margin-top:60px;
        background-color: red;
    }
    } */



    .contact_wrap{
        background-color: #F2F4F5;
        /* height: 70vh; */
    }

    .inp_wrap{
        border: 2px solid #096C59;
        height: 3.7rem;
        background-color: white;
        padding: 0.7rem;
    }

.inp_wrap_coment{

    border: 2px solid #096C59;
    height: 5.7rem;
    background-color: white;
    padding: 0.7rem;

}



    .inp_contact{
        width: 100%;
        padding: 5px;
        color: black;
        /* border: 1px solid red; */
    }
.showInterest_headingCont {
    color: var(--white);
    background-color: #48BA7E;
    padding: 5rem;
    /* padding-left: 12rem; */
  }
  .showInterest_heading {
    font-size: 2.5rem;
    line-height: 2.8rem;
    font-weight: bold;
    text-transform: uppercase;
 
    
  }
  .showInterest_description {
    font-size: 1.4rem;
    line-height: 1.8rem;
    padding: 1rem;
  }


  .card_wrap_contactus{
      /* height: 30vh; */
      background-color: #F2F4F5;

  }

  .card_contact{
      background-color: white;
  }

  .mobile_svg{
      height: 4rem;
      width: 4rem;
  }

  .sell_car{
      color: #48BA7E;
  }

  .btn_contact{
      /* background-color: #FFD700; */
      width: 100%;
      height: 3rem;
      font-weight: bold;
    
  }
  .btn_contact:hover{
      /* border: 4px solid #FFD700; */
      /* background-color: white; */
      width: 100%;
      height: 3rem;
      font-weight: bold;
  }

  .card_row{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90%;
      margin: 0 auto;
  }

  .text_tow{
      font-size: 1rem;
      color: black;
      font-family: Arial, Helvetica, sans-serif;
  }

  /* @media screen and (max-width: 767px) {
    .mobile-space {
        margin-top:60px;
        background-color: red;
    }
    } */
.inp_heading{
    text-align: start;
}


    .contact_wrap{
        background-color: #F2F4F5;
        text-align: center;
        /* height: 70vh; */
    }

    .inp_wrap{
        border: 2px solid #096C59;
        height: 3.7rem;
        background-color: white;
        padding: 0.7rem;
    }

.inp_wrap_coment{

    border: 2px solid #096C59;
    height: 5.7rem;
    background-color: white;
    padding: 0.7rem;

}



    .inp_contact{
        width: 100%;
        padding: 5px;
        color: black;
        /* border: 1px solid red; */
    }

    .main_Wrap_ss{
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 0 8px #1d1d1b40;
        min-height: 460px;
        padding: 48px;
        text-align: left;
    }
    
    .inp_main{
        width: 100%;
        display: flex;
        border: 2px solid #afada3;
    }
    
    .city_list{
            background-color: var(--generic-bg-color);
        border: 2px solid #afada3;
        border-radius: 0 0 4px 4px;
        border-top: 0;
        box-shadow: 1px 1px 1px #e2e1d9, -1px 1px 1px #e2e1d9, 0 1px 1px #e2e1d9;
        left: 0;
        /* height: 300px; */
        /* /* min-height: 300px; */
        max-height: 300px; 
        
        top: 100%;
        width: 100%;
        overflow: auto;
        text-align: left;
    }
    
    .lii{
        list-style: none;
      line-height: 12px;
      padding: 5px;
        font-weight: 2rem;
        list-style-position:inside;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .lii:hover{
        /* background-color: #afada3; */
        cursor: pointer;
    }
    
    .kkkkkkkk{
        /* padding: 50px; */
        padding-bottom: 100px;
    }
    
    .inp_inp{
        -webkit-appearance: none;
        background-color: var(--generic-bg-color);
        border: 2px solid var(--generic-border-color);
        border-radius: 4px;
        color: var(--generic-text-color);
        font-size: 16px;
        font-weight: 400;
        height: 48px;
        margin: 0;
        outline: none;
        padding: 0 16px;
        text-align: left;
        width: 100%;
    
    }

    .abca{
        background-color: white;
    }
    
    
    .srech_{
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
.showInterest_headingCont {
    color: var(--white);
    background-color: #48BA7E;
    padding: 5rem;
    /* padding-left: 12rem; */
  }
  .showInterest_heading {
    font-size: 2.5rem;
    line-height: 2.8rem;
    font-weight: bold;
    text-transform: uppercase;
 
    
  }
  .showInterest_description {
    font-size: 1.4rem;
    line-height: 1.8rem;
    padding: 1rem;
  }


  .card_wrap_contactus{
      /* height: 30vh; */
      background-color: #F2F4F5;

  }

  .card_contact{
      background-color: white;
  }

  .mobile_svg{
      height: 4rem;
      width: 4rem;
  }

  .sell_car{
      color: #48BA7E;
  }

  .btn_contact{
      /* background-color: #FFD700; */
      width: 100%;
      height: 3rem;
      font-weight: bold;
    
  }
  .btn_contact:hover{
      /* border: 4px solid #FFD700; */
      /* background-color: white; */
      width: 100%;
      height: 3rem;
      font-weight: bold;
  }

  .card_row{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90%;
      margin: 0 auto;
  }

  .text_tow{
      font-size: 1rem;
      color: black;
      font-family: Arial, Helvetica, sans-serif;
  }

  /* @media screen and (max-width: 767px) {
    .mobile-space {
        margin-top:60px;
        background-color: red;
    }
    } */



    .contact_wrap{
        background-color: #F2F4F5;
        /* height: 70vh; */
    }

    .inp_wrap{
        /* border: 2px solid #096C59; */
        height: 3.7rem;
        background-color: white;
        padding: 0.7rem;
    }

.inp_wrap_coment{

    border: 2px solid #096C59;
    height: 5.7rem;
    background-color: white;
    padding: 0.7rem;

}



    .inp_contact{
        width: 100%;
        padding: 5px;
        color: black;
        /* border: 1px solid red; */
    }
.inp_wrap{


    background-color: #fff;
    border: 2px solid  #fff;
    border-radius: 4px;
    box-shadow: 0 0 8px #1d1d1b40;
    cursor: pointer;
    /* padding: 24px; */
    transition: border-color .2s linear;
    width: 100%;
}

.inp_wrap:hover{
    box-shadow: 0 0 12px #03030340;
    border: 2px solid  #afada3;
}

.inp_search{
    background-color: red;
}

.prompt{
    /* background-color: black !important; */
    width: 500px !important;
}

.next_btn{
    align-items: center;
    -webkit-appearance: none;
    background-color: #0460cc;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: NewTransportSemi,Helvetica,Arial,sans-serif;
    font-size: 18px;
    font-weight: 400;
    height: 48px;
    justify-content: center;
    line-height: 24px;
    margin-bottom: 24px;
    min-width: 112px;
    outline: none;
    padding: 4px 8px;
    transition: background-color .1s linear;
    vertical-align: middle;
    width: 200px;
    /* width: 100%; */
}

.ui.input{
    width: 60%!important;
} 


.qwqw{
    display: flex;
    /* border: 1px solid red; */
    flex-direction: row;
    align-items: center;
}
.back_btn_{
    max-width: 300px;
}

@media screen and (max-width: 700px) {
    .button-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
    } 
}
.back_btn-{
    align-items: center;
    -webkit-appearance: none;

   
    border-radius: 4px;

    cursor: pointer;
    display: flex;
    font-family: NewTransportSemi,Helvetica,Arial,sans-serif;
    font-size: 18px;
    font-weight: 400;
    height: 48px;
    justify-content: center;
    line-height: 24px;
    /* background-color: #fff; */
    /* border: 2px solid #e2e1d9; */
    /* color: #0460cc; */
    /* transition: background-color .1s linear,border-color .1s linear; */
    margin-bottom: 24px;
    min-width: 112px;
    outline: none;
    padding: 4px 8px;
    /* transition: background-color .1s linear;
    vertical-align: midd; */
    width: 200px;
    /* width: 100%; */
}

.inp_main_wrapp{
    padding: 20px;
    height: 50vh;
    /* border: 1px solid red; */
}


.progresbar{
    position: fixed;
 
    /* border: 1px solid red; */

} 
.p_wrap{
    position: absolute;
    top: 15px;
}
.aaaaaa{
    position: relative;
   

}

.css-1l6rwmg-MuiLinearProgress-root{
    position: fixed !important;
    width: 100% !important;
    z-index: 10000 !important;
    
}

.css-1l6rwmg-MuiLinearProgress-root .MuiLinearProgress-bar{
    background-color: #002F34 !important;
    top: 91px!important;
}


.botm_wrap{
       padding: 0px 10px 10px 10px;
    position: unset !important;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
}


.inp_col{
    display: flex;
    justify-content: flex-end;
}

 .inp_col_2{
    display: flex;
    /* justify-content: start; */
} 
.main_Wrap_ss{
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 8px #1d1d1b40;
    min-height: 460px;
    padding: 48px;
    text-align: left;
    border: 1px solid red;
}

.inp_main{
    width: 100%;
    display: flex;
    border: 2px solid #afada3;
}

.city_list{
        background-color: var(--generic-bg-color);
    border: 2px solid #afada3;
    border-radius: 0 0 4px 4px;
    border-top: 0;
    box-shadow: 1px 1px 1px #e2e1d9, -1px 1px 1px #e2e1d9, 0 1px 1px #e2e1d9;
    left: 0;
    /* height: 300px; */
    /* /* min-height: 300px; */
    max-height: 300px; 
    
    top: 100%;
    width: 100%;
    overflow: auto;
    text-align: left;
}
.enter{
    /* background-color: #178971 !important; */
    color: blue;
    /* padding: 2px; */
    text-decoration: underline;
}

.lii{
    list-style: none;
  line-height: 12px;
  padding: 5px;
    font-weight: 2rem;
    list-style-position:inside;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lii:hover{
    /* background-color: #afada3; */
    cursor: pointer;
}

.kkkkkkkk{
    /* padding: 50px; */
    padding-bottom: 100px;
}

.inp_inp{
    -webkit-appearance: none;
    background-color: var(--generic-bg-color);
    border: 2px solid var(--generic-border-color);
    border-radius: 4px;
    color: var(--generic-text-color);
    font-size: 16px;
    font-weight: 400;
    height: 48px;
    margin: 0;
    outline: none;
    padding: 0 16px;
    text-align: left;
    width: 100%;

}


.srech_{
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.inp_wrap{


    background-color: #fff;
    border: 2px solid  #fff;
    border-radius: 4px;
    box-shadow: 0 0 8px #1d1d1b40;
    cursor: pointer;
    /* padding: 24px; */
    transition: border-color .2s linear;
    width: 100%;
}

.inp_wrap:hover{
    box-shadow: 0 0 12px #03030340;
    border: 2px solid  #afada3;
}

.inp_search{
    background-color: red;
}

.prompt{
    /* background-color: black !important; */
    width: 500px !important;
}

.next_btn{
    align-items: center;
    -webkit-appearance: none;
    background-color: #0460cc;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: NewTransportSemi,Helvetica,Arial,sans-serif;
    font-size: 18px;
    font-weight: 400;
    height: 48px;
    justify-content: center;
    line-height: 24px;
    margin-bottom: 24px;
    min-width: 112px;
    outline: none;
    padding: 4px 8px;
    transition: background-color .1s linear;
    vertical-align: middle;
    width: 200px;
    /* width: 100%; */
}

.ui.input{
    width: 60%!important;
} 


.qwqw{
    display: flex;
    /* border: 1px solid red; */
    flex-direction: row;
    align-items: center;
}


.back_btn{
    align-items: center;
    -webkit-appearance: none;

   
    border-radius: 4px;

    cursor: pointer;
    display: flex;
    font-family: NewTransportSemi,Helvetica,Arial,sans-serif;
    font-size: 18px;
    font-weight: 400;
    height: 48px;
    justify-content: center;
    line-height: 24px;
    background-color: #fff;
    border: 2px solid #e2e1d9;
    color: #0460cc;
    transition: background-color .1s linear,border-color .1s linear;
    margin-bottom: 24px;
    min-width: 112px;
    outline: none;
    padding: 4px 8px;
    transition: background-color .1s linear;
    vertical-align: midd;
    width: 200px;
    /* width: 100%; */
}

.inp_main_wrapp{
    padding: 20px;
    height: 50vh;
    /* border: 1px solid red; */
}


.progresbar{
    position: fixed;
 
    /* border: 1px solid red; */

} 
.p_wrap{
    position: absolute;
    top: 15px;
}
.aaaaaa{
    position: relative;
   

}

.css-1l6rwmg-MuiLinearProgress-root{
    position: fixed !important;
    width: 100% !important;
    z-index: 10000 !important;
    
}

.css-1l6rwmg-MuiLinearProgress-root .MuiLinearProgress-bar{
    background-color: #002F34 !important;
    top: 91px!important;
}


.botm_wrap{
    padding: 0px 10px 10px 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
}


.inp_col{
    display: flex;
    justify-content: flex-end;
}

 .inp_col_2{
    display: flex;
    /* justify-content: start; */
} 
.inp_wrapx{

    /* border: 2px solid #096C59; */
    height: 3.7rem;
    /* background-color: #178971; */
    padding: 1.1rem;
    color: var(--primary);
       font-size: 1rem;
    font-weight: bold;

    box-shadow: 0 0 8px #1d1d1b40;   
}

.inp_wrapx_{
      padding: 0.4rem; 

          /* border: 2px solid #096C59; */
    height: 3.7rem;
    /* background-color: #178971; */

    color: var(--primary);
       font-size: 1rem;
    font-weight: bold;

    box-shadow: 0 0 8px #1d1d1b40;   
}

.aclas{
 /* background-color: red; */
 /* border: 2px solid red; */
}
.bclas{
      border: 0.2rem solid var(--primary);
    color: var(--primary);
      font-size: 1rem;
    font-weight: bold;
    padding: 1rem;
}
.bclas_{

      border: 0.2rem solid var(--primary);
    color: var(--primary);
      font-size: 1rem;
    font-weight: bold;
    padding: 0.2rem;
}

 
.inp_wrapx.aaaaaa{
    box-shadow: 0 0 12px #03030340;
    border: 2px solid  #48BA7E;
} 
.inp_wrapx:hover{
    /* box-shadow: 0 0 12px #03030340; */
      border: 0.2rem solid gray;
    color: var(--primary);
      font-size: 1rem;
    font-weight: bold;
    padding: 1rem;
        /* font-weight: bold; */
} 
.inp_wrapx_:hover{
    /* box-shadow: 0 0 12px #03030340; */

border: 0.2rem solid gray;
    color: black;
      font-size: 1rem;
    font-weight: bold;
    padding: 0.2rem;
        /* font-weight: bold; */
} 

.inp_search{
    background-color: red;
}

.prompt{
    /* background-color: black !important; */
    width: 500px !important;
}

.next_btn{
    align-items: center;
    -webkit-appearance: none;
    background-color: #0460cc;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: NewTransportSemi,Helvetica,Arial,sans-serif;
    font-size: 18px;
    font-weight: 400;
    height: 48px;
    justify-content: center;
    line-height: 24px;
    margin-bottom: 24px;
    min-width: 112px;
    outline: none;
    padding: 4px 8px;
    transition: background-color .1s linear;
    vertical-align: middle;
    width: 200px;
    /* width: 100%; */
}

.ui.input{
    width: 60%!important;
} 


.qwqw{
    display: flex;
    /* border: 1px solid red; */
    flex-direction: row;
    align-items: center;
}


.back_btn{
    align-items: center;
    -webkit-appearance: none;

   
    border-radius: 4px;

    cursor: pointer;
    display: flex;
    font-family: NewTransportSemi,Helvetica,Arial,sans-serif;
    font-size: 18px;
    font-weight: 400;
    height: 48px;
    justify-content: center;
    line-height: 24px;
    background-color: #fff;
    border: 2px solid #e2e1d9;
    color: #0460cc;
    transition: background-color .1s linear,border-color .1s linear;
    margin-bottom: 24px;
    min-width: 112px;
    outline: none;
    padding: 4px 8px;
    transition: background-color .1s linear;
    vertical-align: midd;
    width: 200px;
    /* width: 100%; */
}

.inp_main_wrappa{
    padding: 20px;
    display: flex;
    /* border: 1px solid red; */
    /* justify-content: center; */
}


.progresbar{
    position: fixed;
 
    /* border: 1px solid red; */

} 
.p_wrap{
    position: absolute;
    top: 15px;
}
.aaaaaa{
    position: relative;
   

}

.css-1l6rwmg-MuiLinearProgress-root{
    position: fixed !important;
    width: 100% !important;
    z-index: 10000 !important;
    
}

.css-1l6rwmg-MuiLinearProgress-root .MuiLinearProgress-bar{
    background-color: #002F34 !important;
    top: 91px!important;
}


.botm_wrap{
      padding: 0px 10px 10px 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
}


.inp_col{
    display: flex;
    justify-content: end;
}

 .inp_col_2{
    display: flex;
    /* justify-content: start; */
} 

/* .color_bg{
   background-color: #ff7255!important;  
} */
@media only screen and (max-width: 576px){
    .prog {
        width: 100% !important;
        /* height: 40vh; */
        /* margin: 0 auto; */
    }
    .card_content{
        margin-bottom: 2%;
    }

    .min_w{
        min-width: 10rem !important;
    }
    /* .car_card_step .card_bto{
        font-size: 0.7rem;
        width:100% !important;
    } */
    .car_card_step{
        width: 100% !important;
        /* height: 40vh; */
        align-items: center !important;
        background-color: #fff;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        box-shadow: 0 0 8px #1d1d1b1f;
        display: flex;
        padding: 48px 0px 40px !important;
        margin: 0 auto;
        flex-direction: column !important; 
        align-content: center !important;
    }
}
.prog{
       width: 80%;
    /* height: 40vh; */
    margin: 0 auto; 
}

.prog_clas .progress-bar {
  background-color: #178971 !important;
  font-size: 8px !important;
  /* color: black !important; */
  /* font-weight: bold !important; */
  background-image: none !important;
}

.car_card_step{
    
    width: 80%;
    /* height: 40vh; */


    align-items: center;
    background-color: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: 0 0 8px #1d1d1b1f;
    
    display: flex;
    padding: 48px 48px 40px;
    margin: 0 auto;

}



@media only screen and (max-width: 490px){
.card_bton{
    /* min-width: auto !important; */
    font-size: 14px !important;

}
.card_bton_w{

    /* min-width: auto !important; */
    font-size: 14px !important;

}

  }



.card_content{
    align-items: center;
    background-color: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
 
    display: flex;
 

}


.card_bton{
    align-items: center;
    -webkit-appearance: none;
    background-color: #0460cc;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: NewTransportSemi,Helvetica,Arial,sans-serif;
    font-size: 18px;
    font-weight: 400;
    height: 48px;
    justify-content: center;
    line-height: 24px;
    margin-bottom: 24px;
    min-width: 90px;
    outline: none;
    padding: 4px 8px;
    transition: background-color .1s linear;
    vertical-align: middle;
    width: 100%;

}


.card_bton_w{


    background-color: #fff;
    border: 2px solid #e2e1d9;
    box-shadow: 0 2px 4px 0 #e2e1d9;
    align-items: center;
    -webkit-appearance: none;


    border-radius: 4px;
    color: #0460cc ;
    cursor: pointer;
    display: flex;
    font-family: NewTransportSemi,Helvetica,Arial,sans-serif;
    font-size: 18px;
    font-weight: 400;
    height: 48px;
    justify-content: center;
    line-height: 24px;
    margin-bottom: 24px;
    min-width: 90px;
    outline: none;
    padding: 4px 8px;
    transition: background-color .1s linear;
    vertical-align: middle;
    width: 100%;

}



.motorway-svg-fill {
    fill: #0460cc;
}

.cici{
    /* background-color: #0460cc; */
    color: #178971;
}

.btnReload{
    font-size: 30px;
    cursor: pointer;
    width: 40px !important;
}
.showInterest_headingCont {
    color: var(--white);
    background-color: #48BA7E;
    padding: 5rem;
    /* padding-left: 12rem; */
  }
  .showInterest_heading {
    font-size: 2.5rem;
    line-height: 2.8rem;
    font-weight: bold;
    text-transform: uppercase;
 
    
  }
  .showInterest_description {
    font-size: 1.4rem;
    line-height: 1.8rem;
    padding: 1rem;
  }


  .card_wrap_contactus{
      height: 100%;
      min-height: 70vh;
      background-color: #F2F4F5;
      /* border: 1px solid red; */

  }

  .card_contact{
      background-color: white;
      /* height: 40vh; */
      /* min-width: 250px; */
      /* border: 1px solid blue; */

  }

  .mobile_svg{
      height: 100%;
      width: 100%;
  }

  .sell_car{
      color: #48BA7E;
  }

  .btn_contact{
      /* background-color: #FFD700; */
      width: 100%;
      height: 3rem;
      font-weight: bold;
    
  }
  .btn_contact:hover{
      /* border: 4px solid #FFD700; */
      /* background-color: white; */
      width: 100%;
      height: 3rem;
      font-weight: bold;
  }

  .card_row{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      width: 80%;
      margin: 0 auto;
  }

  @media only screen and (max-width:600px) {

      .card_row{
          width: 100% !important;
      }
  }




  .card_main_wrap{
    max-width: 400px;
    min-width: 300px;
  }
  .text_tow{
      font-size: 1rem;
      color: black;
      font-family: Arial, Helvetica, sans-serif;
  }

  /* @media screen and (max-width: 767px) {
    .mobile-space {
        margin-top:60px;
        background-color: red;
    }
    } */

.shimer_w{

    margin: 0 auto;
    /* border: 1px solid red; */
    padding: 50px;
}

    .contact_wrap{
        background-color: #F2F4F5;
        /* height: 70vh; */
    }

    .inp_wrap{
        border: 2px solid #096C59;
        height: 3.7rem;
        background-color: white;
        padding: 0.7rem;
    }

.inp_wrap_coment{

    border: 2px solid #096C59;
    height: 5.7rem;
    background-color: white;
    padding: 0.7rem;

}



    .inp_contact{
        width: 100%;
        padding: 5px;
        color: black;
        /* border: 1px solid red; */
    }
.showInterest_headingCont {
    color: var(--white);
    background-color: #48BA7E;
    padding: 5rem;
    /* padding-left: 12rem; */
  }
  .showInterest_heading {
    font-size: 2.5rem;
    line-height: 2.8rem;
    font-weight: bold;
    text-transform: uppercase;
 
    
  }
  .showInterest_description {
    font-size: 1.4rem;
    line-height: 1.8rem;
    padding: 1rem;
  }


  .card_wrap_contactus{
      /* height: 30vh; */
      background-color: #F2F4F5;

  }

  .card_contact{
      background-color: white;
  }

  .mobile_svg{
      height: 100%;
      width: 100%;
  }

  .sell_car{
      color: #48BA7E;
  }

  .btn_contact{
      /* background-color: #FFD700; */
      width: 100%;
      height: 3rem;
      font-weight: bold;
    
  }
  .btn_contact:hover{
      /* border: 4px solid #FFD700; */
      /* background-color: white; */
      width: 100%;
      height: 3rem;
      font-weight: bold;
  }

  .card_row{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      width: 80%;
      margin: 0 auto;
  }

  .text_tow{
      font-size: 1rem;
      color: black;
      font-family: Arial, Helvetica, sans-serif;
  }

  /* @media screen and (max-width: 767px) {
    .mobile-space {
        margin-top:60px;
        background-color: red;
    }
    } */



    .contact_wrap{
        background-color: #F2F4F5;
        /* height: 70vh; */
    }

    .inp_wrap{
        border: 2px solid #096C59;
        height: 3.7rem;
        background-color: white;
        padding: 0.7rem;
    }

.inp_wrap_coment{

    border: 2px solid #096C59;
    height: 5.7rem;
    background-color: white;
    padding: 0.7rem;

}



    .inp_contact{
        width: 100%;
        padding: 5px;
        color: black;
        /* border: 1px solid red; */
    }


.btn_row{
    display: flex;
    justify-content: center;
}

.shiping_book{
    box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.3);
    /* height: 30vh; */
    width: 60%;
    margin: 0 auto;
}

.ship_wrap{
    width: 80%;
    border: 1px solid red;
    margin: 0 auto;
}
*, *::after, *::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}


.shoping_h{
  margin: 0;
  padding: 0;
  /* display: inline-block; */
}

.root {
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.3);
}

figure {
  display: flex;
}
figure img {
  width: 8rem;
  height: 8rem;
  border-radius: 15%;
  border: 1.5px solid #f05a00;
  margin-right: 1.5rem;
  padding:1rem;
}
figure figcaption {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
figure figcaption h4 {
  font-size: 1.4rem;
  font-weight: 500;
}
figure figcaption h6 {
  font-size: 1rem;
  font-weight: 300;
}
figure figcaption h2 {
  font-size: 1.6rem;
  font-weight: 500;
}

.order-track {
  margin-top: 2rem;
  padding: 0 1rem;
  border-top: 1px dashed #2c3e50;
  padding-top: 2.5rem;
  display: flex;
  flex-direction: column;
}
.order-track-step {
  display: flex;
  height: 7rem;
}
.order-track-step:last-child {
  overflow: hidden;
  height: 4rem;
}
.order-track-step:last-child .order-track-status span:last-of-type {
  display: none;
}
.order-track-status {
  margin-right: 1.5rem;
  position: relative;
}
.order-track-status-dot {
  display: block;
  width: 2.2rem;
  height: 2.2rem;
  border: 2px solid #f05a00;
  border-radius: 50%;
  /* background-color: white; */
  background: #f05a00;
}
.clr{
  background-color: white;
}




.order-track-status-line {
  display: block;
  margin: 0 auto;
  width: 2px;
  height: 7rem;
  background: #f05a00;
}
.order-track-text-stat {
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 3px;
}
.order-track-text-sub {
  font-size: 1rem;
  font-weight: 300;
}

.order-track {
  transition: all .3s height 0.3s;
  -webkit-transform-origin: top center;
          transform-origin: top center;
}





@media screen and (max-width: 500px) {
  
  .smim__{
    width: 100% !important;
  }
   
   } 


.left_wrap{
    width: 50rem;
    height: 50vh;
    position: relative;
}

/* .car_imgimg{
    width:100%;
    height:40%
} */
.car_up_text{
    position: absolute;
    color: gray;
    font-weight: bold;
    /* top: 70px; */
    /* left: 150px; */
    z-index: 1000px;
   
}
.car_up_text2{
    position: absolute;
    color: gray;
    font-weight: bold;
    /* top: 170px; */
    /* left: 460px; */
    z-index: 1000px;
}
.car_up_text3{
    position: absolute;
    color: gray;
    font-weight: bold;
    top: 290px;
    left: 150px;
    z-index: 1000px;
}






.car_damage{
    padding: 5px;
    border-radius: 10px;
    position: absolute;
    top: 20px;
    right: 20px;
    /* width: 13rem; */
    /* height: 17rem; */
    display: flex;
    justify-content: center;
    border: 2px solid gray;
}
.main_wrap_car_damge{
    position: relative;
    /* border: 1px solid red; */
    display: flex;
    /* flex-direction: row; */
    flex-wrap: nowrap;

}

.car_iii{
  

    width: 10rem;
    height: 10rem;
    /* border: 1px solid red; */
    margin: 0 auto;

}
.main_line{
    /* float: right; */
    border: 1px solid grey;
    position: absolute;
    width: 150px;
    top: 80px;
    right:18%;
    /* left: 170px; */

    z-index: 10000;
}

.right_wrap{
    /* position: absolute; */
    /* border: 1px solid red; */
    display: flex;
    flex-wrap: nowrap;
}
.main_section{
    padding:0px 4px 30px;
}
#image_map_print{
    display: none;
}
.maphilighted {
    background: url(https://wsa4.pakwheels.com/assets/car_certification_v2-e9ae77b….jpg) no-repeat center center !important;
    display: block;
    position: relative;
    padding: 0px;
    width: 553px;
    height: 406px;
}
.taggd-wrapper {
    overflow: hidden;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
}
.carsure-structure-outer .carsure-structure-info {
    position: absolute;
    /* top: 0px; */
    right: 75px;
    width: 180px;
    padding: 20px;
    border: 2px solid #7b8397;
    border-radius: 2px;
    min-height: 100px;
    text-align: center;
    background-color: #FFF;
}
.carsure-structure-outer .carsure-structure-info .pointer {
    background: #7b8397;
    height: 2px;
    margin-top: -2px;
    position: absolute;
    right: 100%;
    top: 60px;
}
.carsure-structure-outer .carsure-structure-infob  {
    background: #7b8397;
width: 80%;
    margin-top: -2px;
    position: absolute;
    right: 100%;
    top: 60px;
    border: 1px solid red;
}

.pointerb{

}



.line_botm{
    border: 1px solid #7b8397 ;

    width: 0px;
    position: absolute;
    z-index: 1000px;
    bottom: 59px;
  
 
}

.damage_show{
    position: relative;
    width: 250px;
    border: 1px solid #7b8397;
    /* width: 70%; */
    /* margin: 0 auto; */
    /* padding: 5px; */
}

.pic_show{
    width: 80%;
    margin: 0 auto;
}








@media only screen and (max-width: 900px) and (min-width: 0px) {
   
    #car_div{
        display: none;
    }
    .pointer{
        display: none;
    }
    
    
  }
@media only screen and (max-width: 1800px) and (min-width: 901px) {
    .damage_show {
      display: none;
    }
    .line_botm {
      display: none;
    }
   
    
  }

@media only screen and (max-width: 768px) and (min-width: 0px) {
   
  .car_damage{
      width: 91%;
  }
    
    
  }
@media only screen and (max-width: 991px) and (min-width: 768px) {
 
    .car_damage{
        width: 96%;
    }
     
    
  }
@media only screen and (max-width: 1440px) and (min-width: 991px) {
 
    .car_damage{
        width: 100%;
    }
     
    
  }




.pint_name{
    font-weight: bold;
    font-size: larger;
    color: black;
}

.p_clor{
    color: #545454;
    width: 50%;
    padding: 0 10px 0 26px;
}


.title_list{
    display: flex;
    flex-wrap: wrap;
}
















  .main_wrap_fist{
      width: 100%;
      margin: 0 auto;
    background-color: #6a6a6a !important;
    color: #fff;
    padding: 6px 0px 6px 5px;
    font-size: 16px;
    text-decoration: none;
    text-transform: capitalize;
    display: flex;
    min-height: 50px;
  }

  .imgicon{
    width: 56px;
    text-align: center;
  }

  .icon-heading{
      display: flex;
      align-items: center;
      
      
  }
  .heading{
    text-align: center;
 
  }

  .ppp{
      display: flex;
      justify-content: end;
      align-items: center;
      color: white;
  }

  .textp{
      font-weight: bold;
  }

  .damge_data{
      /* width: 96%; */
      /* margin: 0 auto; */
      /* border: 1px solid red; */
  }

  .non_{
      background-color: #edfdf1;
      border-bottom: 1px solid #EAEDEF;

   
  }
  .hedd{
    border-bottom: 1px solid #EAEDEF;  
    border-left: 1px solid #EAEDEF;  
    border-right: 1px dashed #c7c1bb;
    
  }

  .report-issue-point {
    border: 1px solid #EAEDEF;
    color: #518ecb;
    /* margin: 5px 0 0; */
    margin: 0 auto;
    font-size: 13px;
    font-weight: 400;
    width: 50%;
    /* padding: 6px */

    border-radius: 5px;
    box-shadow: inset 0 0 2px #ddd;
    display: inline-block;
    cursor: pointer;
}

.crosbtnqq{
position: absolute;
z-index: 1000;
left: 20px;
top: 50px;
font-weight: bold;
color: white;
}

.icon_n{
    color: white;
}

.sub_heading{
    border-top: 1px solid #dfe1e5;
    border-bottom: 1px solid #dfe1e5;
    background-color: #F0F0F0 !important;
    color: #2E2E2E;
    font-weight: 700;
   
    font-size: 16px;
    padding: 13px 10px 13px 20px;
}

.multiple{
    display: flex;
    justify-content: space-between;
}

.row_f{
    /* border: 1px solid rgb(88, 40, 201); */
    margin-right: 0px !important;
    margin-left: 0px !important;
}



/*<link href="https://fonts.googleapis.com/css?family=Maven+Pro:400,500,700,900" rel="stylesheet">
*/



@media screen and (max-width:992px) {
	.cars_th{
		position: unset !important;
		width: auto !important;
	}
}






.form-control::-webkit-input-placeholder { color: #eee; }  /* WebKit, Blink, Edge */
.form-control:-moz-placeholder { color: #eee; }  /* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder { color: #eee; }  /* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder { color: #eee; }  /* Internet Explorer 10-11 */
.form-control::-ms-input-placeholder { color: #eee; }  /* Microsoft Edge */




.cfl_header.navbar-light .navbar-nav .active>.nav-link, 
.cfl_header.navbar-light .navbar-nav .nav-link.active, 
.cfl_header.navbar-light .navbar-nav .nav-link.show, 
.cfl_header.navbar-light .navbar-nav .show>.nav-link ,
.cfl_header.navbar-light .navbar-nav .nav-link {
	color: #096c59;
    border-bottom: 3px solid transparent;
    /*padding-bottom: 6px;*/
    padding: 6px 0 5px 0;
    line-height: 1;
    transition: .3s border-color ease-out;
    white-space: nowrap;
    font-size: 16px;
    padding-right: 0;
    padding-left: 0;
}
.cfl_header.navbar-light .navbar-nav .nav-link:focus, 
.cfl_header.navbar-light .navbar-nav .nav-link:hover {
	border-bottom-color: #096c59;
}
.cfl_header.navbar-light .navbar-nav li.nav-item:not(:first-of-type) {
	margin: 0 0 0 1rem;
}
.cfl_header.navbar-light .navbar-nav li .btn-fancy {
	margin: 3px 0 0 0;
}
.btn-fancy {
	padding: .375rem 1.4rem !important;
	background-color: #096c59;
	background-image: linear-gradient(0deg, #096c59, #13b092);
	border: none;
	outline: none;
}
.cfl_header.navbar-light .navbar-nav a.btn-fancy ,
.cfl_header.navbar-light .navbar-nav a.btn-fancy:hover {
	border-bottom: none;
}
.cfl_header.navbar-light {
	display: flex;
	height: 75px;
	align-items: center;
}
.cfl_header.navbar-light .navbar-brand {
	padding: 0;
	margin: 0 0 0 -1px;
	font-size: 1rem;
}
.cfl_header.navbar-light ul.navbar-nav {
 align-items: center;
}
.lead_banner {
	background-color:var(--primary);
	/* background-image: linear-gradient(106deg, #096c59, #13b092); */
	/*position: relative;*/
	padding: 30px 0;
	padding: 2.1875rem 0 0 0;
	color: #fff;
	height: auto;
}
.lead_banner .dost {
	color: #fff;
	font-weight: 700;
	font-size: 2.8rem;
	letter-spacing: -0.05rem;
	margin: 0 0 20px 0;
	line-height: 1;
}
/*.cars_th {
	background: url(../images/handshake.png) no-repeat;
	background-size: contain;
	position: relative;
	bottom: 0;
	left: -15%;
	z-index: 10;
	width: 833px;
	height: 340px;
	max-width: 100%;
	margin: -50px 0 0 0;
}*/
.cars_th {
	position: absolute;
	bottom: 0;
	left: -120px;
	z-index: 10;
	width: 700px;
}
.relative {
	position: relative;
}
.cars_th_wrap {
	min-height: 400px;
}
.lead_banner_form_wrap {
	/*background: #157a68;*/
	background: #fff;
	border-radius: 12px;
	padding: 20px 35px 25px 35px;
	color: #157a68;
	width: 430px;
	max-width: 100%;
	margin: 0 auto;	
}
.thin_title {
	font-weight: 400;
}
.text-small {
	font-size: 11px;
}
.lead_banner_form_wrap .thin_title {
	text-align: left;
	/*padding: .85rem 1rem;*/
	padding: .85rem 0 5px 0;
	color: #157a68;
}
.lead_banner_form_wrap p {
	color: #fff;
	font-size: 12px;
	line-height: 1.3;
	
}
.lead_banner_pad {
	
}
.btn-submit {
	/*padding: .8rem 1rem !important;
	background-color: #096c59;
	background-image: linear-gradient(0deg, #096c59, #13b092);
	border: none;
	outline: none;
	color: #fff;
	font-weight: 500;
	text-transform: uppercase;
	border-radius: 0 0 12px 12px;
	font-size: 18px;
	letter-spacing: 0.02rem;
	border: 1px solid #10987e;*/
	border-radius: .3125rem;
	font-size: 1.125rem;
	height: auto;
	width: 100%;
	text-align: left;
	background: #ffd700;
}
/*.lead_banner_form_wrap select.form-control {
	border-radius: 0;
	height: 46px;
	background: #157a68;
	background : url("../images/arrow_down.png") no-repeat right 10px center #157a68;
	color: #fff;
}*/



.lead_banner_form_wrap .form-control {
	background: var(--primary);
	color: #fff;
	border-radius: 0;
}


.lead_banner_form_wrap select.form-control {
	outline : none;
    overflow : hidden;
    text-indent : 0.01px;
    text-overflow : '';
    /* background : url("../images/arrow_down.png") no-repeat right 10px center #157a68; */
    background-size: 18px;

    -webkit-appearance: none;
         -o-appearance: none;
            appearance: none;
}
.lead_banner_form_wrap select.form-control::-ms-expand {
    display: none;
}







.asdd {
	border-radius: .3125rem;
	font-size: 1.125rem;
	height: auto;
	width: 100%;
	text-align: left;
	background: #ffd700;
}
.lead_banner_form_wrap ol {
	margin: 0;
	padding: 0 0 0 12px;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.35;
	font-family: 'Roboto', sans-serif;
}
.lead_banner_form_wrap ol li {
	margin: 0 0 12px 0;
}
.btn-submit {
	font-weight: 700;
	font-size: 1.125rem;
	color: #3b424b;
	text-transform: capitalize;
	padding: 1.15rem .9375rem 1.15rem .9375rem;
	transition: all .2s ease;
	transition: all 450ms cubic-bezier(.23, 1, .32, 1) 0ms;
}
.btn-submit:hover {
	background-color: #e6c200;
}
.text-yellow {
	color: #ffd600;
}
.btn-submit i {
	float: right;
	margin: 4px 5px 4px 0;
}
/*FOOTER*/
.main_footer {
	background: #363636;
	border-top: 5px solid #096c59;
	font-size: 1em;
	color: #fff;
	padding: 60px 0;
}
.main_footer .footerBranding {
	float: left;
	width: 33.33333333%;
}
.main_footer .footerNav {
	left: 25%;
	width: 25%;
	position: relative;
	float: left;
}
.main_footer .footerBranding ,
.main_footer .footerNav ,
.main_footer .footerSocialLinks {
	padding-left: .9375rem;
	padding-right: .9375rem;
}
.main_footer .footerSocialLinks {
	margin-bottom: 20px;
	left: 25%;
	width: 16.666666666666664%;
	float: left;
	position: relative;
}
.main_footer .footerBranding .footerLogo {
    max-width: 210px;
    margin-bottom: 10px;
}
.main_footer .footerBranding .footerLogo svg,
.main_footer .footerBranding .footerLogo img {
	width: auto;
	max-width: 100%;
}
.main_footer .footerLogoLink {
    height: 29px;
}
.main_footer .footerBranding .footerBrandingLine {
	margin-bottom: 30px;
	font-weight: bold;
}
.main_footer a {
	color: #fff;
	transition: .15s ease-out opacity;
}
.main_footer .footerBranding .footerBrandingItem {
	margin-bottom: .3125rem;
    display: flex;
}
.main_footer .footerBranding .footerBrandingItem .icon,
.main_footer .footerBranding .footerBrandingItem i, 
.main_footer .footerBranding .footerWorkingHours .icon ,
.main_footer .footerBranding .footerWorkingHours i {
	margin-right: 10px;
}
.main_footer .footerBranding .footerWorkingHours span {
	font-weight: normal;
}
.main_footer .footerNav {
	font-size: 1rem;
}
.main_footer .footerNavLeft ,
.main_footer .footerNavRight {
	list-style-type: none;
	margin: 0;
	padding: 0;
}
.main_footer .footerSocialIcon {
	display: block;
    min-height: 24px;
    margin-bottom: 14px;
} 
.main_footer .footerNavItem {
    display: block;
    min-height: 24px;
    margin-bottom: 14px;
}
.main_footer .footerSocialLinks .footerSocialLink {
	display: block;
	height: 24px;
}
.main_footer .footerSocialLinks .footerSocialLink .icon ,
.main_footer .footerSocialLinks .footerSocialLink i {
    fill: #fff;
    margin-right: 10px;
}
.main_footer .footerCopyright {
	margin-top: 15px;
}
.main_footer .container {
	max-width: 1170px;
	/*padding-left: .9375rem;
    padding-right: .9375rem;*/
    padding-left: 0;
    padding-right: 0;
}
.main_footer .footerSocialLinks .footerSocialLink i {
	font-size: 18px;
}
.text-p{
	font-size: 1rem;
	/*font-family: 'maven_pro_light300';*/
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
}
.cars_th_wrap ol {
	font-size: 18px;
	margin-bottom: 32px;
}

/*Loader*/
.lead_banner_form_wrap {
	position: relative;
}
.lead_banner_form_wrap .success_loader {
	display: block;
}
.lead_banner_form_wrap {
	overflow: hidden;	
}
.lead_banner_form_wrap .success_loader:before {
	position: absolute;
	top: 0; bottom: 0;
	right: 0; left: 0;
	width: 100%;
	height: 100%;
	margin: auto;
	background: rgba(0,0,0,.35);
}
.lead_banner_form_wrap .success_loader:after {
	content: "";
	/* background: rgba(0,0,0,.5) url(../images/loading.gif) no-repeat center ; */
	width: 64px;
	height: 64px;
	position: absolute;
	top: 0; bottom: 0;
	right: 0; left: 0;
	width: 100%;
	height: 100%;
	margin: auto;	
}



/*Checkbox*/
.checkbox_wrap {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 18px;
    cursor: pointer;
	/*font-size: .875rem;
	line-height: 1.31;
	font-weight: 500;*/
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
	padding-top: 3px;
	margin-top: 18px;
	font-size: 14px;
	line-height: 1.35;
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
}

/* Hide the browser's default checkbox */
.checkbox_wrap input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 6px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
	border: 2px solid #157a68;
	border-radius: 5px;
}
/* On mouse-over, add a grey background color */
.checkbox_wrap:hover input ~ .checkmark {
    background-color: #ccc;
}
/* When the checkbox is checked, add a blue background */
.checkbox_wrap input:checked ~ .checkmark {
    background-color: #157a68;
    border-radius: 5px;
	border: 1px solid #fff;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
/* Show the checkmark when checked */
.checkbox_wrap input:checked ~ .checkmark:after {
    display: block;
}
/* Style the checkmark/indicator */
.checkbox_wrap .checkmark:after {
    left: 10px;
    top: 6px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.thankyou_message {
	font-family: Maven Pro,sans-serif;
	color: #fff;
	text-align: center;
	position: absolute;
	top: 0; bottom: 0;
	right: 0; left: 0;
	width: 100%;
	height: 100%;
	margin: auto;
	background: rgba(0,0,0,.925);
	z-index: 10;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	padding: 2rem 2rem;
}
.thankyou_message h4 {
	margin: 0 0 1	0px 0;
	font-size: 24px;
	line-height: 1.4;
}
.thankyou_message p {
	font-size: 18px;
}
.thankyou_message i {
	font-size: 48px;
	margin-bottom: .5rem;
}
.help-block{
	/*color: #ffd600;*/
	color: #f00;
	display: block;
	width: 100%;
	clear: both;
}
#termsConditions-error{
	margin-top: 10px;
    display: block;
    font-weight: 400;
    font-size: 16px;
}
.h-w{
	margin-top: 25px;
}
.lin{
	    margin-bottom: 7px;
}
.levels_wrap {
	margin: 0;
	color: #fff;
}
.level_line {
	padding: 0 0 8px 0;
	margin: 8px 0;
	border-bottom: 1px solid #323232;
}
.level_line .level_line_icon {
	width: 50px;
	margin: 5px 10px 0 0;
	float: left;
}
.level_line .level_line_icon figure {
	position: relative;
	width: 50px;
	height: 50px;
	overflow: hidden;
	text-align: center;
	background: transparent;
	color: #fff;
	margin: 0;
}
.level_line .level_line_icon figure figcaption {
	position: relative;
	top: 0;
	width: 100%;
	font-size: 14px;
	font-weight: 500;	
	background: transparent;
	padding: 2px 0;
	color: #ddd;
}
.level_line .level_line_icon figure strong {
	font-size: 24px;
	font-weight: 700;
	line-height: 1;
}
.level_line .level_line_content {
	float: right;
	width: calc(100% - 65px);
	color: #fff;
	font-size: .9rem;
	text-align: left;
	display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 60px;
    flex-direction: column;
}
.level_line.prize figure svg ,
.level_line.prize figure img {
	width: 30px;
	height: auto;
}
.level_line.prize {
	border-bottom: none;
}
.level_line.prize ol {
	display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 13px;
    line-height: 1.4;
    margin: 15px 0 15px 15px;
    padding: 0;
}
.level_line.prize ol li {
	margin: 0 0 10px 0;
	padding: 0;
	text-align: left;
}
.thankyou_message_close_button {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 10;
}
.thankyou_message_close_button img {
	width: 30px;
	height: 30px;
	cursor: pointer;
}

ul.h-i-w {
	font-size: 1rem;
	margin: 0;
	margin-bottom: 32px;
	padding: 0;
	list-style-type: none;
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
}
ul.h-i-w li {
	margin: 0 0 12px 0;
	padding: 0;
	list-style-type: none;
	display: block;
}
ul.h-i-w li i {
	display: inline-block;
	width: 32px;
	height: 32px;
	background: #0a725e;
	border-radius: 50px;
	color: #fff;
	line-height: 32px;
	font-weight: 500;
	font-size: 18px;
	margin: 0 12px 0 0;
	text-align: center;
	font-style: normal;
	float: left;
}
ul.h-i-w li span {
	float: left;
	width: calc(100% - 60px);
}




@media (min-width: 992px) {
	.cfl_header.navbar-light .navbar-nav .active>.nav-link {
	    border-bottom-color: #096c59;
	}
}


.button.small {
    font-size: 1rem;
    padding: 1rem;
}
.button.primary, .button.primary:visited {
    background-color: gold;
}
.button.primary {
    color: #3b424b;
}
.button.primary:hover {
    background-color: #ffdb1a;
}
.button {
    border: none;
    border-radius: .3125rem;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(0,0,0,.25);
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all .45s cubic-bezier(.23,1,.32,1) 0ms;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    font-weight: 500;
    padding: 1.25rem .9375rem 1.3125rem;
    line-height: 1.15;
}

.button.withIcon .buttonText {
	 flex: 1 1;
	    text-align: left;
	    margin-right: 1rem;
	    font-family: Maven Pro,sans-serif;
	    font-size: 1.125rem;
	    font-weight: 700;
	    white-space: nowrap;
	}

.cfl_header.navbar-light ul.navbar-nav li.cta a:hover {
	text-decoration: none;
}



/*DOST PROGRAM URDU*/
.lead_banner.has_urdu {
	direction: rtl;
	text-align: right;
	font-family: 'Markazi Text', serif;
	font-weight: 400;
}
.lead_banner.has_urdu .lead_banner_form_wrap ol {
	list-style-type: none;
	font-size: 16px;
    font-weight: 700;
}
.lead_banner.has_urdu .checkbox_wrap {
	font-size: 16px;
    font-weight: 700;
}
.has_urdu .stepbox  {
	text-align: right;
	border-radius: 0 0 0 50px;
}
.has_urdu .stepbox p {
	font-size: 16px;
	font-weight: 500;
}
.lead_banner.has_urdu ul.h-i-w li i {
	float: right;
	margin: 0 0 0 12px;
}
.lead_banner.has_urdu .cars_th {
	right: -120px;
}
.lead_banner.has_urdu .lead_banner_form_wrap select.form-control {
	/* background : url("../images/arrow_down.png") no-repeat left 10px center #157a68; */
	background-size: 18px;
}
.lead_banner.has_urdu .lead_banner_form_wrap .thin_title {
	text-align: right;
}
.lead_banner.has_urdu  ul.lang_list {
	margin: 0 0 0 20px 0;
	padding: 0;
	list-style-type: none;
	text-align: right;
}
.lead_banner.has_urdu  ul.lang_list li {
	margin: 0 0 0 1rem;
	padding: 0;
	list-style-type: none;
	display: inline-block;
	font-size: 13px;
	text-transform: capitalize;
	font-weight: 500;
}
.lead_banner.has_urdu  ul.lang_list li a {
	color: #fff;
	text-decoration: none;
	font-weight: 500;
}

ul.lang_list {
	margin: 0 0 20px 0;
	padding: 0;
	list-style-type: none;
	text-align: left;
}
ul.lang_list li {
	margin: 0 1rem 0 0;
	padding: 0;
	list-style-type: none;
	display: inline-block;
	font-size: 13px;
	text-transform: capitalize;
	font-weight: 500;
}
ul.lang_list li a {
	color: #fff;
	text-decoration: none;
	font-weight: 500;
}
ul.lang_list li.active a {
	padding-bottom: 3px;
	border-bottom: 1px solid #fff;
}



/*13-12-2019*/
.btn-yellow {
	padding-top: 0;
	position: relative;
	height: 52px;
	font-size: 16px;
	font-weight: bold;
	border-radius: 4px;
	text-align: center;
	box-shadow: inset 0px -5px 0px rgba(0,0,0,0.2);
	background-color: #FFD700;
	color: #3b424b;
	cursor: pointer;
	box-sizing: border-box;
	line-height: 34px;
	border: none;
	white-space: nowrap;
	padding-bottom: 2px;
	padding-left: calc(10px - 2px);
	padding-right: calc(10px - 2px);
	-webkit-user-select: none;
}
.btn-yellow:hover {
	box-shadow: inset 0px -3px 0px rgba(0,0,0,0.2);
    padding-left: calc(10px - 2px);
    padding-right: calc(10px - 2px);
}
.main_header {

}

/*Footer*/
.main_footer {
	background-color: #0f0d0b;
	color: #fff;
	padding-top: 2.4rem;
}
.container {
	max-width: 1030px;
}
.fbb {
	border-bottom: 1px solid #6e747b;
}
.fbt {
	border-top: 1px solid #6e747b;
}
.fw-700 {
	font-weight: 700 !important;
}
.fw-400 {
	font-weight: 400 !important;
}
.fw-500 {
	font-weight: 500 !important;
}

.main_footer a ,
.main_footer a:hover ,
.main_footer a:focus {
	color: #fff;
}
.main_footer h5 {
	font-size: 18px;
	line-height: 27px;
}
.info_icon_line {
    display: flex;
	align-items: center;
}
.info_icon_line p {
	margin: 0 0 0 10px;	
}
.info_icon_line_marg {
	margin-top: 30px;
}
.main_footer a.btn-yellow  {
	color: #3b424b;
}
ul.footer_nav {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
ul.footer_nav li {
	margin: 0 0 9px 0;
	padding: 0;
	list-style-type: none;
	display: block;
}
ul.footer_nav li a {
	color: #fff;
	text-decoration: none;
}
.fs-12 {
	font-size: 12px;
}
.fs-20 {
	font-size: 20px;
}
ul.footer_popular_brands {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
ul.footer_popular_brands li {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: inline-block;
	font-size: 12px;
	color: #9b9b9b;
}
ul.footer_popular_brands li a ,
ul.footer_popular_brands li a:hover {
	text-decoration: none;
	color: #9b9b9b;
}
ul.footer_social_list {
	margin: 0;
	padding: 0;
	list-style-type: none;
	text-align: right;
}
ul.footer_social_list li {
	margin: 0 0 0 6px;
	padding: 0;
	list-style-type: none;
	display: inline-block;
}
ul.footer_social_list li a {
	text-decoration: none;
}
ul.footer_social_list li img {
	width: 24px;
	height: 24px;
}
.copyrights {
	margin: 0;
	font-size: 14px;
	color: #fff;
	line-height: 21px;
}


.main_header .dropdown-menu {
	padding: 0;
}
.main_header .dropdown-menu .dropdown-item {
	padding: 16px;
}
.langTick {
	width: 14px;
	height: auto;
	float: right;
}
.main_header .navbar-nav .lang_drop.show .dropdown-toggle::after {
	    content: "\f077";
}
.main_header .navbar {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}
.main_header .nav-item.menudrop {
	padding: 1rem;
    padding-left: 2rem;
    margin-top: -2px;
}
.main_header  .navbar-brand {
	margin-right: 0;
	padding-top: 0;
}
.main_header .navMenuContent {
	font-size: 1.125em;
    position: fixed;
    top: -120%;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 9999999999;
    overflow-y: auto;
    transition: top .15s ease-out;
}
.navMenuContent.isHidden  {
	visibility: hidden;
	display: none;
}
.main_header .navMenuOpen .navMenuContent.show ,
.main_header .navMenuContent.show {
	visibility: visible;
    top: 0;
    transition-duration: .3s;
}

.main_header .navMenuContent .mobileHeader {
    display: flex;
    padding-top: 1.1rem;
    padding-left: 1.35rem;
    padding-right: 1.5rem;
    max-height: 60px;
     justify-content: space-between;
}
.headerNavigationList {
	
}
.headerNavigationList ul {
	margin: 0;
	padding-top: 1rem;
	padding-bottom: 1rem;
	padding-left: 0;
	padding-right: 0;
	list-style-type: none;
	border-bottom: 1px solid #eaeaea;
}
.headerNavigationList ul li {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: block;
}
.headerNavigationList ul li a {
	border-bottom: 3px solid transparent;
    padding-bottom: 6px;
    transition: border-color .3s ease-out;
    white-space: nowrap;
    display: block;
    width: 100%;
    line-height: 45px;
    padding-bottom: inherit!important;
    padding-left: .9375rem;
    padding-right: .9375rem;
    box-sizing: border-box;
    color: #434d51;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
}
.headerNavigationList ul li a:hover {
	border-color: #eaeaea;
}
.headerNavigationList_footerNav ul {
	margin: 0;
	padding-top: 1rem;
	padding-bottom: 1rem;
	padding-left: .9375rem;
    padding-right: .9375rem;
	list-style-type: none;
	border-bottom: 1px solid #eaeaea;	
}
.headerNavigationList_footerNav ul li {
	margin: 6px 0;
	padding: 0;
	list-style-type: none;
}
.headerNavigationList_footerNav ul li a {
	font-size: 16px;
	font-weight: 400;
	color: #434d51;
	text-decoration: none;
}
.navMenuContent .lang_drop {
	font-size: 14px;
	color: #434d51;
}
.navMenuContent .lang_drop a {
	color: #434d51;	
}
.navMenuContent ul.footer_social_list {
	text-align: left;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.desk_navLeft {
	margin-left: 32px;
	margin-top: 8px; 
}
.desk_navLeft .navbar-nav li.nav-item:not(:first-of-type) {
	margin-left: 1rem;
}
.desk_navRight .navbar-nav li.nav-item:first-child {
	padding: 1rem;
}
.desk_navRight .navbar-nav li.nav-item:nth-child(2) {
	padding: 1rem 0;
	margin-left: 1rem;
}
.isFixed {
	position: fixed;
    top: 0px;
    overflow: hidden;
    width: 100%;
}
.mobileHeader_navCloseBtn {

}




/*CHANGING PAGE DESIGN - 15-06-2021*/
.fw-400 {
	font-weight: 400!important;
}
.text-p.lead {
	font-size: 1.1rem;
}
table.dptable {
	text-transform: uppercase;
}
table.dptable thead th {
	background-color: var(--yellow);
	text-transform: uppercase;
	text-align: center;
	color: black;
}
.table.dptable td, 
.table.dptable th {
	border:  1px solid #bed4cf;
	text-align: center;
}
.table.dptable td {
	background-color: var(--primary);
	color: #fff;
}
.newbanner {
	/* background: url(../images/banner-thumb.png) no-repeat; */
	background-size: contain;
	position: relative;
	bottom: 0;
	left: -15%;
	z-index: 10;
	width: 833px;
	height: 340px;
	max-width: 100%;
	margin: -50px 0 0 0;
}
.howitworks {
	background-color: #e9ecee;
	padding: 60px 0 60px 0;
}
.stepbox {
	background-color: #fff;
	color: #148771;
	padding: 30px;
	border-radius: 0 0 50px 0;
	box-shadow: 1px 1px 10px 1px rgba(0,0,0,.1);
    display: flex;
    flex-direction: column;
    height: calc(100% - 1rem);
    margin-bottom: 1rem;
}
.stepbox p {
    font-size: 13px;
    font-weight: 500;
}
.heightadjuster {
	height: 360px;
}
.rtl {
	direction: rtl!important;
}

.toaster-hide {
  display: none;
  opacity: none;
  visibility: hidden;
}
.toaster-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 100000;
  /* width: 20rem; */
  /* padding: 1rem; */
  /* border-top-left-radius: 10px; */
  /* border-bottom-left-radius: 10px; */
  border-radius: 8px;
  /* height: 2rem; */
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  max-width: 30rem;
  transition: ease-in-out 0.5s;
}
.toaster-content-container {
  /* position: absolute; */
  /* top: 50; */
  /* right: 0; */
  /* z-index: 10000000000; */
  /* width: 20rem; */
  padding: 0.7rem 1rem 0.7rem 0.5rem;
  /* border-top-left-radius: 10px; */
  /* border-bottom-left-radius: 10px; */
  /* height: 2rem; */
  /* overflow: visible; */
  word-wrap: break-word;
}
.statusBar {
  display: flex;
  flex: 1 1;
  padding: 5px;
}
.toaster-heading {
  font-weight: bold;
  font-size: 1.2rem;
  color: #000;
  text-transform: capitalize;
}
.toaster-description {
  font-size: 0.9rem;
  color: #262626;
  text-transform: capitalize;
  /* margin-top: 0.3rem; */
}

.azaaza{
    display: flex;
    justify-content: flex-end !important;
}





* {
    outline: 0;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.w-full {
    width: 100%;
}
.text-white {
    color: #fff;
}
.bg-charcoal {
  
        background-color: var(--primary)  !important;
        

}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.flex {
    display: flex;
}
