.navmain {
  background-color: gray !important;
  z-index: 10000;
}

.unselected {
  color: var(--nav-item-unselect);
  text-decoration: none;
  font-weight: bold;
  font-size: 1.1rem;
  transition: ease-in-out 0.2s;
  letter-spacing: 2px;
  text-transform: capitalize;

}
.selected {
  color: var(--nav-item-select) !important;
  text-decoration: none !important;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 1.1rem;
  text-transform: capitalize;
}
.unselected:hover {
 color: var(--nav-item-select) !important;
  text-decoration: none;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 1.1rem;
}
.back {
  padding-left: 8%;
  width: 50%;
  justify-content: center;
  align-items: center;
}

.navbt {
  height: 100%;
  width: 100%;
}
.loginsignup {
  margin-right: 5%;
  width: 13rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* float: right; */
}
.Getstarted {
  border-radius: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 20rem;
  background-color: whitesmoke;
}
.singup {
  color: grey;
  font-weight: 500;
}
.navlogo {
  object-fit: contain;
  width: 5rem;
}




a:link { text-decoration: none;

}


a:visited { text-decoration: none;
 
}

a:hover { text-decoration: none; }


a:active { text-decoration: none; 
  /* color: #FEF200 ; */
}



a{

 
}






/* ---------------------------------- */
/* .effect5 {
  color: #fff ;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.0rem;
  display: inline-block;
  overflow: hidden;
}
.effect5:before, .effect5:after {
  right: 0;
  bottom: 0;
  background: orangered;
}
.effect5:before {
  width: 100%;
  height: 2px;
  transform: translateX(-100%);
}
.effect5:after {
  width: 2px;
  height: 100%;
  transform: translateY(100%);
}
.effect5 > span {
  display: block;
  padding: 10px;
}
.effect5 > span:before, .effect5 > span:after {
  left: 0;
  top: 0;
  background: orangered;
}
.effect5 > span:before {
  width: 100%;
  height: 2px;
  transform: translateX(100%);
}
.effect5 > span:after {
  width: 2px;
  height: 100%;
  transform: translateY(-100%);
}
.effect5:hover:before, .effect5:hover:after, .effect5:hover > span:before, .effect5:hover > span:after {
  transform: translate(0, 0);
}
.effect5, .effect5 > span {
  position: relative;
   text-decoration: none;
  line-height: 24px;
  color: #fff ;
   font-weight: 500;
  font-size: 1.0rem;
   width: 50rem;
}
.effect5:before, .effect5:after, .effect5 > span:before, .effect5 > span:after {
  content: '';
  position: absolute;
  transition: transform .5s ease;
}
.effect5:hover{
color: #fff ;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.0rem;} */
