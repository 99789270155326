.main_Wrap_ss{
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 8px #1d1d1b40;
    min-height: 460px;
    padding: 48px;
    text-align: left;
    border: 1px solid red;
}

.inp_main{
    width: 100%;
    display: flex;
    border: 2px solid #afada3;
}

.city_list{
        background-color: var(--generic-bg-color);
    border: 2px solid #afada3;
    border-radius: 0 0 4px 4px;
    border-top: 0;
    box-shadow: 1px 1px 1px #e2e1d9, -1px 1px 1px #e2e1d9, 0 1px 1px #e2e1d9;
    left: 0;
    /* height: 300px; */
    /* /* min-height: 300px; */
    max-height: 300px; 
    
    top: 100%;
    width: 100%;
    overflow: auto;
    text-align: left;
}
.enter{
    /* background-color: #178971 !important; */
    color: blue;
    /* padding: 2px; */
    text-decoration: underline;
}

.lii{
    list-style: none;
  line-height: 12px;
  padding: 5px;
    font-weight: 2rem;
    list-style-position:inside;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lii:hover{
    /* background-color: #afada3; */
    cursor: pointer;
}

.kkkkkkkk{
    /* padding: 50px; */
    padding-bottom: 100px;
}

.inp_inp{
    -webkit-appearance: none;
    background-color: var(--generic-bg-color);
    border: 2px solid var(--generic-border-color);
    border-radius: 4px;
    color: var(--generic-text-color);
    font-size: 16px;
    font-weight: 400;
    height: 48px;
    margin: 0;
    outline: none;
    padding: 0 16px;
    text-align: left;
    width: 100%;

}


.srech_{
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}