

.Dmage_wrap{
    width: 100%;
    /* border: 1px solid red; */

}


.damage_img{
    width: 13rem;
    height: 13rem;
    border-radius: 10px;
  
}
.damage_img_a{
    width: 13rem;
    height: 13rem;
    border-radius: 10px;
    background-color: #F2F2F2;
}

.Dmage_inside{
    display: flex;
    flex-wrap: wrap;
}


.retake_btn{
    position: absolute;
    z-index: 1000;
    /* border: 1px solid red; */
    color: white;
    /* top: px; */
    left: 5px;
    top: 107px;
    font-size: 2rem;
}

.edit_icon{
    border: 1px solid gray;
    position: absolute;
    background-color: white;
    border-radius: 50%;
    padding: 0.6rem;
    right: -5px;
    top: -5px;
    font-size: 1.5rem;

}

.imge_wrap{
    position: relative;
}


.no_pic_div{
    background-color: #F2F2F2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.add_text{
    color: blue;
}