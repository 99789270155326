@import "~react-image-gallery/styles/css/image-gallery.css";
.counter_main_container {
  background: var(--info);
  width: 80%;
  padding: 2rem;
  padding-bottom: 1.5rem;
  border-radius: 5px;
  position: relative;
}
.slider{
  position: relative;
  width: 100%;
  height:   45% !important;
  overflow: hidden;
}
.counter_text {
  font-size: 2rem;
  font-weight: 700;
  color: var(--secondary);
  /* margin: 0.2vw; */
}

.counter_buy_now_text {
  font-size: 0.9rem;
  font-weight: 650;
  color: var(--white);
  text-transform: uppercase;
}

.counter_place_bid_cont_text {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--white);
  text-transform: capitalize;
  margin: 5px;
}

.counter_time_title {
  text-transform: uppercase;
  font-size: 12px;
}

.viewtitle_underline {
  background: var(--secondary);
  width: 30%;
  padding: 2.5px;
  margin: 8px 0px;
}

.counter_total_bidder_cont {
  position: absolute;
  top: 0;
  left: 0;
}

.counter_buy_now_cont {
  background: var(--primary);
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  padding: 0.2rem;
  border-radius: 0.2rem;
}

.counter_place_bid_cont {
  background: var(--secondary);
  border-radius: 0.5rem;
  padding: 10px;
}
.counter_buy_now_cont_ {
  background: var(--white);
  border: 1.5px solid var(--primaryDark);
  border-radius: 0.5rem;
  transition: 0.3s ease-out;
  cursor: pointer;
}
img {
    max-width: unset !important;
}
.counter_buy_now_cont_:hover {
  /* background: #fae9e7; */
  box-shadow: -2px 2px 12px -3px rgba(136, 39, 29, 0.8);
  -webkit-box-shadow: -2px 2px 12px -3px rgba(136, 39, 29, 0.8);
  -moz-box-shadow: -2px 2px 12px -3px rgba(136, 39, 29, 0.8);
}

.place_bid_input_cont {
  position: relative;
  z-index: 0;
  display: flex;
  flex: 1 1;
  width: 100%;
  /* background: red; */
  border-radius: 5px;
  border: 1px solid var(--secondary);
  /* padding: 0 0.2vw; */
  overflow: hidden;
  margin: 1vw 0px;
  box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.75);
}

.place_bid_input_cont > input {
  display: flex;
  /* flex: 1; */
  width: 95%;
  border: none;
  outline: none;
  background: none;
  margin: 0 0.5vw;
  /* font-family: var(--font); */
}

.place_bid_input_cont > input:focus {
  outline: none;
}

.data_row_item {
  box-shadow: -3px 3px 9px -4px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: -3px 3px 9px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -3px 3px 9px -4px rgba(0, 0, 0, 0.5);
  border-radius: 0.2rem;
  padding: 0.4rem 1vw;
  margin: 0.4rem 1vw;
}

.data_row_item_icon {
  color: var(--primary);
  font-size: 1.2rem;
  margin: 0.2rem;
  /* padding: 0.2rem  ; */
  text-transform: capitalize;
  text-align: center;
}

.data_row_item_title {
  font-size: 1rem;
  color: var(--secondary);
}

.data_row_item_body {
  color: var(--secondary);
  font-weight: 600;
}
.bid_animating_car_cont {
  /* width: 100%; */
  overflow: hidden;
}
.bid_animating_car {
  z-index: 1;
  width: 40px;
  position: absolute;
  left: -50px;
  animation: bid_animating_car_animation 3s;
}
@keyframes bid_animating_car_animation {
  0% {
    left: -50px;
  }
  100% {
    left: calc(100% + 50px);
  }
}
.ImageGallery_originalClass {
  width: 20px;
}
/* ------------------------------------------------ */

.productFeaturesList {
  flex-wrap: wrap;
  width: 90%;
  border: 1px solid #c3c3c3;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.productFeaturesList > span {
  /* background-color: yellow; */
  margin: 2px 5px;
  text-transform: capitalize;
}

.incrementDecrementBtn {
  padding: 0.09rem 0.35rem;
  /* background: #f5f5f5; */
  /* border-bottom: 0.5px solid #c9cbd8; */
  cursor: pointer;
  transition: 0.15s ease-in-out;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.incrementDecrementBtn:hover {
  background: var(--infoPlus);
  /* border-bottom: 0.5px solid #73737c; */
}

.ViewMain {
  padding: 1%;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20%;
}

.leftview {
  display: flex;
  flex-direction: column;
  max-width: 50vw;
}

.rightview {
  /* margin-left: 1%; */
  align-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tabstyle {
  border-bottom: 1.5px solid #ccc;
  /* background-color: beige; */
}

.liststyle {
  border-bottom: 1.5px solid #ccc;
  background-color: whitesmoke;
}

.Bidingtitle {
  margin-top: 2%;
  font-size: 0.9rem;
  font-weight: 600;
}

.cartitle {
  width: 100%;
  /* background-color: antiquewhite; */
}

.cardetail {
  border-radius: 10px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  width: 100%;
  /* background-color: red; */
  align-items: center;
}

.cardetailmain {
  width: 100%;
  /* background: red; */
  padding: 1rem;
  /* background-color: whitesmoke;
  border-radius: 2rem;
  padding: 5%;
  margin: 2%; */
}

.viewtitle {
  font-size: 2.5rem;
  font-weight: 800;
  color: var(--secondary);
  line-height: 2.5rem;
}

.viewtext1 {
  font-size: 1rem;
  font-weight: 500;
  margin: 0px;
}

.bidingbar {
  border-bottom: 1.5px solid #ccc;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1%;
  padding: 1%;
}

.btnbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.bidingbar2 {
  border-bottom: 1.5px solid #ccc;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40%;
  margin-top: 1%;
  padding: 2%;
}

.bidingbartext {
  color: black;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

.bidingbutton {
  border-radius: 5px;
  /* padding: 2%; */
  margin: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 25%;
  background-color: grey;
  color: white;
}

.sellertitle {
  border-bottom: 1px solid #ccc;
}

.timermain {
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: space-around;
  align-self: center;
  margin-bottom: 5%;
  margin-top: 2%;
}

.timerMobile {
  display: none;
}

/* ================clock timer============ */

#clockdiv {
  font-family: sans-serif;
  color: #fff;
  display: flex;
  font-weight: 100;
  text-align: center;
  font-size: 40px;
  width: 100%;
}

#clockdiv > div {
  padding: 15px;
  border-radius: 10px;
  background: whitesmoke;
  display: inline-block;
  margin: 3%;
  width: 80%;
  height: 120px;
}

#clockdiv div > span {
  padding: 20px;
  border-radius: 5px;
  background: grey;
  display: inline-block;
}

.smalltext {
  padding-top: 10px;
  font-size: 16px;
  color: black;
}

.mobiletabbar {
  display: none;
}

.mobileTabBar {
  display: none;
}

@media only screen and (max-width: 991px) {
  .desktopbar {
    display: none;
  }
  .mobileTabBar {
    display: inline-block;
    width: 100%;
    overflow-x: scroll;
  }
}

@media only screen and (max-width: 479px) {
  .counter_text {
    font-size: 1.5rem;
    font-weight: 650;
  }
  .counter_time_title {
    font-size: 8px;
  }
  .counter_place_bid_cont_text {
    font-size: 0.9rem;
    font-weight: 580;
   }
  .counter_buy_now_text {
    font-size: 0.8rem;
    font-weight: 620;
  }
  .viewtitle {
    font-size: 2rem;
    font-weight: 760;
    line-height: 2rem;
  }
}
