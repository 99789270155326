

.landingmain_banner_cont {
  background-image: url(../../assets//images//city.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: var(--primary);
}
/* .landingmain_banner_body_wrapper {
} */
.landingmain_banner_left_cont {
  flex: 1;
}
.landingmain_banner_left_cont > .viewtitle {
  color: var(--info);
  width: 70%;
}
.landingmain_banner_right_cont {
  flex: 1;
}
/*  */
.offer_card_row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.offer_card {
  margin: 0.5rem;
  padding: 1rem;
  display: block;
  border-radius: 0.5rem;
  width: 25rem;
  height: 15rem;
}
.offer_card_title {
  margin: 0.5rem 0;
  font-weight: bold;
  color: var(--primary);
}
.offer_card_title_icon {
  font-size: 2.5rem;
}
.offer_card_title_text {
  font-size: 1.4rem;
}
.offer_card_description {
  margin: 0.5rem 0;
  font-size: 1.2rem;
}

/* small screen media query */
@media only screen and (max-width: 540px) {
  .landingmain_banner_left_cont {
    display: none;
  }
  .landingmain_banner_left_cont > .viewtitle {
    width: 95%;
  }
  .form_main_wrapper {
    width: 95%;
  }
  .banner_description {
    width: 95%;
  }
  /*  */
  .offer_card_row {
    justify-content: center;
    align-items: center;
  }
  .offer_card {
    display: block;
    border-radius: 0.5rem;
    width: 95%;
  }
}
/* bootstrap md breakpoint */
@media only screen and (max-width: 991px) {
  .landingmain_banner_left_cont > .viewtitle {
    width: 80%;
  }
  .form_main_wrapper {
    width: 80%;
  }
  .banner_description {
    width: 70%;
  }
  /*  */
  .offer_card_row {
    justify-content: center;
    /* align-items: center; */
  }
}
/* bootstrap md breakpoint */
@media only screen and (max-width: 350px) {
  .landingmain_banner_left_cont > .viewtitle {
    width: 95%;
  }
  .form_main_wrapper {
    width: 98%;
  }
  .banner_description {
    width: 95%;
  }
}
