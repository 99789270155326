.landingBody {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;

  /* background-color: aquamarine; */
  /* background-color: aqua; */
}
.topsection {
  display: flex;
  flex-direction: row;
  /* margin: 1%; */
  height: 400px;
}
.topsectionright {
  display: flex;
  flex-direction: row;
  width: 125%;
  /* background-color: red; */
}
.toprightimage {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  object-fit: contain;
  border-bottom-right-radius: 7rem;
  width: 100%;
  box-shadow: 2px 2px 14px -6px rgb(0 0 0 / 50%);
  -webkit-box-shadow: 2px 2px 14px -6px rgb(0 0 0 / 50%);
  -moz-box-shadow: 2px 2px 14px -6px rgba(0, 0, 0, 0.5);
}
.circleshape {
  border-radius: 100%;
  right: -56px;
  top: -28px;
  position: absolute;
  height: 200px;
  width: 200px;
  border: 30px solid #ccc;
  opacity: 0.2;
  z-index: -10;
}

.circleshape2 {
  border-radius: 100%;
  right: 141px;
  top: 150px;
  position: absolute;
  height: 80px;
  width: 80px;
  border: 50px solid #ccc;
  opacity: 0.2;
}
.abovetext {
  width: 100%;
  /* background-color: aqua; */
  /* left: 63px; */
  /* top: 127px; */
  /* position: absolute; */
  z-index: 10;
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fixedimg {
  /* background: red; */
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -10;
}
.toptext {
  color: black;
  font-size: 1.5rem;
}
.companylogo {
  object-fit: contain;
  width: 130px;
}
.topmiddleimage {
  border: 10px solid #f2f2f2;
  margin-left: 30%;
  margin-top: 20%;
  overflow: hidden;
  object-fit: contain;
  width: 200px;
  height: 200px;
}
.regbutton {
  border-radius: 0.7rem;
  display: flex;
  justify-content: center;
  background-color: #f2f2f2;
  align-items: center;
  width: 150px;
  height: 50px;
  color: black;
}
.regbutton:hover {
  border-radius: 0.7rem;
  display: flex;
  justify-content: center;
  background-color: #f2f2f2;
  align-items: center;
  width: 150px;
  height: 50px;
  color: black;
}
.framecar {
  top: 106px;
  left: 133px;
  position: absolute;
  z-index: 0;
  object-fit: contain;
  width: 250px;
}
.framecar2 {
  transform: rotate(3deg);
  top: 34px;
  left: 302px;
  position: absolute;
  z-index: 15;
  object-fit: contain;
  width: 180px;
  opacity: 0.7;
}
.carwraper {
  position: relative;
  width: 100%;
}
.searchwrapper {
  display: flex;
  margin-top: 1%;
  /* background-color: aqua; */
  flex-direction: "row";
  justify-content: "space-between";
  height: 15vh !important;

  width: 100%;
}
.searchleft {
  -webkit-clip-path: polygon(0% 0, 100% 0, 75% 120%, 0 100%);
  clip-path: polygon(0% 0, 100% 0, 75% 120%, 0 100%);
  display: flex;
  height: 100%;
  width: 45%;
  flex-direction: column;
  background-color: grey;
  /* background-color: green !important; */
}
.searchright {
  flex-direction: row;
  display: flex;
  -webkit-clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
  clip-path: polygon(9% 0, 100% 0, 100% 100%, 0 100%);
  display: flex;
  height: 100%;
  width: 100%;
  /* padding-top:5% ; */
  padding-left: 5%;
  padding-bottom: 2%;
  justify-content: center;
  align-items: center;
  background-color: grey;
}
.searchcol {
  justify-content: center;
  margin: 1%;
  flex-direction: column;
  display: flex;
  width: 20%;
  height: 15vh;
}
.containerbox {
  border: 5px #ccc solid;
  width: 100%;
  height: 100%;
  background-color: #ccc !important;
}
.searchbutton {
  /* padding: 2%; */
  border: 5px solid #ccc;
  height: 100%;
  width: 80%;
  background-color: #ccc;
  display: flex;
  /* align-self: center; */
  justify-content: center;
  align-items: center;
}
.searchtext {
  margin: 2px;
  color: whitesmoke;
  font-size: 0.9rem;
  font-weight: 500;
}
.Servicescontainer {
  object-fit: contain;
  padding: 1%;
  height: 30vh;
  /* overflow: hidden; */
  margin-top: 2%;
  flex-direction: row;
  justify-content: space-evenly;
  display: flex;
  /* background-color: grey; */
}
.cardstyle {
  /* margin: 1%; */
  border-radius: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
  width: 20rem;
  height: 15rem;
}
.cardimage {
  float: right;
  object-fit: contain;
  width: 120px;
}

.sliderimage {
  border-radius: 0% 10% 10% 0%;
  background-color: grey;
  object-fit: fill;
  height: 100%;
  width: 80%;
  -webkit-clip-path: polygon(18% 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(18% 0, 100% 0, 100% 100%, 0 100%);
}
.sliderwrap {
  border-radius: 10%;
  height: 25vh;
  width: 70%;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  background-color: white;
}
.slidertextwrap {
  padding: 5%;
  border-radius: 10% 0% 0% 10%;
  background-color: grey;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
  clip-path: polygon(0% 0, 100% 0, 74% 100%, 0 100%);
  width: 50%;
  overflow: hidden;
  /* margin: 2%; */
  font-size: 0.9rem;
  font-weight: bold;
}

.SliderMain {
  margin-top: 3%;
  height: 40vh;
  background-color: #f2f2f2;
}
.sliderheader {
  padding-top: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  justify-content: center;
}
.sliderdisplay {
  width: 134% !important;
  height: 25vh;
}
.Workmain {
  display: flex;
  flex-direction: column;
  height: 60vh;
}
.workwrap {
  flex-direction: row;
  display: flex;
  margin-left: 30px;
  margin-right: 30px;
}
.animationcontainer {
  height: 20vh;
}

/* =================mobile screen ==================== */
@media only screen and (max-width: 768px) {
  .landingBody {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    /* overflow-x: hidden; */

    /* background-color: aquamarine; */
    /* background-color: aqua; */
  }
  .topsection {
    display: flex;
    flex-direction: row;
    /* margin: 1%; */
    height: 400px;
  }
  .topsectionright {
    display: flex;
    flex-direction: row;
    width: 125%;
    /* background-color: red; */
  }
  .toprightimage {
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    object-fit: contain;
    border-bottom-right-radius: 7rem;
    width: 100%;
    box-shadow: 2px 2px 14px -6px rgb(0 0 0 / 50%);
    -webkit-box-shadow: 2px 2px 14px -6px rgb(0 0 0 / 50%);
    -moz-box-shadow: 2px 2px 14px -6px rgba(0, 0, 0, 0.5);
  }
  .circleshape {
    border-radius: 100%;
    right: -56px;
    top: -28px;
    position: absolute;
    height: 200px;
    width: 200px;
    border: 30px solid #ccc;
    opacity: 0.2;
  }

  .circleshape2 {
    border-radius: 100%;
    right: 141px;
    top: 150px;
    position: absolute;
    height: 80px;
    width: 80px;
    border: 50px solid #ccc;
    opacity: 0.2;
  }
  .abovetext {
    width: 100%;
    /* background-color: aqua; */
    /* left: 63px; */
    /* top: 127px; */
    /* position: absolute; */
    z-index: 10;
    height: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .fixedimg {
    background: red;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -10;
  }
  .toptext {
    color: black;
    font-size: 1.5rem;
  }
  .companylogo {
    object-fit: contain;
    width: 130px;
  }
  .topmiddleimage {
    border: 10px solid #f2f2f2;
    margin-left: 30%;
    margin-top: 20%;
    overflow: hidden;
    object-fit: contain;
    width: 200px;
    height: 200px;
  }
  .regbutton {
    color: black;
    border-radius: 0.7rem;
    display: flex;
    justify-content: center;
    background-color: #f2f2f2;
    align-items: center;
    width: 150px;
    height: 50px;
  }
  .regbutton:hover {
    color: black;
    border-radius: 0.7rem;
    display: flex;
    justify-content: center;
    background-color: #f2f2f2;
    align-items: center;
    width: 150px;
    height: 50px;
  }
  .framecar {
    top: 106px;
    left: 133px;
    position: absolute;
    z-index: 20;
    object-fit: contain;
    width: 250px;
  }
  .framecar2 {
    transform: rotate(3deg);
    top: 34px;
    left: 302px;
    position: absolute;
    z-index: 15;
    object-fit: contain;
    width: 180px;
    opacity: 0.7;
  }
  .carwraper {
    position: relative;
    width: 100%;
  }
  .searchwrapper {
    display: flex;
    margin-top: 1%;
    /* background-color: aqua; */
    flex-direction: column;
    justify-content: "space-between";
    height: 20% !important;

    width: 100%;
  }
  .searchleft {
    -webkit-clip-path: polygon(0% 0, 100% 0, 75% 120%, 0 100%);
    clip-path: polygon(0% 0, 100% 0, 75% 120%, 0 100%);
    display: flex;
    height: 45%;
    width: 100%;
    flex-direction: row;
    background-color: transparent;
    /* background-color: green !important; */
  }
  .searchright {
    margin-top: 5%;
    /* margin: 0% auto; */
    flex-direction: row;
    /* -webkit-clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%); */
    -webkit-clip-path: polygon(0% 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0% 0, 100% 0, 100% 100%, 0 100%);
    height: 30%;
    width: 100%;
  }
  .searchcol {
    justify-content: space-between;
    /* margin: 1%; */
    flex-direction: column;
    display: flex;
    width: 27%;
    height: 45%;
  }
  .containerbox {
    border: 5px #ccc solid;
    width: 100%;
    height: 100%;
    background-color: whitesmoke !important;
  }
  .searchbutton {
    /* padding: 2%; */
    border: 5px solid #ccc;
    height: 100%;
    width: 80%;
    background-color: aliceblue;
    display: flex;
    /* align-self: center; */
    justify-content: center;
    align-items: center;
  }
  .searchtext {
    margin: 2px;
    color: whitesmoke;
    font-size: 0.9rem;
    font-weight: 500;
  }

  /* =================services card csss================== */
  .Servicescontainer {
    object-fit: contain;
    padding: 1%;
    height: 36%;
    /* overflow: hidden; */
    margin-top: 2%;
    flex-direction: column;
    justify-content: space-around;
    display: flex;
    align-items: center;
    /* background-color: grey; */
  }
  .cardstyle {
    /* margin: 1%; */
    border-radius: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: whitesmoke;
    width: 79%;
    height: 31%;
  }
  .cardimage {
    float: right;
    object-fit: contain;
    width: 120px;
  }

  /* ===============slider ======================cssss  */
  .SliderMain {
    margin-top: 5%;
    height: 45vh;
    background-color: #f2f2f2;
  }
  .sliderdisplay {
    width: 134% !important;
    height: 25vh;
  }
  .sliderimage {
    border-radius: 0% 10% 10% 0%;
    background-color: grey;
    object-fit: fill;
    height: 100%;
    width: 80%;
    -webkit-clip-path: polygon(18% 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(18% 0, 100% 0, 100% 100%, 0 100%);
  }
  .sliderwrap {
    border-radius: 10%;
    height: 25vh;
    width: 70%;
    background-color: white;
  }
  .slidertextwrap {
    flex-direction: column;
    display: flex;
    padding: 5%;
    border-radius: 10% 0% 0% 10%;
    background-color: grey;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
    clip-path: polygon(0% 0, 100% 0, 74% 100%, 0 100%);
    width: 50%;
    overflow: hidden;
    /* margin: 2%; */
    font-size: 0.9rem;
    font-weight: bold;
  }

  .sliderheader {
    padding-top: 20px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    justify-content: center;
  }
  /* ====================how its work csss =================== */
  .workcontainer {
    flex-direction: column;
    display: flex;
    width: 100%;
    height: 80vh;
  }
  .workwrap {
    flex-direction: column;
    display: flex;
    margin-left: 30px;
    margin-right: 30px;
  }
  .animationcontainer {
    height: 30vh;
  }
}
