.Cardiv_wrap{
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    /* border: 1px solid red; */
    margin: 0 auto;
}



.mainDiv1{
    width: 100%;
   background-color: var(--primary);
   /* border: 1px solid red; */
   color: white;

}


.hedingcc1{
color: white;
font-weight: bold;
font-size: 2rem;
/* border: 1px solid white; */
}

.mdiv2{
    width: 100%;
    
}


.shimer-div{
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    /* border: 1px solid red; */
}


.shimer{
    width:48%;
    margin: 1.5rem auto;
}

.cardDiv{

    width:48%;
    margin: 1.5rem auto;
    /* border: 1px solid red; */
    padding: 5px 0px 4px 9px;
    
    box-shadow: -1px 0px 10px -5px rgba(69,68,68,0.5);
    -webkit-box-shadow: -1px 0px 10px -5px rgba(69,68,68,0.5);
    -moz-box-shadow: -1px 0px 10px -5px rgba(69,68,68,0.5);
   
}


.card_Div{
    /* border: 1px solid blue; */
    display: flex;
}


.fotDiv{
    width: 100%;
    /* border: 1px solid red; */
    display: flex;
flex: 1;
align-items: center;
flex-direction: row;
}


.fotDiv_last{
    display: flex;
}

.fot1{
    display: flex;
    flex-direction: row;
    /* border: 1px solid rgb(0, 255, 42); */
}


@media screen and (max-width: 534px) {
    

    .fotDiv_last{
        margin-top: 10px !important;
        display: flex;
        justify-content: flex-end;
    }
}

 @media screen and (max-width: 475px) {
  
.cardDiv{
    width: 100% !important;
}


} 

.car-model{
    font-size: 1.3rem;
}


.fueltype{
    font-size: 1.3rem;
    padding-left: 2px;
    display: flex;
    /* border:1px solid blue; */

}


.btntnext{
    width: 8rem;
    height: 2.5rem;
    border-radius: 0.5rem;
    display: inline-block;
    border: none;
    padding: 0px;
    margin: 0;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    background: none;
    background-color: var(--yellow);
    color: black;
}

.btntnext:hover{
    background-color:var(--yellow);
    color: var(--primary);
}

.date{
    /* border: 1px solid blue; */
    font-size: 1.2rem;
    display: block;
}
.showInterest_headingCont {
    color: var(--white);
    padding: 1rem;
  }
  .showInterest_heading {
    font-size: 2.5rem;
    line-height: 2.8rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  .showInterest_description {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }




  @media only screen   and (max-width: 1000px) {
    .cardDiv{
        width: 100% !important;
    }

  }
  @media only screen   and (max-width: 400px) {

    .showInterest_headingCont{
        padding: 20px 0px 24px 30px !important;
    

    }
    .showInterest_description{
        width: 100% !important;
        /* text-align: justify; */
    }
  }