.cardma {
    width: 300px;
    box-shadow: 0px 0px 14px -5px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 14px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 14px -5px rgba(0,0,0,0.75);
  }
  
  .mdiv1 {
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    border-radius: 0.8rem;
  cursor: pointer;
    /* flex-wrap: nowrap; */
    /* background-color: red; */
    /* display: flex; */
    /* justify-content: center; */
    width: 80%;
   margin: 0 auto;
  }
  
  /* @media ( max-width: 800px) {
   .mdiv1{
    width: 90%;
    background-color: orange;
   }
  } */
  
  
  
  
  
  
  
  
  
  .sliderDiv{
    width: 100%;
    /* border: 1px solid blue; */
    /* background-color: var(--primary); */
    height: 300px;
  }
  
  .sliderDiv2{
    width: 80%;
    margin: 0 auto;
    /* border: 1px solid green; */
  }
  .slick-arrow{
    /* background-color: black; */
    /* color: black; */
  }
  .slick-prev{
    color: black;
    
  }
  
  
  .slick-prev:before{
    color: black;
  }
  .slick-next:before{
    color: black;
  }
  
  .head{
    font-weight: bold;
    font-size: 1.5rem;
  }
  .head2{
    font-size: 1.2rem;
  }
  
  
  .ttt{
      /* background-color: rgb(218, 210, 210); */
  }
  .notfoundpic{
      width: 30rem;
      height: 30rem;
      margin: 0 auto;
  }
  
  
  .maindivcar {
    display: flex;
    /* justify-content: center; */
    flex-wrap: nowrap;
    width:100%;
    align-items: center;
  
    /* border: 1px solid blue; */
  
    /* flex-direction: column; */
    /* background-color: red; */
  }
  
  
  .cola{
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
  }
  
  .colb{
    /* border: 1px solid blue; */
  }
  
  .carflxa {
    flex: none;
  }
  .picdiv {
  }
  .imga1 {
    height: 16rem;
    width: 25rem;
    border-radius: 7px;
    /* border: 1px solid gold; */
  }
  
  .cardtil {
  }
  
  .cartexta {
    display: flex;
    justify-content: space-between;
  }
  
  .txt {
    /* font-weight: bold; */
    font-size: 1.1rem;
  }
  
  
  .modelpics{
    padding: 2rem;
    /* border: 1px solid red; */
  }
  .modelpic{
    width: 50rem;
    /* border: 1px solid red; */
  }
  
  /* .slider{
    border: 1px solid red;
  } */
  
  .sliderdiv{
    width: 90%;
    background-color: red;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .clrclas{
    /* background-color: gray; */
  }
  
  .nocar{
    margin: auto auto;
    /* border: 1px solid red; */
  }
  
  
  
  
  .brndname{
    font-size: 2rem;
  }
  .modelvarint{
    font-size: 1.2rem;
  
  }
  
  
  .App {
    font-family: sans-serif;
    text-align: center;
    border: 1px red solid;
     width: 600px;
    height: 500px;
  }
  
  
  .Card_Div{
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }