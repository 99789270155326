.work_text{
    font-weight: bolder;
    font-size: 1.2rem;
}

.how_pic{
    width: 20rem;
    height: 17rem;
}


@media only screen and (max-width: 899px) and (min-width: 600px) {
    .how_wrap {
    margin-top: 14rem;
    }
    
    
  }

@media only screen and (max-width: 600px) and (min-width: 500px) {
    .how_wrap {
    margin-top: 10rem;
    }
    
    
  }

  @media only screen and (max-width: 499px) and (min-width: 400px) {
    .how_wrap {
    margin-top: 3rem;
    }
    
    
  }

  


  @media only screen and (max-width: 400px) and (min-width: 380px) {
    .how_wrap {
    margin-top: 8rem;
    }
    
    
  }


  @media only screen and (max-width: 380px) and (min-width: 0px) {
    .how_wrap {
    margin-top: 16rem;
    }
    
    
  }

  @media only screen and (max-width: 1400px) and (min-width: 900px) {
    .how_wrap {
    /* margin-top: 5%; */
    }
    
    
  }

  /* .img_4{
      width: 210px;
  } */
  /* .img_2{
      width: 210px;
  } */