.card {
  /* background-color: aquamarine !important; */
  padding: 0%;
  margin-bottom: 2%;
}

.gridisplaylayout {
  flex-direction: column;
  display: flex;
}
.griddetailcontainer {
  width: 100%;
  display: flex;
}
.gridcurrentbidContainer {
  align-items: center;
  width: 100%;
}
.gridseconddetail {
  width: 100%;
  height: 22%;
  display: flex;
  flex-direction: column;
}
.gridtexttitle {
  font-size: 1rem;
  font-weight: bold;
}
.gridtext {
  font-size: 0.8rem;
}
.gridtimelable {
  /* position: absolute;
    bottom: 0%;
    right: 0%;
    height: 20%; */
  width: 100%;
  flex-direction: row;
  justify-content: end;
  text-align: center;
  align-items: center;
  display: flex;
}
.gridbid {
  position: absolute;
  bottom: 0%;
  left: 0%;
  height: 20%;
  width: 25%;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  display: flex;
}
.gridfirst {
  position: relative;
  /* height: 80% !important; */
  width: 100%;
  /* background-color: green; */
}
img {
  height: 100%;
  width: 100%;
}
.gridiconcontainer {
  flex-direction: row;
  display: flex;
  position: relative;
  height: 10%;
  width: 100%;
  bottom: 12%;
  left: 1%;
  /* justify-content: center; */
  align-items: center;
}
.gridicon {
  margin-right: 2%;
  border: 1px solid whitesmoke;
  height: 100%;
  width: 20%;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
}
.gridicontext {
  font-size: 0.8rem;
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.gridiconi {
  font-size: 1.2rem;
  color: white;
  align-items: center;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.gridsecond {
  position: relative;
  padding: 2%;
  width: 100%;
}
.gridthird {
  flex-direction: column;
  width: 100%;
  background-color: whitesmoke !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .main {
    background-color: aqua !important;
  }

  .gridisplaylayout {
    width: 100%;
    flex-direction: column;
    display: flex;
  }
  .griddetailcontainer {
    width: 100%;
    display: flex;
  }
  .gridcurrentbidContainer {
    align-items: center;
    width: 100%;
  }
  .gridseconddetail {
    width: 100%;
    height: 22%;
    display: flex;
    flex-direction: column;
  }
  .gridtexttitle {
    font-size: 1rem;
    font-weight: bold;
  }
  .gridtext {
    font-size: 0.8rem;
  }
  .gridtimelable {
    /* position: absolute;
      bottom: 10%;
      right: -25%;
      height: 20%; */
    width: 100%;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
  }
  .gridbid {
    position: absolute;
    bottom: 0%;
    left: 0%;
    height: 20%;
    width: 25%;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    display: flex;
  }
  .gridfirst {
    position: relative;
    height: 80% !important;
    width: 100%;
    /* background-color: green; */
  }
  img {
    height: 100%;
    width: 100%;
  }
  .gridiconcontainer {
    flex-direction: row;
    display: flex;
    position: relative;
    height: 10%;
    width: 100%;
    bottom: 12%;
    left: 1%;
    /* justify-content: center; */
    align-items: center;
  }
  .gridicon {
    margin-right: 2%;
    border: 1px solid whitesmoke;
    height: 100%;
    width: 20%;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
  }
  .gridicontext {
    font-size: 0.8rem;
    color: white;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .gridiconi {
    font-size: 1.2rem;
    color: white;
    align-items: center;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .gridsecond {
    flex-direction: row;
    width: 100%;
    position: relative;
    /* padding: 2%; */
  }
  .gridthird {
    flex-direction: row;
    width: 100%;
    background-color: whitesmoke !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 770px) and (max-width: 887px) {
}

@media (min-width: 888px) and (max-width: 1200px) {
}
