.toaster-hide {
  display: none;
  opacity: none;
  visibility: hidden;
}
.toaster-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 100000;
  /* width: 20rem; */
  /* padding: 1rem; */
  /* border-top-left-radius: 10px; */
  /* border-bottom-left-radius: 10px; */
  border-radius: 8px;
  /* height: 2rem; */
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  max-width: 30rem;
  transition: ease-in-out 0.5s;
}
.toaster-content-container {
  /* position: absolute; */
  /* top: 50; */
  /* right: 0; */
  /* z-index: 10000000000; */
  /* width: 20rem; */
  padding: 0.7rem 1rem 0.7rem 0.5rem;
  /* border-top-left-radius: 10px; */
  /* border-bottom-left-radius: 10px; */
  /* height: 2rem; */
  /* overflow: visible; */
  word-wrap: break-word;
}
.statusBar {
  display: flex;
  flex: 1;
  padding: 5px;
}
.toaster-heading {
  font-weight: bold;
  font-size: 1.2rem;
  color: #000;
  text-transform: capitalize;
}
.toaster-description {
  font-size: 0.9rem;
  color: #262626;
  text-transform: capitalize;
  /* margin-top: 0.3rem; */
}
