.inp_wrap{


    background-color: #fff;
    border: 2px solid  #fff;
    border-radius: 4px;
    box-shadow: 0 0 8px #1d1d1b40;
    cursor: pointer;
    /* padding: 24px; */
    transition: border-color .2s linear;
    width: 100%;
}

.inp_wrap:hover{
    box-shadow: 0 0 12px #03030340;
    border: 2px solid  #afada3;
}

.inp_search{
    background-color: red;
}

.prompt{
    /* background-color: black !important; */
    width: 500px !important;
}

.next_btn{
    align-items: center;
    -webkit-appearance: none;
    background-color: #0460cc;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: NewTransportSemi,Helvetica,Arial,sans-serif;
    font-size: 18px;
    font-weight: 400;
    height: 48px;
    justify-content: center;
    line-height: 24px;
    margin-bottom: 24px;
    min-width: 112px;
    outline: none;
    padding: 4px 8px;
    transition: background-color .1s linear;
    vertical-align: middle;
    width: 200px;
    /* width: 100%; */
}

.ui.input{
    width: 60%!important;
} 


.qwqw{
    display: flex;
    /* border: 1px solid red; */
    flex-direction: row;
    align-items: center;
}


.back_btn{
    align-items: center;
    -webkit-appearance: none;

   
    border-radius: 4px;

    cursor: pointer;
    display: flex;
    font-family: NewTransportSemi,Helvetica,Arial,sans-serif;
    font-size: 18px;
    font-weight: 400;
    height: 48px;
    justify-content: center;
    line-height: 24px;
    background-color: #fff;
    border: 2px solid #e2e1d9;
    color: #0460cc;
    transition: background-color .1s linear,border-color .1s linear;
    margin-bottom: 24px;
    min-width: 112px;
    outline: none;
    padding: 4px 8px;
    transition: background-color .1s linear;
    vertical-align: midd;
    width: 200px;
    /* width: 100%; */
}

.inp_main_wrapp{
    padding: 20px;
    height: 50vh;
    /* border: 1px solid red; */
}


.progresbar{
    position: fixed;
 
    /* border: 1px solid red; */

} 
.p_wrap{
    position: absolute;
    top: 15px;
}
.aaaaaa{
    position: relative;
   

}

.css-1l6rwmg-MuiLinearProgress-root{
    position: fixed !important;
    width: 100% !important;
    z-index: 10000 !important;
    
}

.css-1l6rwmg-MuiLinearProgress-root .MuiLinearProgress-bar{
    background-color: #002F34 !important;
    top: 91px!important;
}


.botm_wrap{
    padding: 0px 10px 10px 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
}


.inp_col{
    display: flex;
    justify-content: flex-end;
}

 .inp_col_2{
    display: flex;
    /* justify-content: start; */
} 