.formComponent_searchCont {
  background: var(--info);
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.formComponent_searchText {
  background: transparent;
  font-size: 1.2rem;
  line-height: 1.2rem;
  margin: 0 0.2rem;
}
.showInterest_form_button {
  border-radius: 0.5rem;
}
.formComponent_listSectionHeading {
  font-weight: bold;
  padding: 0.2rem;
  color: var(--primary);
  text-transform: capitalize;
}
.formComponent_sectionListCont {
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background: var(--info); */
  margin: 0.5rem 0;
  border-radius: 0.5rem;
  width: 100%;
}
.formComponent_sectionListDivider {
  width: 98%;
  height: 1px;
  background: var(--infoPlus);
}
/*  */
.showInterest_form_child {
  max-height: 78%;
  overflow-y: scroll;
}

/* Handle */

.showInterest_form_child::-webkit-scrollbar-thumb {
  background: var(--primaryInfo);
  border-radius: 5px;
}

/* Handle on hover */

.showInterest_form_child::-webkit-scrollbar-thumb:hover {
  background: var(--primaryLight);
}
/*  */
@media only screen and (max-width:1274px) {
  .ui.fluid.steps {
    height: 100% !important;
    overflow-y: scroll !important ;
    overflow-x: hidden !important ;
  }
}



.shimer_showintest{
  width: 80%;
  /* border: 1px solid red; */
  margin: 0 auto;
  
  box-shadow: -1px 0px 10px -5px rgba(69,68,68,0.5);
  -webkit-box-shadow: -1px 0px 10px -5px rgba(69,68,68,0.5);
  -moz-box-shadow: -1px 0px 10px -5px rgba(69,68,68,0.5);
}