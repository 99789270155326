.activeTab {
  background: var(--white);
}
.tab {
  padding: 1rem;
  font-weight: bold;
  color: var(--secondary);
  cursor: pointer;
  text-transform: capitalize;
}
.tabs_body_wrapper {
    background: var(--white);
    color: var(--secondary);
    padding: 1rem;
}
