.auction_dropdown_wrapper {
  padding: 2vh;
  border-top: 0.2px solid var(--secondary);
  background: var(--info);
}
.filter_bar_mobile_footer {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.filter_bar_mobile_footer_button {
  font-size: 1.2rem;
  font-weight: 700;
  padding: 1.5vh;
  width: 90%;
  color: var(--white);
  align-self: center;
  border-radius: 5px;
  background: var(--primary);
}
.Abody {
  padding: 5%;
}
.listtext {
  font-size: 0.8rem;
}
.heading {
  font-size: 1.2rem;
  font-weight: 500;
}
hr.solid {
  border-top: 4px solid #bbb;
}

.Auctionbody {
  padding: 5%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}
