.greating_section {
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  margin: 1rem 0;
}
.greating_main_cont {
  min-height: calc(100vh / 1.7);
  width: 100%;
}
.greating_cont_heading {
  font-size: 4rem;
  font-weight: 900;
  line-height: 1;
  color: var(--white);
  text-transform: capitalize;
  text-align: center;
}
.token_cont_item {
  font-size: 3rem;
  font-weight: 900;
  line-height: 1;
  color: var(--white);
  text-transform: capitalize;
  text-align: center;
}
.greating_cont_desc {
  margin: 1rem 0;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.3;
  text-align: center;
  width: 60%;
  color: var(--white);
  /* text-transform: capitalize; */
}
.greating_section_2 {
  background: var(--info);
  display: flex;
  flex-wrap: wrap;
}
.greating_section_2_card {
  margin: 0.5rem;
  padding: 1rem;
  display: block;
  border-radius: 0.5rem;
  width: 16rem;
}
.token_cont {
  background-color: var(--info);
  /* height: 4.7rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.2rem;
  margin: 1.5rem;
  overflow: hidden;
}

.token_text {
  margin: .75rem;
  color: var(--secondary);
  flex: 1;
}
.token_button {
  background: var(--primary);
}
/*  */
@media only screen and (max-width: 1135px) {
  .greating_section {
    width: 80%;
  }
  .greating_cont_heading {
    font-size: 3.5rem;
  }
  .token_cont_item {
    font-size: 2.8rem;
  }
}
/*  */
@media only screen and (max-width: 991px) {
  .greating_main_cont {
    min-height: calc(100vh / 2.7);
  }
  .greating_section {
    width: 95%;
  }
  .greating_cont_heading {
    font-size: 3rem;
  }
  .token_cont_item {
    font-size: 2.8rem;
  }
  .greating_cont_desc {
    width: 85%;
  }
}
/*  */
@media only screen and (max-width: 685px) {
  .greating_section {
    width: 100%;
  }
  .greating_cont_heading {
    font-size: 2.4rem;
  }
  .token_cont_item {
    font-size: 1.8rem;
  }
  .greating_cont_desc {
    width: 90%;
    line-height: 1.1;
  }
}
/*  */
@media only screen and (max-width: 545px) {
  .greating_section {
    padding: 0px;
  }
  .greating_cont_heading {
    font-size: 1.9rem;
  }
  .token_cont_item {
    font-size: 1.2rem;
  }
  .greating_cont_desc {
    width: 95%;
    line-height: 1.2;
    font-size: 1.1rem;
  }
  .token_cont {
    margin: 1rem;
  }
}
@media print {
  .token_cont {
    display: none;
  }
  .greating_cont_heading {
    color: var(--secondary);
  }
  .greating_cont_desc {
    color: var(--secondary);
    width: 85%;
  }
  .hide_in_print {
    display: none;
  }
}
