
.car_l{
  display: flex;
  justify-content: center;
  align-items: center;
}


@media only  screen and (max-width:450px) {
  .animi{
    margin-left: 20px !important;
  }

  .no_car{
    font-size: 1.4rem !important;
  }
}

.landing_page_main_cont {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.landing_page_left_cont_wrapper {
  width: calc(320px);
}
.landing_page_left_cont {
  width: calc(320px);
  overflow-y: scroll;
}
.landing_page_right_cont {
  width: calc(300px);
  overflow-y: scroll;
}
.landing_page_right_cont_wrapper {
  width: calc(300px);
}
.product-list-cont {
  display: flex;
  flex: 7;
  flex-direction: column;
  padding: 7px;
}
.buttons_tab_main_cont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.grid_list_toggle_button_group {
  display: flex;
  justify-content: center;
  align-items: center;
  /* align-self: center; */
}
.grid_list_toggle_button {
  border-radius: 5px;
  padding: 1vh;
  font-size: 1rem;
  font-weight: 500;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.15s ease-in-out;
  margin: 5px;
}
.mobile_filter_drawer_wrapper {
  display: none;
}
.mobile_filter_drawer_button {
  color: var(--white);
  padding: 1vh;
  margin: 0.5vh 1vw;
  border-radius: 5px;
  font-size: 1.1rem;
  background: var(--secondary);
  font-weight: 400;
}
.mobile_filter_drawer_button:hover {
  background: var(--primary);
}
/* --------------------- */
.search {
  background-color: white;
  width: 100%;
  height: 20% !important;
}
.buttonbox {
  padding: 2%;
  margin-bottom: 2%;
  flex-direction: row;
  display: flex;
  /* background-color: aqua; */
  width: 100%;
  height: 5%;
  align-items: center;
}
.buutonheading {
  font-size: 1rem;
  font-weight: 600;
}
.grid {
  margin-left: 2%;
  background-color: whitesmoke;
  /* justify-content: space-around; */
  width: 7%;
  flex-direction: row;
  display: flex;
  cursor: pointer;
}
.Bottom {
  width: 100%;
  padding: 1%;
  flex-direction: row;
  display: flex;
}
.mfirst {
  width: 50%;
  /* background-color: antiquewhite; */
}
.msecond {
  width: 100%;
}
.mthird {
  width: 50%;
  /* background-color: aquamarine; */
}

@media only screen and (max-width: 948px) and (min-width: 0px) {
  .landing_page_left_cont_wrapper {
    display: none;
    /* background: aqua; */
  }
  .filter_bar_mobile_footer {
    display: flex;
  }
  .mobile_filter_drawer_wrapper {
    display: inline-block;
  }
}
@media only screen and (max-width: 1023px) and (min-width: 0px) {
  .landing_page_right_cont_wrapper {
    display: none;
  }
}
@media (min-width: 888px) and (max-width: 1070px) {
  .mthird {
    width: 60%;
  }
}

@media (min-width: 360px) and (max-width: 700px) {
  .mthird {
    display: none;
  }
  .mfirst {
    display: none;
  }
  .buttonbox {
    padding: 2%;
    flex-direction: row;
    display: flex;
    width: 100%;
    height: 1%;
    align-items: center;
  }
  .buutonheading {
    font-size: 1rem;
    font-weight: 600;
  }
  .grid {
    margin-left: 2%;
    background-color: whitesmoke;
    justify-content: center;
    width: 13%;
    flex-direction: row;
    display: flex;
    cursor: pointer;
  }
}
@media (min-width: 700px) and (max-width: 1200px) {
  .mfirst {
    width: 50%;
    /* background-color: antiquewhite; */
  }
  .mthird {
    width: 50%;
  }
  .msecond {
    width: 100%;
  }
  .buttonbox {
    padding: 2%;
    flex-direction: row;
    display: flex;
    width: 100%;
    height: 5%;
    align-items: center;
  }
  .buutonheading {
    font-size: 1rem;
    font-weight: 600;
  }
  .grid {
    margin-left: 2%;
    background-color: whitesmoke;
    justify-content: center;
    width: 13%;
    flex-direction: row;
    display: flex;
    cursor: pointer;
  }
}
