/* I've Webkit vendor-prefixed my CSS */

input[type="range"] {
  width: 100%;
  /* margin: 100px; */
  /* background: -webkit-gradient(linear, 0 0, 0 bottom, from(#000), to(#1f1f1f)); */
  background: var(--secondary);
  -webkit-appearance: none;
  border-radius: 10px;
  padding: 5px;
  transition: opacity 0.5s;
  position: relative;
}
input[type="range"]:hover {
  cursor: grab;
}
input[type="range"]:active {
  cursor: grabbing;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border-radius: 10px;
  /* background: -webkit-linear-gradient(top, #fafafa 0%,#dedede 50%,#cfcfcf 51%,#a3a3a3 100%); */
  /* background: var(--primary); */
  background-image: url("../../assets/svg/right-arrow-bg-white.svg");
  background-repeat: no-repeat;
  z-index: 1;
  width: 55px;
  position: relative;
  height: 35px;
  content: "Slide";
}

input[type="range"]:before {
  content: "Slide left to bid";
  color: var(--info);
  position: absolute;
  left: 100px;
  top: 10px;
  z-index: 1;
  font-size: 25px;
}

input[type="range"]::-webkit-slider-thumb:before {
  color: var(--info);
  position: absolute;
  left: 5px;
  top: -10px;
  z-index: 1;
  font-size: 56px;
  font-weight: bold;
  content: "→";
}
input[type="range"] {
  pointer-events: none;
}
input[type="range"]::-webkit-slider-thumb {
  /*Webkit Browsers like Chrome and Safari*/
  pointer-events: auto;
}
input[type="range"]::-moz-range-thumb {
  /*Firefox*/
  pointer-events: auto;
}
input[type="range"]::-ms-thumb {
  /*Internet Explorer*/
  pointer-events: auto;
}
@media only screen and (max-width: 1060px) {
   input[type="range"]:before {
  left: 80px;
  top: 10px;
   font-size: 20px;
}
}
@media only screen and (max-width: 991px) {
	input[type="range"]:before {
	left: 120px;
	top: 8px;
	font-size: 25px;
	}

}
@media only screen and (max-width: 570px) {
	input[type="range"]:before {
	left: 80px;
	top: 10px;
	font-size: 20px;
	}

}
@media only screen and (max-width: 450px) {
	input[type="range"]:before {
	left: 65px;
	top: 12px;
	font-size: 16px;
	}
}
@media only screen and (max-width: 390px) {
	input[type="range"]:before {
	left: 70px;
	top: 13px;
	font-size: 12px;
	}
}
/* .pullee {
  width: 10rem;
  appearance: none;
}
.pullee:active::-webkit-slider-thumb {
  appearance: none;
  transform: scale(1.1);
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.pullee:active::-moz-range-thumb {
  border: 0;
  transform: scale(1.1);
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.pullee:active::-ms-thumb {
  transform: scale(1.1);
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
.pullee:focus {
  outline: none;
}
.pullee::-webkit-slider-thumb {
  appearance: none;
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: var(--primary);
  transform-origin: 50% 50%;
  transform: scale(1);
  transition: transform ease-out 100ms;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.pullee::-moz-range-thumb {
  border: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: var(--primary);
  transform-origin: 50% 50%;
  transform: scale(1);
  transition: transform ease-out 100ms;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.pullee::-ms-thumb {
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: var(--primary);
  transform-origin: 50% 50%;
  transform: scale(1);
  transition: transform ease-out 100ms;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.pullee::-webkit-slider-runnable-track {
  height: 1rem;
  padding: 0.25rem;
  box-sizing: content-box;
  border-radius: 1rem;
  background-color: var(--secondary2);
}
.pullee::-moz-range-track {
  height: 1rem;
  padding: 0.25rem;
  box-sizing: content-box;
  border-radius: 1rem;
  background-color: var(--secondary2);
}
.pullee::-moz-focus-outer {
  border: 0;
}
.pullee::-ms-track {
  border: 0;
  height: 1rem;
  padding: 0.25rem;
  box-sizing: content-box;
  border-radius: 1rem;
  background-color: var(--secondary2);
  color: transparent;
}
.pullee::-ms-fill-lower, .pullee::-ms-fill-upper {
  background-color: transparent;
}
.pullee::-ms-tooltip {
  display: none;
} */
