.wonbody{
width: 100%;
}

.wontitle{
font-size: 0.9rem;
font-weight: 500
}

.wonheading{
  font-size: 1rem;
  font-weight: 700
  }
/* .wonheading {
  font-size: 1rem;
  font-weight: 500;
} */
.wonheading2 {
    display: flex;
    text-align: center;
    font-size: 0.8rem;
    font-weight: 700;
    color: lightskyblue;
  }
hr.solid {
    border-top: 4px solid #bbb;
  }
.wonAuctionbody{
    padding: 2%;
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content:space-between ;

}
.wondetail{

 justify-content: center;
  display: flex;
  width: 100%;
  height: 5rem;
}
.wfirst{
  border-bottom-left-radius: 2px;
  border-width: 3px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
 
}
.wsecond{
  flex-direction: column;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;

}
.wthird{
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  width: 100%;

}
@media (min-width:601) and (max-width:800){

  .wonAuctionbody{
    padding: 1%;
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content:space-between ;

}
.wfirst{
  border-bottom-left-radius: 2px;
  border-width: 3px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
 
}
.wsecond{
  flex-direction: column;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;

}
.wthird{
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  width: 100%;

}
    
}