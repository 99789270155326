.grid_container {
  width: 100%;
  padding: 1rem;
}
.grid_card_wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.grid_card {
  width: 48%;
  margin: 0.2rem;
  display: block;
}
.product_list_card_image {
  transition: transform 0.2s;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  object-fit: contain;
}