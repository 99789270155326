


/*<link href="https://fonts.googleapis.com/css?family=Maven+Pro:400,500,700,900" rel="stylesheet">
*/



@media screen and (max-width:992px) {
	.cars_th{
		position: unset !important;
		width: auto !important;
	}
}






.form-control::-webkit-input-placeholder { color: #eee; }  /* WebKit, Blink, Edge */
.form-control:-moz-placeholder { color: #eee; }  /* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder { color: #eee; }  /* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder { color: #eee; }  /* Internet Explorer 10-11 */
.form-control::-ms-input-placeholder { color: #eee; }  /* Microsoft Edge */




.cfl_header.navbar-light .navbar-nav .active>.nav-link, 
.cfl_header.navbar-light .navbar-nav .nav-link.active, 
.cfl_header.navbar-light .navbar-nav .nav-link.show, 
.cfl_header.navbar-light .navbar-nav .show>.nav-link ,
.cfl_header.navbar-light .navbar-nav .nav-link {
	color: #096c59;
    border-bottom: 3px solid transparent;
    /*padding-bottom: 6px;*/
    padding: 6px 0 5px 0;
    line-height: 1;
    -webkit-transition: .3s border-color ease-out;
    transition: .3s border-color ease-out;
    white-space: nowrap;
    font-size: 16px;
    padding-right: 0;
    padding-left: 0;
}
.cfl_header.navbar-light .navbar-nav .nav-link:focus, 
.cfl_header.navbar-light .navbar-nav .nav-link:hover {
	border-bottom-color: #096c59;
}
.cfl_header.navbar-light .navbar-nav li.nav-item:not(:first-of-type) {
	margin: 0 0 0 1rem;
}
.cfl_header.navbar-light .navbar-nav li .btn-fancy {
	margin: 3px 0 0 0;
}
.btn-fancy {
	padding: .375rem 1.4rem !important;
	background-color: #096c59;
	background-image: linear-gradient(0deg, #096c59, #13b092);
	border: none;
	outline: none;
}
.cfl_header.navbar-light .navbar-nav a.btn-fancy ,
.cfl_header.navbar-light .navbar-nav a.btn-fancy:hover {
	border-bottom: none;
}
.cfl_header.navbar-light {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 75px; 
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.cfl_header.navbar-light .navbar-brand {
	padding: 0;
	margin: 0 0 0 -1px;
	font-size: 1rem;
}
.cfl_header.navbar-light ul.navbar-nav {
	-webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.lead_banner {
	background-color:var(--primary);
	/* background-image: linear-gradient(106deg, #096c59, #13b092); */
	/*position: relative;*/
	padding: 30px 0;
	padding: 2.1875rem 0 0 0;
	color: #fff;
	height: auto;
}
.lead_banner .dost {
	color: #fff;
	font-weight: 700;
	font-size: 2.8rem;
	letter-spacing: -0.05rem;
	margin: 0 0 20px 0;
	line-height: 1;
}
/*.cars_th {
	background: url(../images/handshake.png) no-repeat;
	background-size: contain;
	position: relative;
	bottom: 0;
	left: -15%;
	z-index: 10;
	width: 833px;
	height: 340px;
	max-width: 100%;
	margin: -50px 0 0 0;
}*/
.cars_th {
	position: absolute;
	bottom: 0;
	left: -120px;
	z-index: 10;
	width: 700px;
}
.relative {
	position: relative;
}
.cars_th_wrap {
	min-height: 400px;
}
.lead_banner_form_wrap {
	/*background: #157a68;*/
	background: #fff;
	border-radius: 12px;
	padding: 20px 35px 25px 35px;
	color: #157a68;
	width: 430px;
	max-width: 100%;
	margin: 0 auto;	
}
.thin_title {
	font-weight: 400;
}
.text-small {
	font-size: 11px;
}
.lead_banner_form_wrap .thin_title {
	text-align: left;
	/*padding: .85rem 1rem;*/
	padding: .85rem 0 5px 0;
	color: #157a68;
}
.lead_banner_form_wrap p {
	color: #fff;
	font-size: 12px;
	line-height: 1.3;
	
}
.lead_banner_pad {
	
}
.btn-submit {
	/*padding: .8rem 1rem !important;
	background-color: #096c59;
	background-image: linear-gradient(0deg, #096c59, #13b092);
	border: none;
	outline: none;
	color: #fff;
	font-weight: 500;
	text-transform: uppercase;
	border-radius: 0 0 12px 12px;
	font-size: 18px;
	letter-spacing: 0.02rem;
	border: 1px solid #10987e;*/
	border-radius: .3125rem;
	font-size: 1.125rem;
	height: auto;
	width: 100%;
	text-align: left;
	background: #ffd700;
}
/*.lead_banner_form_wrap select.form-control {
	border-radius: 0;
	height: 46px;
	background: #157a68;
	background : url("../images/arrow_down.png") no-repeat right 10px center #157a68;
	color: #fff;
}*/



.lead_banner_form_wrap .form-control {
	background: var(--primary);
	color: #fff;
	border-radius: 0;
}


.lead_banner_form_wrap select.form-control {
	outline : none;
    overflow : hidden;
    text-indent : 0.01px;
    text-overflow : '';
    /* background : url("../images/arrow_down.png") no-repeat right 10px center #157a68; */
    background-size: 18px;

    -webkit-appearance: none;
       -moz-appearance: none;
        -ms-appearance: none;
         -o-appearance: none;
            appearance: none;
}
.lead_banner_form_wrap select.form-control::-ms-expand {
    display: none;
}







.asdd {
	border-radius: .3125rem;
	font-size: 1.125rem;
	height: auto;
	width: 100%;
	text-align: left;
	background: #ffd700;
}
.lead_banner_form_wrap ol {
	margin: 0;
	padding: 0 0 0 12px;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.35;
	font-family: 'Roboto', sans-serif;
}
.lead_banner_form_wrap ol li {
	margin: 0 0 12px 0;
}
.btn-submit {
	font-weight: 700;
	font-size: 1.125rem;
	color: #3b424b;
	text-transform: capitalize;
	padding: 1.15rem .9375rem 1.15rem .9375rem;
	transition: all .2s ease;
	transition: all 450ms cubic-bezier(.23, 1, .32, 1) 0ms;
}
.btn-submit:hover {
	background-color: #e6c200;
}
.text-yellow {
	color: #ffd600;
}
.btn-submit i {
	float: right;
	margin: 4px 5px 4px 0;
}
/*FOOTER*/
.main_footer {
	background: #363636;
	border-top: 5px solid #096c59;
	font-size: 1em;
	color: #fff;
	padding: 60px 0;
}
.main_footer .footerBranding {
	float: left;
	width: 33.33333333%;
}
.main_footer .footerNav {
	left: 25%;
	width: 25%;
	position: relative;
	float: left;
}
.main_footer .footerBranding ,
.main_footer .footerNav ,
.main_footer .footerSocialLinks {
	padding-left: .9375rem;
	padding-right: .9375rem;
}
.main_footer .footerSocialLinks {
	margin-bottom: 20px;
	left: 25%;
	width: 16.666666666666664%;
	float: left;
	position: relative;
}
.main_footer .footerBranding .footerLogo {
    max-width: 210px;
    margin-bottom: 10px;
}
.main_footer .footerBranding .footerLogo svg,
.main_footer .footerBranding .footerLogo img {
	width: auto;
	max-width: 100%;
}
.main_footer .footerLogoLink {
    height: 29px;
}
.main_footer .footerBranding .footerBrandingLine {
	margin-bottom: 30px;
	font-weight: bold;
}
.main_footer a {
	color: #fff;
	transition: .15s ease-out opacity;
}
.main_footer .footerBranding .footerBrandingItem {
	margin-bottom: .3125rem;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.main_footer .footerBranding .footerBrandingItem .icon,
.main_footer .footerBranding .footerBrandingItem i, 
.main_footer .footerBranding .footerWorkingHours .icon ,
.main_footer .footerBranding .footerWorkingHours i {
	margin-right: 10px;
}
.main_footer .footerBranding .footerWorkingHours span {
	font-weight: normal;
}
.main_footer .footerNav {
	font-size: 1rem;
}
.main_footer .footerNavLeft ,
.main_footer .footerNavRight {
	list-style-type: none;
	margin: 0;
	padding: 0;
}
.main_footer .footerSocialIcon {
	display: block;
    min-height: 24px;
    margin-bottom: 14px;
} 
.main_footer .footerNavItem {
    display: block;
    min-height: 24px;
    margin-bottom: 14px;
}
.main_footer .footerSocialLinks .footerSocialLink {
	display: block;
	height: 24px;
}
.main_footer .footerSocialLinks .footerSocialLink .icon ,
.main_footer .footerSocialLinks .footerSocialLink i {
    fill: #fff;
    margin-right: 10px;
}
.main_footer .footerCopyright {
	margin-top: 15px;
}
.main_footer .container {
	max-width: 1170px;
	/*padding-left: .9375rem;
    padding-right: .9375rem;*/
    padding-left: 0;
    padding-right: 0;
}
.main_footer .footerSocialLinks .footerSocialLink i {
	font-size: 18px;
}
.text-p{
	font-size: 1rem;
	/*font-family: 'maven_pro_light300';*/
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
}
.cars_th_wrap ol {
	font-size: 18px;
	margin-bottom: 32px;
}

/*Loader*/
.lead_banner_form_wrap {
	position: relative;
}
.lead_banner_form_wrap .success_loader {
	display: block;
}
.lead_banner_form_wrap {
	overflow: hidden;	
}
.lead_banner_form_wrap .success_loader:before {
	position: absolute;
	top: 0; bottom: 0;
	right: 0; left: 0;
	width: 100%;
	height: 100%;
	margin: auto;
	background: rgba(0,0,0,.35);
}
.lead_banner_form_wrap .success_loader:after {
	content: "";
	/* background: rgba(0,0,0,.5) url(../images/loading.gif) no-repeat center ; */
	width: 64px;
	height: 64px;
	position: absolute;
	top: 0; bottom: 0;
	right: 0; left: 0;
	width: 100%;
	height: 100%;
	margin: auto;	
}



/*Checkbox*/
.checkbox_wrap {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 18px;
    cursor: pointer;
	/*font-size: .875rem;
	line-height: 1.31;
	font-weight: 500;*/
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
	padding-top: 3px;
	margin-top: 18px;
	font-size: 14px;
	line-height: 1.35;
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
}

/* Hide the browser's default checkbox */
.checkbox_wrap input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 6px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
	border: 2px solid #157a68;
	border-radius: 5px;
}
/* On mouse-over, add a grey background color */
.checkbox_wrap:hover input ~ .checkmark {
    background-color: #ccc;
}
/* When the checkbox is checked, add a blue background */
.checkbox_wrap input:checked ~ .checkmark {
    background-color: #157a68;
    border-radius: 5px;
	border: 1px solid #fff;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
/* Show the checkmark when checked */
.checkbox_wrap input:checked ~ .checkmark:after {
    display: block;
}
/* Style the checkmark/indicator */
.checkbox_wrap .checkmark:after {
    left: 10px;
    top: 6px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.thankyou_message {
	font-family: Maven Pro,sans-serif;
	color: #fff;
	text-align: center;
	position: absolute;
	top: 0; bottom: 0;
	right: 0; left: 0;
	width: 100%;
	height: 100%;
	margin: auto;
	background: rgba(0,0,0,.925);
	z-index: 10;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	padding: 2rem 2rem;
}
.thankyou_message h4 {
	margin: 0 0 1	0px 0;
	font-size: 24px;
	line-height: 1.4;
}
.thankyou_message p {
	font-size: 18px;
}
.thankyou_message i {
	font-size: 48px;
	margin-bottom: .5rem;
}
.help-block{
	/*color: #ffd600;*/
	color: #f00;
	display: block;
	width: 100%;
	clear: both;
}
#termsConditions-error{
	margin-top: 10px;
    display: block;
    font-weight: 400;
    font-size: 16px;
}
.h-w{
	margin-top: 25px;
}
.lin{
	    margin-bottom: 7px;
}
.levels_wrap {
	margin: 0;
	color: #fff;
}
.level_line {
	padding: 0 0 8px 0;
	margin: 8px 0;
	border-bottom: 1px solid #323232;
}
.level_line .level_line_icon {
	width: 50px;
	margin: 5px 10px 0 0;
	float: left;
}
.level_line .level_line_icon figure {
	position: relative;
	width: 50px;
	height: 50px;
	overflow: hidden;
	text-align: center;
	background: transparent;
	color: #fff;
	margin: 0;
}
.level_line .level_line_icon figure figcaption {
	position: relative;
	top: 0;
	width: 100%;
	font-size: 14px;
	font-weight: 500;	
	background: transparent;
	padding: 2px 0;
	color: #ddd;
}
.level_line .level_line_icon figure strong {
	font-size: 24px;
	font-weight: 700;
	line-height: 1;
}
.level_line .level_line_content {
	float: right;
	width: calc(100% - 65px);
	color: #fff;
	font-size: .9rem;
	text-align: left;
	display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 60px;
    flex-direction: column;
}
.level_line.prize figure svg ,
.level_line.prize figure img {
	width: 30px;
	height: auto;
}
.level_line.prize {
	border-bottom: none;
}
.level_line.prize ol {
	display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 13px;
    line-height: 1.4;
    margin: 15px 0 15px 15px;
    padding: 0;
}
.level_line.prize ol li {
	margin: 0 0 10px 0;
	padding: 0;
	text-align: left;
}
.thankyou_message_close_button {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 10;
}
.thankyou_message_close_button img {
	width: 30px;
	height: 30px;
	cursor: pointer;
}

ul.h-i-w {
	font-size: 1rem;
	margin: 0;
	margin-bottom: 32px;
	padding: 0;
	list-style-type: none;
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
}
ul.h-i-w li {
	margin: 0 0 12px 0;
	padding: 0;
	list-style-type: none;
	display: block;
}
ul.h-i-w li i {
	display: inline-block;
	width: 32px;
	height: 32px;
	background: #0a725e;
	border-radius: 50px;
	color: #fff;
	line-height: 32px;
	font-weight: 500;
	font-size: 18px;
	margin: 0 12px 0 0;
	text-align: center;
	font-style: normal;
	float: left;
}
ul.h-i-w li span {
	float: left;
	width: calc(100% - 60px);
}




@media (min-width: 992px) {
	.cfl_header.navbar-light .navbar-nav .active>.nav-link {
	    border-bottom-color: #096c59;
	}
}


.button.small {
    font-size: 1rem;
    padding: 1rem;
}
.button.primary, .button.primary:visited {
    background-color: gold;
}
.button.primary {
    color: #3b424b;
}
.button.primary:hover {
    background-color: #ffdb1a;
}
.button {
    border: none;
    border-radius: .3125rem;
    -webkit-box-shadow: 0 0.125rem 0.25rem 0 rgba(0,0,0,.25);
    box-shadow: 0 0.125rem 0.25rem 0 rgba(0,0,0,.25);
    cursor: pointer;
    position: relative;
    overflow: hidden;
    -webkit-transition: all .45s cubic-bezier(.23,1,.32,1) 0ms;
    transition: all .45s cubic-bezier(.23,1,.32,1) 0ms;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-weight: 500;
    padding: 1.25rem .9375rem 1.3125rem;
    line-height: 1.15;
}

.button.withIcon .buttonText {
		-webkit-box-flex: 1;
	    -ms-flex: 1;
	    flex: 1;
	    text-align: left;
	    margin-right: 1rem;
	    font-family: Maven Pro,sans-serif;
	    font-size: 1.125rem;
	    font-weight: 700;
	    white-space: nowrap;
	}

.cfl_header.navbar-light ul.navbar-nav li.cta a:hover {
	text-decoration: none;
}



/*DOST PROGRAM URDU*/
.lead_banner.has_urdu {
	direction: rtl;
	text-align: right;
	font-family: 'Markazi Text', serif;
	font-weight: 400;
}
.lead_banner.has_urdu .lead_banner_form_wrap ol {
	list-style-type: none;
	font-size: 16px;
    font-weight: 700;
}
.lead_banner.has_urdu .checkbox_wrap {
	font-size: 16px;
    font-weight: 700;
}
.has_urdu .stepbox  {
	text-align: right;
	border-radius: 0 0 0 50px;
}
.has_urdu .stepbox p {
	font-size: 16px;
	font-weight: 500;
}
.lead_banner.has_urdu ul.h-i-w li i {
	float: right;
	margin: 0 0 0 12px;
}
.lead_banner.has_urdu .cars_th {
	right: -120px;
}
.lead_banner.has_urdu .lead_banner_form_wrap select.form-control {
	/* background : url("../images/arrow_down.png") no-repeat left 10px center #157a68; */
	background-size: 18px;
}
.lead_banner.has_urdu .lead_banner_form_wrap .thin_title {
	text-align: right;
}
.lead_banner.has_urdu  ul.lang_list {
	margin: 0 0 0 20px 0;
	padding: 0;
	list-style-type: none;
	text-align: right;
}
.lead_banner.has_urdu  ul.lang_list li {
	margin: 0 0 0 1rem;
	padding: 0;
	list-style-type: none;
	display: inline-block;
	font-size: 13px;
	text-transform: capitalize;
	font-weight: 500;
}
.lead_banner.has_urdu  ul.lang_list li a {
	color: #fff;
	text-decoration: none;
	font-weight: 500;
}

ul.lang_list {
	margin: 0 0 20px 0;
	padding: 0;
	list-style-type: none;
	text-align: left;
}
ul.lang_list li {
	margin: 0 1rem 0 0;
	padding: 0;
	list-style-type: none;
	display: inline-block;
	font-size: 13px;
	text-transform: capitalize;
	font-weight: 500;
}
ul.lang_list li a {
	color: #fff;
	text-decoration: none;
	font-weight: 500;
}
ul.lang_list li.active a {
	padding-bottom: 3px;
	border-bottom: 1px solid #fff;
}



/*13-12-2019*/
.btn-yellow {
	padding-top: 0;
	position: relative;
	height: 52px;
	font-size: 16px;
	font-weight: bold;
	border-radius: 4px;
	text-align: center;
	box-shadow: inset 0px -5px 0px rgba(0,0,0,0.2);
	background-color: #FFD700;
	color: #3b424b;
	cursor: pointer;
	box-sizing: border-box;
	line-height: 34px;
	border: none;
	white-space: nowrap;
	padding-bottom: 2px;
	padding-left: calc(10px - 2px);
	padding-right: calc(10px - 2px);
	-webkit-user-select: none;
}
.btn-yellow:hover {
	box-shadow: inset 0px -3px 0px rgba(0,0,0,0.2);
    padding-left: calc(10px - 2px);
    padding-right: calc(10px - 2px);
}
.main_header {

}

/*Footer*/
.main_footer {
	background-color: #0f0d0b;
	color: #fff;
	padding-top: 2.4rem;
}
.container {
	max-width: 1030px;
}
.fbb {
	border-bottom: 1px solid #6e747b;
}
.fbt {
	border-top: 1px solid #6e747b;
}
.fw-700 {
	font-weight: 700 !important;
}
.fw-400 {
	font-weight: 400 !important;
}
.fw-500 {
	font-weight: 500 !important;
}

.main_footer a ,
.main_footer a:hover ,
.main_footer a:focus {
	color: #fff;
}
.main_footer h5 {
	font-size: 18px;
	line-height: 27px;
}
.info_icon_line {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}
.info_icon_line p {
	margin: 0 0 0 10px;	
}
.info_icon_line_marg {
	margin-top: 30px;
}
.main_footer a.btn-yellow  {
	color: #3b424b;
}
ul.footer_nav {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
ul.footer_nav li {
	margin: 0 0 9px 0;
	padding: 0;
	list-style-type: none;
	display: block;
}
ul.footer_nav li a {
	color: #fff;
	text-decoration: none;
}
.fs-12 {
	font-size: 12px;
}
.fs-20 {
	font-size: 20px;
}
ul.footer_popular_brands {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
ul.footer_popular_brands li {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: inline-block;
	font-size: 12px;
	color: #9b9b9b;
}
ul.footer_popular_brands li a ,
ul.footer_popular_brands li a:hover {
	text-decoration: none;
	color: #9b9b9b;
}
ul.footer_social_list {
	margin: 0;
	padding: 0;
	list-style-type: none;
	text-align: right;
}
ul.footer_social_list li {
	margin: 0 0 0 6px;
	padding: 0;
	list-style-type: none;
	display: inline-block;
}
ul.footer_social_list li a {
	text-decoration: none;
}
ul.footer_social_list li img {
	width: 24px;
	height: 24px;
}
.copyrights {
	margin: 0;
	font-size: 14px;
	color: #fff;
	line-height: 21px;
}


.main_header .dropdown-menu {
	padding: 0;
}
.main_header .dropdown-menu .dropdown-item {
	padding: 16px;
}
.langTick {
	width: 14px;
	height: auto;
	float: right;
}
.main_header .navbar-nav .lang_drop.show .dropdown-toggle::after {
	    content: "\f077";
}
.main_header .navbar {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}
.main_header .nav-item.menudrop {
	padding: 1rem;
    padding-left: 2rem;
    margin-top: -2px;
}
.main_header  .navbar-brand {
	margin-right: 0;
	padding-top: 0;
}
.main_header .navMenuContent {
	font-size: 1.125em;
    position: fixed;
    top: -120%;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 9999999999;
    overflow-y: auto;
    -webkit-transition: top .15s ease-out;
    -o-transition: top ease-out .15s;
    transition: top .15s ease-out;
}
.navMenuContent.isHidden  {
	visibility: hidden;
	display: none;
}
.main_header .navMenuOpen .navMenuContent.show ,
.main_header .navMenuContent.show {
	visibility: visible;
    top: 0;
    -webkit-transition-duration: .3s;
    -o-transition-duration: .3s;
    transition-duration: .3s;
}

.main_header .navMenuContent .mobileHeader {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-top: 1.1rem;
    padding-left: 1.35rem;
    padding-right: 1.5rem;
    max-height: 60px;
     -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.headerNavigationList {
	
}
.headerNavigationList ul {
	margin: 0;
	padding-top: 1rem;
	padding-bottom: 1rem;
	padding-left: 0;
	padding-right: 0;
	list-style-type: none;
	border-bottom: 1px solid #eaeaea;
}
.headerNavigationList ul li {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: block;
}
.headerNavigationList ul li a {
	border-bottom: 3px solid transparent;
    padding-bottom: 6px;
    -webkit-transition: border-color .3s ease-out;
    -o-transition: .3s border-color ease-out;
    transition: border-color .3s ease-out;
    white-space: nowrap;
    display: block;
    width: 100%;
    line-height: 45px;
    padding-bottom: inherit!important;
    padding-left: .9375rem;
    padding-right: .9375rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #434d51;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
}
.headerNavigationList ul li a:hover {
	border-color: #eaeaea;
}
.headerNavigationList_footerNav ul {
	margin: 0;
	padding-top: 1rem;
	padding-bottom: 1rem;
	padding-left: .9375rem;
    padding-right: .9375rem;
	list-style-type: none;
	border-bottom: 1px solid #eaeaea;	
}
.headerNavigationList_footerNav ul li {
	margin: 6px 0;
	padding: 0;
	list-style-type: none;
}
.headerNavigationList_footerNav ul li a {
	font-size: 16px;
	font-weight: 400;
	color: #434d51;
	text-decoration: none;
}
.navMenuContent .lang_drop {
	font-size: 14px;
	color: #434d51;
}
.navMenuContent .lang_drop a {
	color: #434d51;	
}
.navMenuContent ul.footer_social_list {
	text-align: left;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.desk_navLeft {
	margin-left: 32px;
	margin-top: 8px; 
}
.desk_navLeft .navbar-nav li.nav-item:not(:first-of-type) {
	margin-left: 1rem;
}
.desk_navRight .navbar-nav li.nav-item:first-child {
	padding: 1rem;
}
.desk_navRight .navbar-nav li.nav-item:nth-child(2) {
	padding: 1rem 0;
	margin-left: 1rem;
}
.isFixed {
	position: fixed;
    top: 0px;
    overflow: hidden;
    width: 100%;
}
.mobileHeader_navCloseBtn {

}




/*CHANGING PAGE DESIGN - 15-06-2021*/
.fw-400 {
	font-weight: 400!important;
}
.text-p.lead {
	font-size: 1.1rem;
}
table.dptable {
	text-transform: uppercase;
}
table.dptable thead th {
	background-color: var(--yellow);
	text-transform: uppercase;
	text-align: center;
	color: black;
}
.table.dptable td, 
.table.dptable th {
	border:  1px solid #bed4cf;
	text-align: center;
}
.table.dptable td {
	background-color: var(--primary);
	color: #fff;
}
.newbanner {
	/* background: url(../images/banner-thumb.png) no-repeat; */
	background-size: contain;
	position: relative;
	bottom: 0;
	left: -15%;
	z-index: 10;
	width: 833px;
	height: 340px;
	max-width: 100%;
	margin: -50px 0 0 0;
}
.howitworks {
	background-color: #e9ecee;
	padding: 60px 0 60px 0;
}
.stepbox {
	background-color: #fff;
	color: #148771;
	padding: 30px;
	border-radius: 0 0 50px 0;
	box-shadow: 1px 1px 10px 1px rgba(0,0,0,.1);	
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: calc(100% - 1rem);
    margin-bottom: 1rem;
}
.stepbox p {
    font-size: 13px;
    font-weight: 500;
}
.heightadjuster {
	height: 360px;
}
.rtl {
	direction: rtl!important;
}
