.banner_main_cont {
  position: relative;
  
}
.banner_background_wrapper {
  /* background: #ccc; */
  position: absolute;
  top: 0;
  z-index: -1;
  height: 680px;
}

.img_d2{
  margin-left: -1px;
  width: auto;
}


.bothcard_wrap{
  align-items: center !important;

 }


.bothcard_wrap2{
  /* align-items: flex-end !important; */
width: 100%;
 }
 
.bothcard_wra{
  margin-top: 19px;
}
 @media only screen and (min-width: 991px){



  .bothcard_wrap{
    align-items: flex-end !important;
    /* width: 9%; */
    width: 85%;
   }
   .bothcard_wrap2{
     /* align-items: flex-end !important; */
   width: 100%;
    }

 }







.banner_background_wrapper2 {
  background: #ccc;
  position: absolute;
  top: 0;
  z-index: -1;
  height: 640px;
}



.card_hide{
  border: 1px solid red;
  height: 400px;
}
@media only screen and (max-width: 992px){
  .reg_car_wrap{
    width: 80% !important;
  }
}
@media only screen and (max-width: 390px) {
  .reg_car_wrap{
    width: 90% !important;
  }
  .banner_background_image {

    height: 1450px !important;
  }
  .banner_background_wrapper {
    height: 1450px !important;
  }

  .banner_background_gradient{
    height: 1450px !important;
  }

  .reg_car_wrap{
    width: 90% !important;
  }
  .banner_background_image2 {

    height: 933px !important;
  }
  .banner_background_wrapper2 {
    height: 933px !important;
  }

  .banner_background_gradient2{
    height: 933px !important;
  }


}





@media only screen and (min-width: 390px)and (max-width: 509px) {
  .reg_car_wrap{
    width: 90% !important;
  }
  .banner_background_image {

    height: 1400px !important;
  }
  .banner_background_wrapper {
    height: 1400px !important;
  }

  .banner_background_gradient{
    height: 1400px !important;
  }




  .banner_background_image2 {
     
    height: 883px   !important;
  }
  .banner_background_wrapper2 {
    height: 883px  !important;
  }

  .banner_background_gradient2{
    height: 883px  !important;
  }

}


@media only screen and (min-width: 458px)and (max-width: 480px) {



  .banner_background_image2 {
     
    height: 813px   !important;
  }
  .banner_background_wrapper2 {
    height: 813px  !important;
  }

  .banner_background_gradient2{
    height: 813px  !important;
  }

}




@media only screen and (min-width: 509px)and (max-width: 580px) {
  .banner_background_image {

    height: 1360px !important;
  }
  .banner_background_wrapper {
    height: 1360px !important;
  }

  .banner_background_gradient{
    height: 1360px !important;
  }



  .banner_background_image2 {

    height: 853px !important;
  }
  .banner_background_wrapper2 {
    height: 853px !important;
  }

  .banner_background_gradient2{
    height: 853px !important;
  }

}
@media only screen and (min-width: 581px) and (max-width: 683px) {
  .banner_background_image {

    height: 1295px !important;
  }
  .banner_background_wrapper {
    height: 1295px !important;
  }
  .banner_background_gradient{
    height: 1295px !important;
  }

  .banner_background_image2 {

    height: 800px !important;
  }
  .banner_background_wrapper2 {
    height: 800px !important;
  }
  .banner_background_gradient2{
    height: 800px !important;
  }


}
@media only screen and (min-width:683px) and (max-width: 991px) {
  .banner_background_image {

    height: 1223px !important;
  }
  .banner_background_wrapper {
    height: 1223px !important;
  }
  .banner_background_gradient{
    height: 1223px !important;
  }



  .banner_background_image2 {

    height: 810px !important;
  }
  .banner_background_wrapper2 {
    height: 810px !important;
  }
  .banner_background_gradient2{
    height: 810px !important;
  }




}
@media only screen and (min-width: 992px)  and (max-width: 1026px) {
  .banner_background_image {

    height: 760px !important;
  }
  .banner_background_wrapper {
    height: 760px !important;
  }
  .banner_background_gradient{
    height: 760px !important
  }


  .banner_background_image2 {

    height: 703px !important;
  }
  .banner_background_wrapper2 {
    height: 703px !important;
  }
  .banner_background_gradient2{
    height: 703px !important
  }


}








.banner_background_image {
  /* background-image: url(../../../assets/images/landingPageBanner.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: var(--primary);
  height: 680px;
}

.banner_background_image2 {
  /* background-image: url(../../../assets/images/landingPageBanner.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: var(--primary);
  height: 640px;
}





.banner_background_gradient {
  background-image: linear-gradient(var(--primary), var(--info));
  position: absolute;
  top: 0;
  opacity: 0.4;
  height: 680px;
}

.banner_background_gradient2 {
  background-image: linear-gradient(var(--primary), var(--info));
  position: absolute;
  top: 0;
  opacity: 0.4;
  height: 640px;
}
.banner_heading {
  line-height: 3rem;
}
.banner_description {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--info);
  line-height: 2rem;
  width: 70%;
}
@media only screen and (max-width: 991px) {
  /* .landing_page_s1 {
    height: auto !important;
  } */
}

.main_ttt{
font-size: x-large;
line-height: 40px;
}
.reg_car_wrap{
  /* border: 1px solid red; */
  /* height: 30vh; */
  width: 60%;
  max-width: 500px;
  padding: 46px;
  background-color: #f4f6f5;
  border-radius: 20px;
}

.input_main_wrap{
  border-radius: 5px;
  /* padding: 20px; */
/* background-color: white; */
  margin: 0 auto;
  height: 90px;
  border: 2px solid black;
}
.button_main_wrap{
  border-radius: 5px;
  /* padding: 20px; */
/* background-color: white; */
  margin: 0 auto;
  height: 90px;
  /* background-color: #0460cc; */
  /* color: white; */
  /* border: 2px solid black; */
}


@media only screen and (max-width: 500px){

  .value_btn{
    font-size: 15px !important;
  }
}
.value_btn{
  height: 100%;
  border-radius: 5px;
  width: 100%;
  /* color: white; */
  font-size: 22px;
  /* height: 64px; */
  line-height: 32px;
  font-weight: bold;

}
.reg_input::-webkit-input-placeholder {
  text-align: center;
}

.reg_input:-moz-placeholder {
  text-align: center;
}


.arow_{
  font-weight: bold;
}







.reg_input{
  text-align: center;
  height: 100%;
  text-transform: uppercase;

  -webkit-appearance: none;
  background-color: var(--generic-bg-color);
  border: 2px solid var(--generic-border-color);
  border-radius: 4px;
  color: var(--generic-text-color);
  font-size: 27px;
  font-weight: 700;
  
  /* height: 48px; */
  margin: 0;
  outline: none;
  padding: 0 16px;
  /* text-align: left; */
  width: 100%;
  background-color: white;
}