.form_main_wrapper {
  background: var(--info);
  height: auto;
  border-radius: 0.5rem;
  min-width: 322px;
}
.form_main_wrapper_width {
  width: 60%;
}
.form_main_wrapper_width_fluid {
  width: 100%;
}
.form_header {
  width: 100%;
  padding: 1rem;
}
.form_header_text {
  font-size: 1.6rem;
  font-weight: bold;
  color: var(--secondary);
  text-transform: capitalize;
}
/*  */
.dropdown_wrapper {
  padding: 0.5rem 1rem;
  /* padding-top: 0px; */
}
@media only screen and (max-width: 991px) {
  .form_main_wrapper_width {
    width: 90%;
  }
}
@media only screen and (max-width: 954px) {
  .form_main_wrapper_width {
    width: 95%;
  }
}
@media only screen and (max-width: 548px) {
  .dropdown_wrapper {
    padding: 0.2rem 0.5 rem;
    /* padding-top: 0px; */
  }



}

@media only screen and (min-width: 300px) and (max-width:400px) {
  
  .form_main_wrapper{
    max-width: 350px;
  }
}

@media only screen and (min-width: 400px) and (max-width:500px) {
  
  .form_main_wrapper{
    max-width: 400px !important;
    min-height: 350px !important;
  }
}

@media only screen and (min-width: 500px) and (max-width:600px) {
  
  .form_main_wrapper{
    max-width: 500px;
  }
}






@media print {
  .hideButtonOnPrint {
    display: none;
  }
}
