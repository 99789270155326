.span_text{
    cursor: pointer;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
/* background-color: yellow; */
color: black;
margin: 2px;
}

.css-1n3h4wo{
    height: auto !important;
}