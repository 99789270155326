.landingSection_main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem 0;
  /* margin: 1rem 0; */
}
.landingSection_body_wrapper {
  width: 100%;
  padding: 2rem;
  margin: 1rem 0;
}
.landingSection_header_title {
  font-size: 1.9rem;
  font-weight: bold;
  margin: 1rem 0;
  text-align: center;
}
.landingSection_header_description {
  font-size: 1.3rem;
  margin: 1rem 0;
  text-align: center;

}
@media only screen and (max-width: 991px) {
  .landingSection_body_wrapper {
    width: 85%;
  }
}
@media only screen and (max-width: 680px) {
  .landingSection_body_wrapper {
    width: 100%;
  }

  .landingSection_header_title {
    font-size: 1.6rem;
  }
  .landingSection_header_description {
    font-size: 1.1rem;
  }
}
