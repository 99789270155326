.inp_wrapx{

    /* border: 2px solid #096C59; */
    height: 3.7rem;
    /* background-color: #178971; */
    padding: 1.1rem;
    color: var(--primary);
       font-size: 1rem;
    font-weight: bold;

    box-shadow: 0 0 8px #1d1d1b40;   
}

.inp_wrapx_{
      padding: 0.4rem; 

          /* border: 2px solid #096C59; */
    height: 3.7rem;
    /* background-color: #178971; */

    color: var(--primary);
       font-size: 1rem;
    font-weight: bold;

    box-shadow: 0 0 8px #1d1d1b40;   
}

.aclas{
 /* background-color: red; */
 /* border: 2px solid red; */
}
.bclas{
      border: 0.2rem solid var(--primary);
    color: var(--primary);
      font-size: 1rem;
    font-weight: bold;
    padding: 1rem;
}
.bclas_{

      border: 0.2rem solid var(--primary);
    color: var(--primary);
      font-size: 1rem;
    font-weight: bold;
    padding: 0.2rem;
}

 
.inp_wrapx.aaaaaa{
    box-shadow: 0 0 12px #03030340;
    border: 2px solid  #48BA7E;
} 
.inp_wrapx:hover{
    /* box-shadow: 0 0 12px #03030340; */
      border: 0.2rem solid gray;
    color: var(--primary);
      font-size: 1rem;
    font-weight: bold;
    padding: 1rem;
        /* font-weight: bold; */
} 
.inp_wrapx_:hover{
    /* box-shadow: 0 0 12px #03030340; */

border: 0.2rem solid gray;
    color: black;
      font-size: 1rem;
    font-weight: bold;
    padding: 0.2rem;
        /* font-weight: bold; */
} 

.inp_search{
    background-color: red;
}

.prompt{
    /* background-color: black !important; */
    width: 500px !important;
}

.next_btn{
    align-items: center;
    -webkit-appearance: none;
    background-color: #0460cc;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: NewTransportSemi,Helvetica,Arial,sans-serif;
    font-size: 18px;
    font-weight: 400;
    height: 48px;
    justify-content: center;
    line-height: 24px;
    margin-bottom: 24px;
    min-width: 112px;
    outline: none;
    padding: 4px 8px;
    transition: background-color .1s linear;
    vertical-align: middle;
    width: 200px;
    /* width: 100%; */
}

.ui.input{
    width: 60%!important;
} 


.qwqw{
    display: flex;
    /* border: 1px solid red; */
    flex-direction: row;
    align-items: center;
}


.back_btn{
    align-items: center;
    -webkit-appearance: none;

   
    border-radius: 4px;

    cursor: pointer;
    display: flex;
    font-family: NewTransportSemi,Helvetica,Arial,sans-serif;
    font-size: 18px;
    font-weight: 400;
    height: 48px;
    justify-content: center;
    line-height: 24px;
    background-color: #fff;
    border: 2px solid #e2e1d9;
    color: #0460cc;
    transition: background-color .1s linear,border-color .1s linear;
    margin-bottom: 24px;
    min-width: 112px;
    outline: none;
    padding: 4px 8px;
    transition: background-color .1s linear;
    vertical-align: midd;
    width: 200px;
    /* width: 100%; */
}

.inp_main_wrappa{
    padding: 20px;
    display: flex;
    /* border: 1px solid red; */
    /* justify-content: center; */
}


.progresbar{
    position: fixed;
 
    /* border: 1px solid red; */

} 
.p_wrap{
    position: absolute;
    top: 15px;
}
.aaaaaa{
    position: relative;
   

}

.css-1l6rwmg-MuiLinearProgress-root{
    position: fixed !important;
    width: 100% !important;
    z-index: 10000 !important;
    
}

.css-1l6rwmg-MuiLinearProgress-root .MuiLinearProgress-bar{
    background-color: #002F34 !important;
    top: 91px!important;
}


.botm_wrap{
      padding: 0px 10px 10px 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
}


.inp_col{
    display: flex;
    justify-content: end;
}

 .inp_col_2{
    display: flex;
    /* justify-content: start; */
} 