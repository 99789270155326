.body {
  padding-right: 10px;
  justify-content: space-between;
  width: 100% ;
  /* background-color: red !important; */
  overflow-x: hidden;
}
.searchfilter{
  justify-content: space-between;
/* background-color: green !important; */
flex-direction: row;
display: flex;
width: 50%;
}
.serachfield{
  width: 30%;
margin-left: 5%;
}
.option{
  justify-content: center;
  flex-direction: row;
  display: flex;
}

.tap {
 border: 2px solid whitesmoke;
  margin-left: 20px;
  text-align: center;
  align-content: center;
  background-color: whitesmoke !important;
  height: 3rem;
  width: 5rem;
  border-radius: 15px;
}
@media only screen and (max-width: 768px){
  .option{
    margin-top: 3%;
    justify-content: center;
    flex-direction: row;
    display: flex;
  }
  .serachfield{
    width: 30%;
  /* margin-left: 5%; */
  }
  .searchfilter{
    justify-content: space-between;
  /* background-color: green !important; */
  flex-direction: row;
  display: flex;
  width: 70%;
  }
  
}
@media (min-width: 770px) and (max-width: 1000px){
  .option{
    margin-top: 3%;
    justify-content: center;
    flex-direction: row;
    display: flex;
  }
  .serachfield{
    width: 30%;
  /* margin-left: 5%; */
  }
  .searchfilter{
    margin-left: 5%;
    justify-content: space-between;
  /* background-color: green !important; */
  flex-direction: row;
  display: flex;
  width: 40%;
  }
}