.slider_main_cont {
  width: 100%;
  display: flex;
  justify-content: center;
}
.slider_carousel_wrapper {
  width: 80%;
  margin-top: 1rem;
}
.slider_card {
  background: rgba(255, 164, 155, 0.45);
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 0.5rem;
  margin: 0 0.5rem;
  height: 5rem;
}

.Shimer_car_Div{
  width: 80%;
  margin: 0 auto ;
  /* border: 1px solid black; */
}









.slider_card_active {
  /* background: rgba(255, 255, 255, 0.45);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px); */
  background: var(---white);

  border-radius: 0.5rem 0.5rem 0 0;
  padding: 0.5rem;
  margin: 0 0.5rem;
  height: 5rem;
}
.slider_card_title {
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.3rem;
}
.slider_card_description {
  /* font-weight: bold; */
  font-size: 1rem;
  line-height: 1.1rem;
}
@media only screen and (max-width: 1024px) {
  .slider_carousel_wrapper {
    width: 90%;
  }
}
@media only screen and (max-width: 464px) {
  .slider_carousel_wrapper {
    width: 98%;
  }
}
