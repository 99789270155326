.showInterest_headingCont {
  color: var(--white);
  padding: 1rem;
}
.showInterest_heading {
  font-size: 2.5rem;
  line-height: 2.8rem;
  font-weight: bold;
  text-transform: uppercase;
}
.showInterest_description {
  font-size: 1.4rem;
  line-height: 1.8rem;
}


.car_step{
  cursor: pointer;
}


@media only screen and (max-width:500px){

  .list_row_text{
    font-size: 0.9rem !important;
  }

  .list_text_secound{
    margin-left: 20px !important;
  }

  .heading1{
    font-size: 2rem !important;
  }
}














.car_Data {
  /* border: 1px solid red; */
  width: 80%;

  margin: 0 auto;
}
/* ========================= */
.list_data_wrapper {
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  flex-direction: column;
}
.list_row {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0.5rem 0;

}
.list_row_text {
  font-size: 1.4rem;
  line-height: 1.4rem;
}



.iii{
 width: 100%;
 height: 100%;
 object-fit: contain;
}

.slider_div{
  width: 100vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}


.edit_btn_wrap{
  /* border: 1px solid red; */
  display: flex;
  justify-content: flex-end;

}

.edit_button{
 color: var(--primary);
  /* width: 100px ; */
  /* position: absolute; */
  /* right: 0; */
  /* bottom: ; */

}


.btn_wrap{
  width: 80%;
  /* position: relative; */
  /* border: 1px solid blue; */
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
}



.crosbtn{
  color: var(--primary);
  width: 100px ;
 



}




.Update_Sction{
  width: 100%;
  /* position: relative; */
  /* display: flex;
  justify-content: center; */
  
}

.Udate_form{
  width: 80%;
  margin: 0 auto;
  /* border: 1px solid red; */
}



.car_part_pic{
  width: 17rem;
  height: 16rem;
  /* border: 1px solid red; */
  padding: 3px;
}

.Car_Parts1_pic{
  width: 100%;
  /* border: 1px solid blue; */
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* align-items: center; */

}