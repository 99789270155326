.azaaza{
    display: flex;
    justify-content: flex-end !important;
}





* {
    outline: 0;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.w-full {
    width: 100%;
}
.text-white {
    color: #fff;
}
.bg-charcoal {
  
        background-color: var(--primary)  !important;
        

}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.flex {
    display: flex;
}