.buy_{
    /* border: 1px solid red; */
}


@media only screen and (max-width: 399px) {

    .bton_wrap{
        width: 100% !important;
margin: 0 auto !important;
    }
    .buy_{
        width: auto;
        /* margin-bottom: 1rem; */
        margin: 10px auto;
    }
    
    .buy_l{
        width: auto;
        margin-bottom: 1rem;
    }
}


@media only screen and (min-width:400px) and (max-width: 500px) {

    .bton_wrap{
        width: 50% !important;
margin: 0 auto !important;
    }
.buy_{
    width: auto;
    /* margin-bottom: 1rem; */
    margin: 10px auto;
}

.buy_l{
    width: auto;
    margin-bottom: 1rem;
}
}


.foter_list{
    list-style: none;
    line-height: 3rem;
    text-decoration: none;
}

.list_wrap{
    font-size: 1.2rem;
    /* line-height: 2rem; */
}

.button_heder{
    width: 10rem;
    height: 3.5rem;
    /* background-color: #FFD700; */
    font-weight: bold;
    /* color: black; */
}

.button_heder:hover{
    /* border: 4px solid #FFD700 ; */
    /* background-color: #F2F4F5; */
}


  .link {
    color: black;
  }
  