.dropdownItems {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.tabstyle {
  background-color: #ccc;
}
.tabIcons {
  font-size: 1.2rem;
  font-weight: bold;
}
.barstyle {
  margin-top: 1%;
}
.insidetab {
  flex-direction: column;
  display: flex;
  margin-top: 1%;
  /* background-color: aqua; */
}
.outertab {
  margin: 1%;
  width: 100%;
}
.listcontainer {
  width: 100%;
  /* justify-content: center;
   flex-direction: row;
   display: flex; */
}
.liststyle {
  margin-top: 2%;
  border-bottom: 1.5px solid #ccc;
  background-color: whitesmoke;
}
.viewtext {
  font-size: 1rem;
}
.ui.menu > .item {
  background-color: #ccc !important;
}
.ui.menu > .item:not(.active) {
  background-color: whitesmoke !important;
}
.ui.pointing.menu .active.item:after {
  background-color: #ccc !important;
}
.textbox {
  justify-content: space-between;
  display: flex;
  align-items: center;
  height: 30px;
  margin: 2%;
  width: 20%;
}
.tabtext {
  font-size: 100%;
  font-weight: bold;
}
@media only screen and (max-width: 600px) {
  .insidetab {
    flex-direction: column;
    display: flex;
    margin-top: 1%;
    /* background-color: aqua; */
  }
  .outertab {
    margin: 1%;
    width: 100%;
  }
  .listcontainer {
    width: 100%;
    /* justify-content: center;
     flex-direction: row;
     display: flex; */
  }
  .logo {
    display: flex;
    justify-content: space-between;
  }

  .viewtext {
    font-size: 0.8rem;
  }
  .ui.menu > .item {
    background-color: #ccc !important;
  }
  .ui.menu > .item:not(.active) {
    background-color: whitesmoke !important;
  }
  .ui.pointing.menu .active.item:after {
    background-color: #ccc !important;
  }
  .textbox {
    justify-content: space-between;
    display: flex;
    align-items: center;
    height: 10%;
    margin: 2%;
    width: 100%;
    background-color: #ccc;
  }
  .tabtext {
    font-size: 59%;
    font-weight: w900;
  }
}
@media (min-width: 769px) and (max-width: 1200px) {
  .insidetab {
    flex-direction: column;
    display: flex;
    margin-top: 1%;
    /* background-color: aqua; */
  }
  .outertab {
    margin: 1%;
    width: 100%;
  }
  .listcontainer {
    width: 100%;
    /* justify-content: center;
     flex-direction: row;
     display: flex; */
  }
  .logo {
    display: flex;
    justify-content: space-between;
  }
  .dropdownItems {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .viewtext {
    font-size: 0.8rem;
  }
  .ui.menu > .item {
    background-color: #ccc !important;
  }
  .ui.menu > .item:not(.active) {
    background-color: whitesmoke !important;
  }
  .ui.pointing.menu .active.item:after {
    background-color: #ccc !important;
  }
  .textbox {
    justify-content: space-between;
    display: flex;
    align-items: center;
    height: 10%;
    margin: 2%;
    width: 100%;
    /* background-color: #ccc; */
  }
  .tabtext {
    font-size: 63%;
    font-weight: w900;
  }
}
