.grid_container {
  width: 100%;
  padding: 1rem;
}
.grid_card_wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.grid_carda {
  /* width: 48%; */
  margin: 0.2rem;
  display: block;
}
