
/* .color_bg{
   background-color: #ff7255!important;  
} */
@media only screen and (max-width: 576px){
    .prog {
        width: 100% !important;
        /* height: 40vh; */
        /* margin: 0 auto; */
    }
    .card_content{
        margin-bottom: 2%;
    }

    .min_w{
        min-width: 10rem !important;
    }
    /* .car_card_step .card_bto{
        font-size: 0.7rem;
        width:100% !important;
    } */
    .car_card_step{
        width: 100% !important;
        /* height: 40vh; */
        align-items: center !important;
        background-color: #fff;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        box-shadow: 0 0 8px #1d1d1b1f;
        display: flex;
        padding: 48px 0px 40px !important;
        margin: 0 auto;
        flex-direction: column !important; 
        align-content: center !important;
    }
}
.prog{
       width: 80%;
    /* height: 40vh; */
    margin: 0 auto; 
}

.prog_clas .progress-bar {
  background-color: #178971 !important;
  font-size: 8px !important;
  /* color: black !important; */
  /* font-weight: bold !important; */
  background-image: none !important;
}

.car_card_step{
    
    width: 80%;
    /* height: 40vh; */


    align-items: center;
    background-color: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: 0 0 8px #1d1d1b1f;
    
    display: flex;
    padding: 48px 48px 40px;
    margin: 0 auto;

}



@media only screen and (max-width: 490px){
.card_bton{
    /* min-width: auto !important; */
    font-size: 14px !important;

}
.card_bton_w{

    /* min-width: auto !important; */
    font-size: 14px !important;

}

  }



.card_content{
    align-items: center;
    background-color: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
 
    display: flex;
 

}


.card_bton{
    align-items: center;
    -webkit-appearance: none;
    background-color: #0460cc;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: NewTransportSemi,Helvetica,Arial,sans-serif;
    font-size: 18px;
    font-weight: 400;
    height: 48px;
    justify-content: center;
    line-height: 24px;
    margin-bottom: 24px;
    min-width: 90px;
    outline: none;
    padding: 4px 8px;
    transition: background-color .1s linear;
    vertical-align: middle;
    width: 100%;

}


.card_bton_w{


    background-color: #fff;
    border: 2px solid #e2e1d9;
    box-shadow: 0 2px 4px 0 #e2e1d9;
    align-items: center;
    -webkit-appearance: none;


    border-radius: 4px;
    color: #0460cc ;
    cursor: pointer;
    display: flex;
    font-family: NewTransportSemi,Helvetica,Arial,sans-serif;
    font-size: 18px;
    font-weight: 400;
    height: 48px;
    justify-content: center;
    line-height: 24px;
    margin-bottom: 24px;
    min-width: 90px;
    outline: none;
    padding: 4px 8px;
    transition: background-color .1s linear;
    vertical-align: middle;
    width: 100%;

}



.motorway-svg-fill {
    fill: #0460cc;
}

.cici{
    /* background-color: #0460cc; */
    color: #178971;
}

.btnReload{
    font-size: 30px;
    cursor: pointer;
    width: 40px !important;
}