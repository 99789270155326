.side_bar_header_main_cont {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2vh;
  background: var(--white);
  position: sticky;
  top: 0;
  z-index: 1;
  -webkit-box-shadow: 0px 1px 3px -2px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 1px 3px -2px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1px 3px -2px rgba(0, 0, 0, 0.5);
}
.side_bar_header_title {
  font-size: 1.2rem;
  font-weight: 700;
  padding: 1vh;
  color: var(--secondary);
}
.side_bar_header_title_2 {
  font-size: 1rem;
  padding: 1vh;
  color: var(--primary);
}
/* searchableListPicker */
.searchableListPicker_main_cont {
  width: 100%;
}
.searchableListPicker_input_wrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 0.5px solid var(--infoPlus);
  border-radius: 5px;
  padding: 0px;
  margin: 0px;
  height: 2.5rem;
}
.searchableListPicker_input {
  font-size: 1.2rem;
  padding: 1vh;
  display: flex;
  flex: 1;
  background: inherit;
}
.searchableListPicker_input:focus {
  background: var(--white);
}
.searchableListPicker_input {
  padding: 1vh;
  display: flex;
  flex: 1;
}
.searchableListPicker_search_icon_wrapper {
  width: 2.2rem;
  height: 100%;
  /* padding: 5px; */
  background: var(--primary);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
}
.searchableListPicker_search_icon {
  font-size: 1.3rem;
  /* color: var(--white); */
}
.display_data_list_cont {
  width: 100%;
  margin-top: 1rem;

  overflow-y: hidden;
}
.display_data_list_item_cont {
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;

  padding-bottom: 1rem;
  margin: 1rem 0px;
}
.display_data_list_item_icon {
  padding: 0.5 rem;
  font-size: 1.45rem;
  font-weight: 800;

  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  text-transform: uppercase;
  color: var(--secondary);
}
.display_data_list_item_text {
  font-size: 1.2rem;
  color: var(--secondary);
  text-transform: capitalize;
  display: flex;
  flex: 1;
}
.display_data_show_more {
  color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchableListPicker_selected_item_text {
  font-size: 1rem;
  text-transform: capitalize;
  font-style: italic;
  font-weight: 500;
}
.searchableListPicker_remove_selected {
  width: 1.8rem;
  height: 100%;
  padding: 3px;
  cursor: pointer;
}
/* DataListPicker */
.dataListPicker_main_cont {
  padding: 0px 10px;
}
.dataListPicker_input {
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  border: 0.5px solid var(--infoPlus);
}
.dataListPicker_input:focus {
  border: 0.5px solid var(--infoPlus);
}
/* searchAbleRangePicker */

/* .searchAbleRangePicker_main_cont{} */
.searchAbleRangePicker_row {
  padding: 10px 5px;
  /* box-sizing: border-box; */
}
.searchAbleRangePicker_col_1 {
  flex: 1;
  text-transform: capitalize;
  color: var(--secondary);
  font-weight: 400;
}

.searchAbleRangePicker_col_2 {
  flex: 4;
}
/* ------------------------- */
input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--info);
  margin: 0px 5px;
  font: inherit;
  color: var(--secondary);
  width: 1.3em;
  height: 1.3em;
  border: 0.15em solid var(--secondary);
  border-radius: 0.15em;
  transform: translateY(-0.075em);
}

input[type="checkbox"] {
  /* ...existing styles */

  display: grid;
  place-content: center;
}
input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--info);
}
input[type="checkbox"] {
  /* ...existing styles */
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--secondary);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}
input[type="checkbox"]::before {
  /* ...existing styles */

  /* Windows High Contrast Mode */
  background-color: var(--secondary);
}
input[type="checkbox"]::before {
  /* ...existing styles */

  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
/*  */
.labeledCheckBox_label {
  text-transform: capitalize;
  color: var(--secondary);
  font-weight: 400;
  padding: 5px;
}
