

.car_Pic{
    width: 70%;
    height: 20rem;
}


.car_Main_Wrap{
    /* border: 1px solid red; */
    width: 100%;
    margin: 0 auto;
    background-color: #1D1D1B;
    color: white;
}

.btn_wrap{
    /* border: 1px solid blue; */
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.icon_div{
    width: 3rem;
    height: 3rem;
    background-color: gray;
    color: white;
    border-radius: 50%;
    padding: 0.9rem;
    font-size: 1.1rem;
    margin: 0 auto;
}

.wwww{
    width: 400px;
    height: 400px;
    /* border: 1px solid red; */

}



/* .ruler_div{
    width: 100%;
    height: 100%;
    border: 1px solid red;
    overflow: hidden;
    padding-top: 5rem;
} */
.Car_Pic_Wrap{
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
}

#draggable { width: 150px; height: 150px; padding: 0.5em; }

.heading-size{
    font-weight: bold;
    font-size: 1.6rem;
}

.buble{
    width: 30px;
    height: 30px;
    background-color: yellow;
    border-radius: 50%;
    position: absolute;
   
}




#d1 {
    width: 120px;
    height: 120px;
    background-color :aqua;
    padding:20px;
    float:left;
    margin:5px;
    }
#d2 {
    width: 120px;
    height: 120px;
    background-color :orange;
    padding:20px;
    float:left;
    margin:5px;
    }
#d3 {
    width: 120px;
    height: 120px;
    background-color :yellow;
    padding:20px;
    float:left;
    margin:5px;
    }


    .lotie_wrap{
        width: 100%;
        /* border: 1px solid red; */
        display: flex;
        justify-content: center;
        align-items: center;
        /* overflow: hidden; */
        
    }

    .Lotie_Main_Wrap{
        overflow: hidden;
    }

    .web_wrap{
        /* height: 100vh; */
        width: 100%;
        justify-content: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: #FEF200;
        padding: 7.4rem;
       
    }

    .text_wrap_2{
        display: flex;
        justify-content: center;
        text-align: center;
        
    }

    .text_2{
        text-align: center;
    }
    .text_3{
        font-size: 1.2rem;
        text-align: center;
    }
    /* .aaa{
        background-color: #a9aeb9;

    } */