.dropdown-button{
    padding: 5px 10px 5px 10px;
    margin: 0 auto;
    cursor: pointer;
    width: 100%;
    font-weight: 500 !important;
}
.expanded-div{
    padding: 5px 10px 5px 10px;
    margin: 0 auto;
    width: 100%;
    
}
.divider{
    width: 90%;
    border-bottom: 1.5px solid #ccc;
    margin: 10px 0px 10px 0px;
}
.border{
    border: 2px solid #ccc;width: 100%;
}