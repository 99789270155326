.showInterest_mainCont {
  width: 100%;
  height: auto;
  position: relative;
}
.showInterest_topBackgroundSection {
  background: var(--primary);
  width: 100%;
  height: 18rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
.showInterest_body {
  /* margin: 1rem; */
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
.showInterest_headingCont_ {
  color: var(--white);
  /* background-color: #48BA7E; */
  padding: 5rem;
}
.showInterest_heading {
  font-size: 2.5rem;
  line-height: 2.8rem;
  font-weight: bold;
  text-transform: uppercase;
}
.showInterest_description {
  font-size: 1.4rem;
  line-height: 1.8rem;
}
/* .showInterest_body_column {

} */
.showInterest_body_column_left {
  display: flex;
  flex: 1;
  align-items: center;
}
.showInterest_body_column_right {
  flex: 3;
  overflow: hidden;
}
.showInterest_columns_wrapper {
  display: flex;
  flex-direction: row;
  background: var(--white);
  border-radius: 0.5rem;
  padding: 0.5rem;
  height: 90vh !important;
}
.showInterest_form_wrapper {
  align-self: center;
  width: 90%;
  background: var(--white);
  box-shadow: none;
  height: 96%;
}
.step.active {
  color: var(--primary) !important;
}

/*  */
@media only screen and (max-width: 400px) {
  .showInterest_body_column_left {
    display: none;
  }
  .showInterest_body_column_right {
    flex: 1;
  }
}
