@import "../../App.css";

.OverLayLoaderCont {
  background: rgb(247, 247, 247,1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex: 1;
  z-index: 10000000;
  justify-content: center;
  align-items: center;
  /* opacity: 0.5; */
}
