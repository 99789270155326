.doc_list{
    display: flex;
    flex-direction: row;
}

.curor_pointer{
background-color: var(--primary);
    cursor: pointer;
    color: white;
    padding-left: 5px;
}